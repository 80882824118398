import Sidebar from "../../components/Sidebar";
import Image from "../../components/Image/Image";
import TabContainer from "./tabContainer";
import Wallet from "../../components/Wallet";
import "./style.css";

import iconAssets from "../../assets/icons/catte-assets.png";
const Assets = () => {
  return (
    <div className="profile-page">
      <Sidebar />
      <Wallet />
      <div className="requset-container" style={{ marginTop: "80px" }}>
        <div className="head-container">
          <h2 className="head-name">
            <Image ImgSrc={iconAssets} />
            My Wallet
          </h2>
        </div>
        <div className="body--container">
          <TabContainer />
        </div>
      </div>
    </div>
  );
};

export default Assets;
