import React, { useEffect, useState } from "react";
import "./AuditorActionModal.style.css";
import { useNavigate } from "react-router-dom";
type Props = {
  type:
    | "SFS_PENDING"
    | "SFS_ACCEPTED"
    | "SFS_REJECTED"
    | "SFS_UPDATE"
    | "SFS_UNDER_REVIEW"
    | "SFS_DRAFT"
    | "info";
  action?: Function;
  setModalState: Function;
  message?: string | null;
  isStatusChanged?: any;
};

const AuditorActionModal = ({
  type,
  action,
  setModalState,
  message,
  isStatusChanged,
}: Props) => {
  const [reason, setReason] = useState(message);
  const navigate = useNavigate();

  useEffect(() => {
    if (isStatusChanged) {
      setModalState((state) => ({ ...state, showModal: false }));
      navigate("/requests");
    }
  }, [isStatusChanged]);
  return (
    <div className="action-modal-container">
      <div className="auditor-modal-action">
        {type === "info" && <h4>Auditor's Response</h4>}
        {type === "SFS_UPDATE" && (
          <div>
            <h4
              style={{
                paddingBottom: "5px",
              }}
            >
              RFI / Need for Revise
            </h4>
            <p
              style={{
                fontSize: "12px",
                color: "rgb(85 85 85);",
              }}
            >
              Message to applicant for additional information or revision in the
              application
            </p>
          </div>
        )}
        {type === "SFS_REJECTED" && (
          <div>
            <h4
              style={{
                paddingBottom: "5px",
              }}
            >
              Reject Application
            </h4>
            <p
              style={{
                fontSize: "12px",
                color: "rgb(85 85 85);",
              }}
            >
              State rejection reason
            </p>
          </div>
        )}
        {type === "SFS_ACCEPTED" && (
          <div>
            <h4
              style={{
                paddingBottom: "5px",
              }}
            >
              Accept Application
            </h4>
            <p
              style={{
                fontSize: "12px",
                color: "rgb(85 85 85);",
              }}
            >
              State application acceptance and necessary future steps
            </p>
          </div>
        )}
        <textarea
          placeholder="Please enter your reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          disabled={type === "info"}
        ></textarea>
        <div className="modal-actions ">
          {type === "info" ? (
            <>
              <button
                onClick={() =>
                  setModalState((state) => ({ ...state, showModal: false }))
                }
                className="update-btn"
              >
                close
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() =>
                  setModalState((state) => ({ ...state, showModal: false }))
                }
                className="update-btn"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (!reason || reason.length === 0) {
                    return;
                  }
                  action(type, reason);
                }}
                className="accept-btn"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuditorActionModal;
