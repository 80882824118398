import React, { useEffect, useState } from "react";
import "./tooltip.css";
type Props = {};

const ToolTibHoverImg = (props) => {
  console.log(
    "🚀 ~ file: ToolTibHoverForImg.tsx:7 ~ ToolTibHoverImg ~ props:",
    props
  );

  const [state, setState] = useState({ showTooltip: false });
  const handleMouseOver = () => {
    setState({ showTooltip: true });
  };

  const handleMouseOut = () => {
    setState({ showTooltip: false });
  };
  useEffect(() => {
    console.log(state.showTooltip);
  }, [state.showTooltip]);
  return (
    <div
      className="tooltip-container"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <>{props.children}</>
      {state.showTooltip && (
        <span className="tooltip-text">{props.tooltipText}</span>
      )}
    </div>
  );
};

export default ToolTibHoverImg;
