import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeEnum } from "../../../types/enum.types";

type Props = {};

const StepControler = ({ steps, id, update }) => {
  const { pathname } = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentIndex(
      steps.findIndex((s) => s.pageLink == pathname?.split("/")[3])
    );
  }, [pathname]);

  const handelPagination = (action) => {
    switch (action) {
      case "next":
        if (currentIndex == steps.length - 1) {
          navigate(`${routeEnum.formApplication}/${id}/${steps[0].pageLink}`);
          update();

          break;
        }
        update();
        navigate(
          `${routeEnum.formApplication}/${id}/${
            steps[currentIndex + 1].pageLink
          }`
        );
        break;
      case "prev":
        if (currentIndex == 0) break;
        update();
        navigate(
          `${routeEnum.formApplication}/${id}/${
            steps[currentIndex - 1].pageLink
          }`
        );
        break;
      case "home":
        update();
        navigate(`${routeEnum.formApplication}/${id}/${steps[0].pageLink}`);
        break;

      default:
        break;
    }
  };

  if (currentIndex === 0) return <></>;
  return (
    <div
      className="formCta-container"
      style={{
        borderTop: "none",
        zIndex: "5",
      }}
    >
      <div>
        {currentIndex == 0 ? (
          // <button
          //   onClick={() => {
          //     navigate(
          //       `${routeEnum.formApplication}/${id}/${steps[1].pageLink}`
          //     );
          //   }}
          //   className="letsgo-btn"
          // >
          //   Let's go
          // </button>
          <></>
        ) : (
          <>
            <button
              style={{
                marginRight: "15px",
              }}
              onClick={() => {
                handelPagination("home");
              }}
              className="formCta-back"
            >
              Home
            </button>
            <button
              onClick={() => {
                handelPagination("prev");
              }}
              className="formCta-back"
            >
              Previous
            </button>
            {currentIndex !== steps.length - 1 && (
              <button
                onClick={() => {
                  handelPagination("next");
                }}
                className="formCta-next"
              >
                {currentIndex === steps.length - 1 ? "Home" : "Next"}
              </button>
            )}
          </>
        )}

        {/* {true && (
          <>
            {true ? (
              <button className="formCta-next">Let's start</button>
            ) : (
              <button className="formCta-next">Submit</button>
            )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default StepControler;
