import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

export const accountApi = createApi({
  reducerPath: "account",
  baseQuery: fetchBaseQuery({ baseUrl: ApiEnum.ACCOUNT_API }),
  endpoints: (builder) => ({
    particleUser: builder.mutation({
      query: (body) => {
        return {
          url: `/login/provider:particle`,
          method: "POST",
          body,
          headers: {
            "X-Application-Id": "1b85a246-77ac-11ee-86ea-bce92fb9ddda",
          },
        };
      },
    }),
  }),
});

export const { useParticleUserMutation } = accountApi;
