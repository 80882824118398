import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthEnum } from "../../types/enum.types";

export interface AuthInterface {
  userId: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpiration: string | null;
  referralCode: string;
}

const initialState: AuthInterface = {
  userId: localStorage.getItem(AuthEnum.userId) || null,
  referralCode: localStorage.getItem(AuthEnum.referralCode) || "",
  accessToken: localStorage.getItem(AuthEnum.token) || null,
  refreshToken: localStorage.getItem(AuthEnum.refreshToken) || null,
  accessTokenExpiration:
    localStorage.getItem(AuthEnum.accessTokenExpiration) || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthInterface>) {
      const { accessToken, accessTokenExpiration, userId, refreshToken } =
        action.payload;
      state.accessToken = accessToken;
      state.accessTokenExpiration = accessTokenExpiration;
      state.userId = userId;
      state.refreshToken = refreshToken;

      localStorage.setItem(AuthEnum.token, accessToken || "");
      localStorage.setItem(AuthEnum.refreshToken, refreshToken || "");
      localStorage.setItem(AuthEnum.userId, userId || "");
      localStorage.setItem(
        AuthEnum.accessTokenExpiration,
        accessTokenExpiration || ""
      );
    },
    logout(state) {
      localStorage.removeItem(AuthEnum.token);
      localStorage.removeItem(AuthEnum.refreshToken);
      localStorage.removeItem(AuthEnum.userId);
      localStorage.removeItem(AuthEnum.accessTokenExpiration);
      // localStorage.clear();

      // Use immer to safely update the state
      state.accessToken = null;
      state.accessTokenExpiration = null;
      state.refreshToken = null;
      state.userId = null;
    },
    getReferralCode(state, action) {
      state.referralCode = action.payload;
      localStorage.setItem(AuthEnum.referralCode, action.payload);
    },
  },
});

export const { login, logout, getReferralCode } = authSlice.actions;
export default authSlice.reducer;
