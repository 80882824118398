import React, { useContext } from "react";
import { useGetAllWalletActivityQuery } from "../../../store/api/walletApi";
import { biconomyContext } from "../../../utils/Context";
import { ArrowUpRight } from "../../../assets/images";
import { ethers } from "ethers";

type Props = {};

const ActivityList = (props: Props) => {
  const { address } = useContext(biconomyContext);
  const { data } = useGetAllWalletActivityQuery(address, {
    skip: Boolean(!address),
  });
  console.log("🚀 ~ file: ActivityList.tsx:12 ~ ActivityList ~ data:", data);
  return (
    <div className="wrapper">
      <div className="assets-box" style={{ display: "table", width: "100%" }}>
        <div className="head-info">
          <span> </span>
          <span> </span>
          <span> </span>
        </div>

        {data?.result?.slice(0, 50).map((history, i) => (
          <div className="body-assets">
            <div
              className="items"
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                position: "relative",
              }}
            >
              <img
                src={"https://assets.polygon.technology/tokenAssets/matic.svg"}
                style={{
                  position: "absolute",
                  top: "5px",
                  left: "60px",
                  width: "18px",
                  height: "18px",
                }}
              />
              <img
                src={ArrowUpRight}
                style={{
                  border: "none",
                  maxHeight: "70px",
                  maxWidth: "70px",
                  padding: "1px",
                }}
              />
            </div>
            <div className="items">
              {history?.functionName?.length > 2 ? (
                history?.functionName?.replace(/\([^)]*\)/, "")
              ) : (
                <>
                  {history?.from?.toLowerCase() === address?.toLowerCase()
                    ? "Send"
                    : "Recive"}
                </>
              )}
            </div>
            <div className="items">
              {history?.functionName?.length > 2
                ? ethers?.utils
                    ?.formatEther(
                      (history?.gasUsed * history?.gasPrice)?.toString()
                    )
                    ?.toString()
                : ethers?.utils?.formatEther(history?.value)?.toString()}{" "}
              MATIC
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityList;
