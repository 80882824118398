export enum RouteEnum {
  home = "/",
  login = "/auth/login",
  explore = "/explore",
  request = "/requests",
  walletConnected = "/connect-wallet",
  register = "/auth/register",
  forgotPassword = "/auth/forgotpassword",
  dashboard = "/dashboard",
  activity = "/dashboard/activity",
  exploreIp = "/dashboard/exploreIp",
  savedIp = "/dashboard/saved-ip",
  accountPreferences = "/setting/account-preferences",
  contactInfo = "/setting/contact-info",
  pendingForActivation = "/auth/pendingForActivation",
  authenticationResult = "/authenticationResult",
  generalPreferences = "/setting/general-preferences",
  profile = "/profile/",
  mint = "/mint",
  whitePaper = "/",
  aboutUs = "/about-us",
  faq = "/faq",
  tos = "/tos",
  privacy = "/privacy",
  searchResult = "/search",
  publicIp = "/ip/:ipDetail",
  news = "/news/:newsid",
  setting = "/setting/general-preferences",
  requests = "/requests",
}

export const ApiEnum = {
  // IP_API = "https://ip-api.sensifia.vc",
  // ACCOUNT_API = "https://accounts-api.sensifia.vc/",
  // STORAGE_API = "https://storage-api.sensifia.vc",
  // IP_API = "http://192.168.0.28:2001",
  // IP_API = "https://api.ipminter.com",
  // ACCOUNT_API = "https://accounts-api.sensifia.vc",
  // STORAGE_API = "https://storage-api.sensifia.vc",
  // DeepLink = "https://app.ip-cafe.com/auth/login",
  // // contractCafe = "0x98815B04beE3d13c6f6e4649afC6ae1249c705e5",
  // IPMINTER_ADD = "ipminter.com",
  IP_API: process.env.REACT_APP_IP_API,
  CAPTAIN_API: process.env.REACT_APP_CAPTAIN_API,
  // IP_API : "https://api-dev.ipminter.com",
  ACCOUNT_API: process.env.REACT_APP_ACCOUNT_API,
  STORAGE_API: process.env.REACT_APP_STORAGE_API,
  DeepLink: process.env.REACT_APP_DeepLink,
  contractCafe: process.env.REACT_APP_contractCafe,
  IPMINTER_ADD: process.env.REACT_APP_IPMINTER_ADD,
  IPMINTER_APP: process.env.REACT_APP_IPMINTER_APP,
  IPCAPTAIN_APP: process.env.REACT_APP_IPCAPTAIN_APP,
  contractMinter: process.env.REACT_APP_contractMinter,
  CLAIM_TOKEN: process.env.REACT_APP_CLAIM_TOKEN,

  TEST: process.env.REACT_APP_SOME_KEY,
};
export const Contracts = {
  SAFE_MINT: process.env.REACT_APP_SAFEMINT_CONTRACT,
  USDT: process.env.REACT_APP_USDT_CONTRACT,
  DISCOUNT: process.env.REACT_APP_DISCOUNT_CONTRACT,
};
export enum AuthEnum {
  token = "token",
  userId = "userId",
  refreshToken = "refreshToken",
  accessTokenExpiration = "accessTokenExpiration",
  referralCode = "referralCode",
}
export enum ChainEnum {
  Polygon = "NN_POLYGON",
  Goreli = "NN_GOERLI",
  Ethereum = "NN_ETHEREUM",
  Optimism = "NN_OPTIMISM",
  Binance = "NN_BINANCE",
  Arbitrum = "NN_ARBITRUM",
}

export enum env {
  PRODUCTION = 1,
}
export enum routeEnum {
  steps = "steps",
  basicInfo = "basic-info",
  product = "product",
  market = "market",
  businessModel = "business-model",
  finance = "finance",
  traction = "traction",
  risks = "risks",
  other = "other",
  formApplication = "/app",
}

export enum StartUpFormStatus {
  SFS_PENDING = "Pending for review",
  SFS_ACCEPTED = "Accepted",
  SFS_REJECTED = "Rejected",
  SFS_UPDATE = "Need for revise",
  SFS_UNDER_REVIEW = "Under review",
  SFS_DRAFT = "Draft",
  SFS_CANCEL = "Canceled",
}

export enum EventType {
  FUM_EVENT = "ET_FUM_BLOCKCHAIN_EVENT_2024",
  GLOBAL = "ET_GLOBAL",
}
