import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const Market = (props: Props) => {
  const { marketInformation, setApplicationData, accessToken, id, appStatus } =
    useContext(FormApplicationContext);

  let bissinesModelsForMArket = ["B2G", "B2B2C", "B2C", "B2B"];

  const requestDataDebounce = useDebounce(marketInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          marketInformation: { ...requestDataDebounce },
        },
      });

    if (hasValue(marketInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Market and Competition</h1>

      <div className="form-controller text">
        <label className="required">
          What is your target market:(Industry, Demographics, Geographical
          Focus){" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              marketInformation: {
                ...marketInformation,
                targetMarket: event.target.value,
              },
            }));
          }}
          value={marketInformation?.targetMarket}
        ></textarea>
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">What is your estimated market size</label>
        <input
          onChange={(event) => {
            // if (isNaN(parseInt(event.target.value))) {
            //   if
            // }

            setApplicationData((state) => ({
              ...state,
              marketInformation: {
                ...marketInformation,
                estimatedMarketSize: event.target.value,
              },
            }));
          }}
          value={marketInformation?.estimatedMarketSize}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput">
        <label className="required">Specify your business model</label>
        <div className="select-controller">
          <select
            onChange={(event) => {
              setApplicationData((state) => ({
                ...state,
                marketInformation: {
                  ...marketInformation,
                  businessModel: event.target.value,
                },
              }));
            }}
            value={marketInformation?.businessModel}
            name=""
            id=""
          >
            <option value="B2B">B2B</option>
            <option value="B2C">B2C</option>
            <option value="B2B2C">B2B2C</option>
            <option value="B2G">B2G</option>
            <option
              value={
                !bissinesModelsForMArket.includes(
                  marketInformation?.businessModel
                )
                  ? marketInformation?.businessModel
                  : "Other"
              }
            >
              Other
            </option>
          </select>
          <input
            placeholder="Other"
            onChange={(event) => {
              setApplicationData((state) => ({
                ...state,
                marketInformation: {
                  ...marketInformation,
                  businessModel: event.target.value,
                },
              }));
            }}
            disabled={bissinesModelsForMArket.includes(
              marketInformation?.businessModel
            )}
            value={
              !bissinesModelsForMArket.includes(
                marketInformation?.businessModel
              )
                ? marketInformation?.businessModel
                : ""
            }
            type="text"
            required
          />
        </div>
      </div>
      <div className="form-controller text">
        <label className="required">Who are your top competitors? </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              marketInformation: {
                ...marketInformation,
                topCompetitors: event.target.value,
              },
            }));
          }}
          value={marketInformation?.topCompetitors}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          What are your top competitive advantages?{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              marketInformation: {
                ...marketInformation,
                competitiveAdvantages: event.target.value,
              },
            }));
          }}
          value={marketInformation?.competitiveAdvantages}
        ></textarea>
      </div>
    </div>
  );
};

export default Market;
