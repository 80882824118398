import { copyIcon } from "../../assets/icons";
import "./style.css";
import QRCode from "react-qr-code";

const InfoModal = ({ showModal, onClose, address }) => {
  return (
    <div className={`wallet-info-container ${showModal ? "show" : ""}`}>
      <div className="wallet-info">
        <span
          className="close"
          onClick={() => {
            console.log("lkedmfekemf");

            onClose(false);
          }}
        >
          &times;
        </span>
        <h2>Referral Invitation Link</h2>
        <div
          style={{
            background: "white",

            display: "grid",
            placeItems: "center",
          }}
        >
          <QRCode
            value={address || "loading"}
            style={{
              width: "80%",
            }}
          />
        </div>

        <div
          className="wallet-address"
          onClick={() => {
            navigator.clipboard.writeText(address);
          }}
        >
          <p
            style={{
              margin: 0,
            }}
          >
            {address}
          </p>
          <img src={copyIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
