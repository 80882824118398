import { Contract, ethers } from "ethers";
import React, { useContext, useState } from "react";
import { ApiEnum, Contracts } from "../../../../types/enum.types";
import { ERC20_ABI } from "@biconomy/modules";
import { providerPolygan } from "../../../../config/providerConfig";
import { DiscountAbi, SafeMintAbi, usdtAbi } from "../../../../utils/ABI";
import minterAbi from "./IPMinterABI.json";
import { biconomyContext } from "../../../../utils/Context";
import {
  IHybridPaymaster,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";

import { parseEther } from "ethers/lib/utils";
import Spinner from "../../../../components/Spinner/Spinner";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { async } from "q";

type Props = {
  value: any;
  hasMoney: boolean;
  discountId: string;
  handelSubmit: Function;
};

const ApllicationMint = ({
  value,

  discountId,
}: Props) => {
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const { id } = useParams();
  const { smartAccount, address } = useContext(biconomyContext);

  const mint = async () => {
    setIsloading(true);

    try {
      const provider = new ethers.providers.JsonRpcProvider(
        // "https://polygon-bor.publicnode.com",
        "https://polygon-bor.publicnode.com",
        "any"
      );
      const usdtContract = new ethers.Contract(
        Contracts.USDT,
        ERC20_ABI,
        provider
      );
      toast.message("creating transaction");
      const balance = await usdtContract.balanceOf(address);

      console.log(id, balance, value, discountId);
      console.log(ethers.utils.formatUnits(balance, 6));

      if (
        parseFloat(ethers.utils.formatUnits(balance, 6)) < parseFloat(value)
      ) {
        toast.error("Insufficient Balance");
        setIsloading(false);
        return;
      }

      const discountContract = new ethers.Contract(
        Contracts.DISCOUNT,
        DiscountAbi,
        provider
      );
      const mintContract = new ethers.Contract(
        Contracts.SAFE_MINT,
        SafeMintAbi,
        provider
      );
      const usdtData = await usdtContract.populateTransaction.approve(
        Contracts.SAFE_MINT,
        ethers.utils.parseEther(value)
      );
      const discountData =
        await discountContract.populateTransaction.setApprovalForAll(
          Contracts.SAFE_MINT,
          true
        );

      const mintData = await mintContract.populateTransaction.safeMint(
        discountId,
        `${ApiEnum.CAPTAIN_API}/metadata/${id}`,
        `0x${id}`
      );

      const usdt = {
        to: Contracts.USDT,
        data: usdtData.data,
      };
      const tx4 = {
        to: Contracts.DISCOUNT,
        data: discountData.data,
      };
      const tx1 = {
        to: Contracts.SAFE_MINT,
        data: mintData.data,
      };
      debugger;

      let partialUserOp2 = await smartAccount.buildUserOp([usdt]);

      toast.message("bundling transactions");
      const biconomyPaymaster =
        smartAccount.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      let paymasterServiceData: SponsorUserOperationDto = {
        mode: PaymasterMode.SPONSORED,
        smartAccountInfo: {
          name: "BICONOMY",
          version: "2.0.0",
        },
        calculateGasLimits: true,
      };
      try {
        const paymasterAndDataWithLimits =
          await biconomyPaymaster.getPaymasterAndData(
            partialUserOp2,
            paymasterServiceData
          );

        partialUserOp2.paymasterAndData =
          paymasterAndDataWithLimits.paymasterAndData;

        if (
          paymasterAndDataWithLimits.callGasLimit &&
          paymasterAndDataWithLimits.verificationGasLimit &&
          paymasterAndDataWithLimits.preVerificationGas
        ) {
          // Returned gas limits must be replaced in your op as you update paymasterAndData.
          // Because these are the limits paymaster service signed on to generate paymasterAndData
          // If you receive AA34 error check here..

          partialUserOp2.callGasLimit = paymasterAndDataWithLimits.callGasLimit;
          partialUserOp2.verificationGasLimit =
            paymasterAndDataWithLimits.verificationGasLimit;
          partialUserOp2.preVerificationGas =
            paymasterAndDataWithLimits.preVerificationGas;
        }
        const paymasterAndDataResponse =
          await biconomyPaymaster.getPaymasterAndData(
            partialUserOp2,
            paymasterServiceData
          );
        partialUserOp2.paymasterAndData =
          paymasterAndDataResponse.paymasterAndData;

        toast.message("sending transactions on blockchain");
        const userOpResponse = await smartAccount.sendUserOp(partialUserOp2);

        const transactionDetails = await userOpResponse.wait();

        toast.success("minted successfuly");

        console.log("Transaction Details:", transactionDetails);
        console.log("Transaction Hash:", userOpResponse.userOpHash);
        setIsloading(true);
        navigate("/requests");
      } catch (e) {
        setIsloading(false);

        toast.error("Error executing transaction:", e);
        console.error("Error executing transaction:", e);
      }
    } catch (error) {
      console.log(error);
      toast.error("Network Error");
      setIsloading(false);
    }
  };

  return (
    <>
      <button
        className="apllication-mint-btn"
        onClick={() => {
          if (isLoading) return;
          mint();
        }}
      >
        {isLoading ? <Spinner /> : "Start Mint"}
      </button>
    </>
  );
};

export default ApllicationMint;
