import React, { useEffect, useRef, useState } from "react";
import "./UserNfts.css";
import { NFTDOCIcon } from "../../assets/icons";
import axios from "axios";
import { ApiEnum } from "../../types/enum.types";
import { useSelector } from "react-redux";
const UserNfts = ({ setNftBox, setNFTData, nftBox, setRequestData }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [scrollIPS, setScrollIPS] = useState(12);
  const refScroll = useRef(null);
  const ref = useRef(null);
  const [searchVAlue, setSearchVAlue] = useState();
  console.log("🚀 ~ file: Assets.jsx:10 ~ Assets ~ searchVAlue:", searchVAlue);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [userNfts, setUserNfts] = useState();
  // Update scroll position on scroll
  useEffect(() => {
    const handleScroll = () => {
      const element = refScroll.current;
      if (element) {
        const isBottom =
          element.scrollHeight - element.scrollTop <= element.clientHeight + 1;
        if (isBottom) {
          setScrollIPS((prevScrollAsset) => prevScrollAsset + 4);
        }
      }
    };
    const scrollElement = refScroll.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);

      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [refScroll, scrollIPS]);
  const getAccountIPS = async () => {
    try {
      var config = {
        method: "get",
        url: ApiEnum.CAPTAIN_API + `/captain/ips/me?pageSize=${scrollIPS}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios(config);
      setUserNfts(response);
      console.log("response:", response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (scrollIPS <= 12) {
      getAccountIPS();
    }
    if (scrollIPS < Number(userNfts?.data?.response.totalItemsCount)) {
      getAccountIPS();
    }
  }, [scrollIPS]);
  useEffect(() => {
    // Function to disable scroll
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Function to enable scroll
    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    const checkIfClickedOutsideModal = (e) => {
      if (nftBox && ref.current && !ref.current.contains(e.target)) {
        setNftBox(false);
        enableScroll(); // Enable scroll when modal is closed
      }
    };

    if (nftBox) {
      disableScroll(); // Disable scroll when modal is open
    }

    document.addEventListener("mousedown", checkIfClickedOutsideModal);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideModal);
      enableScroll(); // Make sure scroll is enabled when component unmounts
    };
  }, [nftBox]);
  return (
    <div className="userNftContainer">
      <div className="userNftContent" ref={ref}>
        <div className="headerAssetModal">
          <h4>Select a NFT</h4>
          <button onClick={() => setNftBox(false)}>&#x2715;</button>
        </div>
        <div className="sereachContainer">
          <input
            type="search"
            onChange={(e) => setInputSearchValue(e.target.value)}
            placeholder="Search name "
          />
          <div></div>
        </div>
        <div className="NftOptions" ref={refScroll}>
          {userNfts?.data?.data
            ?.filter((el, i) => {
              if (!inputSearchValue) return el;
              if (el.name.includes(inputSearchValue)) return el;
            })
            .map((data, i) => (
              <button
                key={i}
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    ipId: data?.id,
                  }));
                  setNFTData(data);
                  setNftBox(false);
                }}
              >
                <div className="NftsContainer-img">
                  <img
                    src={`https://arweave.net/${data?.file?.transactionHash}`}
                    onError={(event) => {
                      event.target.src = NFTDOCIcon;
                      event.target.onerror = null;
                    }}
                  />
                </div>

                <p className="titleNfts">
                  {data?.name?.slice(0, 11)}
                  {data?.name?.length > 10 && "..."}
                </p>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserNfts;
