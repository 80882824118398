import { useContext } from "react";
import "./style.css";
import QRCode from "react-qr-code";
import { biconomyContext } from "../../utils/Context";
import copyIcon from "./copy.svg";

const InfoModal = ({ showModal, onClose, address }) => {
  const { toast } = useContext(biconomyContext);
  return (
    <div className={`wallet-info-container ${showModal ? "show" : ""}`}>
      <div className="wallet-info">
        <span
          className="close"
          onClick={() => {
            console.log("lkedmfekemf");

            onClose(false);
          }}
        >
          &times;
        </span>
        <h2 className="wallet-header">Wallet Address</h2>
        <div
          style={{
            background: "white",
            boxShadow:
              "0px 3.430962324142456px 11.150627136230469px 0px #00000038",

            display: "grid",
            placeItems: "center",
            borderRadius: "12px",
            width: "180px",
            height: "205px",
          }}
        >
          <QRCode
            value={address || "loading"}
            style={{
              width: "90%",
              height: "100%",
            }}
          />
          <p
            style={{
              wordBreak: "break-word",
              fontSize: "10px",
              textAlign: "center",
              padding: "0 5px",
            }}
          >
            {address}
          </p>
        </div>
        <button
          onClick={() => {
            navigator.clipboard.writeText(address);
            toast.success("copied to clipboard");
          }}
          className="copy-icon"
        >
          <img src={copyIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default InfoModal;
