import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image/Image";

import insta from "../../images/Instagram.png";
import linkdin from "../../images/Linkedin.png";
import facebook from "../../images/Facebook.png";
import twiter from "../../images/Twitter.png";
import UserNfts from "../../../../components/Modals/UserNfts";
import { ApiEnum } from "../../../../types/enum.types";
import { useSingleIpQuery } from "../../../../store/api/ipApi";

const Step1 = ({ requestData, setRequestData, setNFTData, NFTData }) => {
  const [nftBox, setNftBox] = useState(false);
  const [socialsState, setSocialsState] = useState({
    linkedin: Boolean(requestData?.formSocial?.linkedin),
    instagram: Boolean(requestData?.formSocial?.instagram),
    facebook: Boolean(requestData?.formSocial?.facebook),
    twitter: Boolean(requestData?.formSocial?.twitter),
  });
  // console.log(socialsState);
  console.log("🚀 ~ file: Step1.tsx:22 ~ Step1 ~ socialsState:", socialsState);
  console.log(
    "🚀 ~ file: Step1.tsx:22 ~ Step1 ~ socialsState:",
    requestData?.formSocial
  );

  useEffect(() => {
    setSocialsState((state) => ({
      ...state,
      linkedin: Boolean(requestData?.formSocial?.linkedin),
      instagram: Boolean(requestData?.formSocial?.instagram),
      facebook: Boolean(requestData?.formSocial?.facebook),
      twitter: Boolean(requestData?.formSocial?.twitter),
    }));
  }, [requestData?.formSocial]);

  const { data: ipData } = useSingleIpQuery(
    { ipId: requestData?.ipId },
    {
      skip:
        !Boolean(requestData?.ipId) ||
        requestData?.ipId == "000000000000000000000000",
    }
  );
  return (
    <>
      <div className="wrapper">
        <h1 className="title">Step 1: General Information</h1>
        <ul className="loading-form">
          <li className="active">1</li>
          <li>2</li>
          <li>3</li>
        </ul>
        <div className="form">
          <div className="formControl">
            <label className="required">Startup or Idea Name</label>
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  startupName: event.target.value,
                }));
              }}
              type="text"
              className="txt "
              value={requestData.startupName}
              required
            />
          </div>
          <div className="formControl">
            <label className="required"> Sector / Industry </label>
            <select
              value={requestData.industry}
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  industry: event.target.value,
                }));
              }}
              className="txt"
            >
              <option value="1">Please Select</option>
              <option value="Agriculture">Agriculture</option>
              <option value="Automotive">Automotive</option>
              <option value="Technology">Technology</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Finance">Finance</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Retail">Retail</option>
              <option value="Energy">Energy</option>
              <option value="Hospitality and Tourism">
                Hospitality and Tourism
              </option>
              <option value="Real Estate">Real Estate</option>
              <option value="Media and Entertainment">
                Media and Entertainment
              </option>
              <option value="Education">Education</option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="Aerospace">Aerospace</option>
              <option value="Consumer Goods">Consumer Goods</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Environmental Services">
                Environmental Services
              </option>
              <option value="Construction">Construction</option>
              <option value="Logistics and Transportation">
                Logistics and Transportation
              </option>
              <option value="Legal Services">Legal Services</option>
              <option value="Nonprofit">Nonprofit</option>
              <option value="Government">Government</option>
              <option value="Fitness and Wellness">Fitness and Wellness</option>
              <option value="Social Media and Networking">
                Social Media and Networking
              </option>
              <option value="Pharmaceuticals">Pharmaceuticals</option>
            </select>
          </div>
          <div className="formControl">
            <label className="required label2">
              {" "}
              Founder or Owner Full Name
            </label>
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  founderName: {
                    ...prevState.founderName,
                    firstName: event.target.value,
                  },
                }));
              }}
              type="text"
              className="txt txt3"
              placeholder="First Name"
              value={requestData.founderName.firstName}
            />
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  founderName: {
                    ...prevState.founderName,
                    lastName: event.target.value,
                  },
                }));
              }}
              type="text"
              className="txt txt3"
              placeholder="Last Name"
              value={requestData.founderName.lastName}
            />
          </div>
          <div className="formControl">
            <label>Select your IP</label>
            <p
              onClick={() => setNftBox(true)}
              className="txt txt5"
              placeholder="Your IP name"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {NFTData
                ? NFTData?.name
                : requestData?.ipId !== "000000000000000000000000"
                ? ipData?.ip?.name
                : "Select Your IP "}
            </p>
            <a
              href={"https://" + ApiEnum.IPMINTER_ADD}
              target="_blank"
              className="btn"
              style={{
                fontSize: "16px",
                height: "45px",
              }}
            >
              Mint new IP
            </a>
          </div>
          <div className="formControl">
            <label className="required"> Email</label>
            <input
              type="text"
              className="txt"
              placeholder="example@example.com"
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  email: event.target.value,
                }));
              }}
              value={requestData.email}
            />
          </div>
          <div className="formControl">
            <label> Phone Number</label>
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  mobile: event.target.value,
                }));
              }}
              type="text"
              className="txt"
              placeholder="(000) 000-000"
              value={requestData.mobile}
            />
          </div>
          <div className="formControl">
            <label>Address </label>
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  formAddress: {
                    ...prevState.formAddress,
                    city: event.target.value,
                  },
                }));
              }}
              type="text"
              className="txt txt3"
              placeholder="City"
              value={requestData.formAddress.city}
            />
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  formAddress: {
                    ...prevState.formAddress,
                    country: event.target.value,
                  },
                }));
              }}
              type="text"
              className="txt txt3"
              placeholder="Country"
              value={requestData.formAddress.country}
            />
          </div>
          {/* <div className="formControl">
            <label className="label3">
              Which channel(s) would you like to share with us?{" "}
            </label>
            <ul className="social">
              <li>
                <button
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      linkedin: !state.linkedin,
                    }))
                  }
                >
                  <Image ImgSrc={linkdin} />
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      facebook: !state.facebook,
                    }))
                  }
                >
                  <Image ImgSrc={facebook} />
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      twitter: !state.twitter,
                    }))
                  }
                >
                  <Image ImgSrc={twiter} />
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      instagram: !state.instagram,
                    }))
                  }
                >
                  <Image ImgSrc={insta} />
                </button>
              </li>
            </ul>
          </div> */}
          {
            <div className="formControl">
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
              </div> */}
              {/* <input type="checkbox" name="" id="" /> */}
              <label className="socialmedia-label">
                <input
                  checked={socialsState.instagram}
                  type="checkbox"
                  name=""
                  id=""
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      instagram: !state.instagram,
                    }))
                  }
                />
                instagram{" "}
              </label>
              <input
                type="text"
                className="txt"
                placeholder="instagram ID"
                disabled={!socialsState.instagram}
                onChange={(event) => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    formSocial: {
                      ...prevState.formSocial,
                      instagram: event.target.value,
                    },
                  }));
                }}
                value={requestData.formSocial.instagram}
              />
            </div>
          }
          {
            <div className="formControl">
              <label className="socialmedia-label">
                <input
                  checked={socialsState.twitter}
                  type="checkbox"
                  name=""
                  id=""
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      twitter: !state.twitter,
                    }))
                  }
                />
                twitter{" "}
              </label>
              <input
                type="text"
                className="txt"
                disabled={!socialsState.twitter}
                placeholder="twitter ID"
                onChange={(event) => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    formSocial: {
                      ...prevState.formSocial,
                      twitter: event.target.value,
                    },
                  }));
                }}
                value={requestData.formSocial.twitter}
              />
            </div>
          }
          {
            <div className="formControl">
              <label className="socialmedia-label">
                <input
                  checked={socialsState.linkedin}
                  type="checkbox"
                  name=""
                  id=""
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      linkedin: !state.linkedin,
                    }))
                  }
                />
                linkedin{" "}
              </label>
              <input
                type="text"
                className="txt"
                disabled={!socialsState.linkedin}
                placeholder="linkdin ID"
                onChange={(event) => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    formSocial: {
                      ...prevState.formSocial,
                      linkedin: event.target.value,
                    },
                  }));
                }}
                value={requestData.formSocial.linkedin}
              />
            </div>
          }
          {
            <div className="formControl">
              <label className="socialmedia-label">
                <input
                  checked={socialsState.facebook}
                  type="checkbox"
                  name=""
                  id=""
                  onClick={() =>
                    setSocialsState((state) => ({
                      ...state,
                      facebook: !state.facebook,
                    }))
                  }
                />
                facebook{" "}
              </label>
              <input
                type="text"
                className="txt"
                disabled={!socialsState.facebook}
                placeholder="facebook ID"
                onChange={(event) => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    formSocial: {
                      ...prevState.formSocial,
                      facebook: event.target.value,
                    },
                  }));
                }}
                value={requestData.formSocial.facebook}
              />
            </div>
          }
          <div className="formControl">
            <label>Website </label>
            <input
              type="text"
              className="txt"
              placeholder="www.example.com"
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  formSocial: {
                    ...prevState.formSocial,
                    website: event.target.value,
                  },
                }));
              }}
              value={requestData.formSocial.website}
            />
          </div>
        </div>
      </div>
      {nftBox && (
        <UserNfts
          setRequestData={setRequestData}
          setNftBox={setNftBox}
          setNFTData={setNFTData}
          nftBox={nftBox}
        />
      )}
    </>
  );
};

export default Step1;
