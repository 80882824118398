import { useContext, useState } from "react";
import "../../../../components/Wallet/style.css";
import refresh from "./refresh.svg";
import { biconomyContext } from "../../../../utils/Context";
import { ERC20_ABI } from "@biconomy/modules";
import { ethers } from "ethers";
import "./style.css";
import {
  IHybridPaymaster,
  PaymasterFeeQuote,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";
import Spinner from "../../../../components/Spinner/Spinner";
import { providerPolygan } from "../../../../config/providerConfig";
import GasFee from "./GasFee";
import { useSelector } from "react-redux";

const Transfer = ({
  showModal,
  onClose,
  token,
  balance,
  getBalance,
  setChangeUsdtBlance,
}) => {
  const { usdt }: any = useSelector<any>((state) => state.wallet.wallet);
  console.log("🚀 ~ file: Transfer.tsx:21 ~ Transfer ~ usdt:", usdt);
  const { address, smartAccount, toast } = useContext(biconomyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [toAddress, setToAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [txHash, setTxHash] = useState("");
  const [transactionStatus, setTransactionStatus] = useState({
    isSuccess: {
      tx: "",
      success: false,
    },
    isFailed: {
      message: "",
      failed: false,
    },
  });

  const transfer = async () => {
    // let temp = providerPolygan.sendTransaction()
    if (!toAddress) {
      toast.error("please enter the address");
      return;
    }
    if (!amount) {
      toast.error("please enter the amount");
      return;
    }

    if (amount > balance) {
      toast.error("insufficient balance");

      return;
    }

    setIsLoading(true);
    const contract = new ethers.Contract(
      token.address,
      ERC20_ABI,
      providerPolygan
    );

    const countData = await contract.populateTransaction.transfer(
      toAddress,
      ethers.utils.parseUnits(amount + "", token.decimals)
    );

    const transferTx = {
      to: token.address,
      data: countData.data,
      value: ethers.utils.parseEther(amount + ""),
    };
    if (token.address != "0x0000000000000000000000000000000000001010") {
      delete transferTx.value;
    }
    try {
      let userOp = await smartAccount.buildUserOp([transferTx]);

      const biconomyPaymaster =
        smartAccount.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      const feeQuotesResponse =
        await biconomyPaymaster.getPaymasterFeeQuotesOrData(userOp, {
          mode: PaymasterMode.ERC20,
          tokenList: ["0xc2132d05d31c914a87c6611c10748aeb04b58e8f"],
        });

      const feeQuotes = feeQuotesResponse.feeQuotes as PaymasterFeeQuote[];
      const spender = feeQuotesResponse.tokenPaymasterAddress || "";
      const usdtFeeQuotes = feeQuotes[0];
      const gasFee = usdtFeeQuotes?.maxGasFeeUSD;

      toast.message(`the maximum gasfee is: ${gasFee}`);

      if (token.address == "0xc2132d05d31c914a87c6611c10748aeb04b58e8f") {
        let totalTransferableAmout = usdt - gasFee;
        if (amount > totalTransferableAmout) {
          setTransactionStatus((state) => ({
            ...state,
            isFailed: {
              message: "insufficient balance to pay gas",
              failed: true,
            },
          }));
          setIsLoading(false);
          return;
        }
      }
      if (usdt < gasFee) {
        setTransactionStatus((state) => ({
          ...state,
          isFailed: {
            failed: true,
            message: "insufficient balance to pay gas",
          },
        }));
        setIsLoading(false);

        return;
      }

      const finalUserOp = await smartAccount.buildTokenPaymasterUserOp(userOp, {
        feeQuote: usdtFeeQuotes,
        spender: spender,
        maxApproval: false,
      });

      let paymasterServiceData = {
        mode: PaymasterMode.ERC20,
        feeTokenAddress: usdtFeeQuotes.tokenAddress,
        calculateGasLimits: true, // Always recommended and especially when using token paymaster
      };

      try {
        const paymasterAndDataWithLimits =
          await biconomyPaymaster.getPaymasterAndData(
            finalUserOp,
            paymasterServiceData
          );

        finalUserOp.paymasterAndData =
          paymasterAndDataWithLimits.paymasterAndData;

        if (
          paymasterAndDataWithLimits.callGasLimit &&
          paymasterAndDataWithLimits.verificationGasLimit &&
          paymasterAndDataWithLimits.preVerificationGas
        ) {
          // Returned gas limits must be replaced in your op as you update paymasterAndData.
          // Because these are the limits paymaster service signed on to generate paymasterAndData
          // If you receive AA34 error check here..

          finalUserOp.callGasLimit = paymasterAndDataWithLimits.callGasLimit;
          finalUserOp.verificationGasLimit =
            paymasterAndDataWithLimits.verificationGasLimit;
          finalUserOp.preVerificationGas =
            paymasterAndDataWithLimits.preVerificationGas;
        }

        const userOpResponse = await smartAccount.sendUserOp(finalUserOp);
        console.log("userOpHash", userOpResponse);
        const res = await userOpResponse.wait(1);
        console.log(res);

        console.log("txHash", res.receipt.transactionHash);
        // setTxHash(res.receipt.transactionHash);
        if (res.success == "success") {
          setTransactionStatus((state) => ({
            ...state,
            isSuccess: {
              tx: res.receipt.transactionHash,
              success: true,
            },
          }));
        } else {
          setTransactionStatus((state) => ({
            ...state,
            isFailed: {
              message: "transaction faild",
              failed: false,
            },
          }));
        }
        getBalance();
        setChangeUsdtBlance(true);
        setIsLoading(false);
      } catch (error) {
        console.log("paymaster", error);
        toast.error(error?.message);
        setIsLoading(false);
        setTransactionStatus((state) => ({
          ...state,
          isFailed: {
            message: error?.message,
            failed: false,
          },
        }));
      }
    } catch (error) {
      setTransactionStatus((state) => ({
        ...state,
        isFailed: {
          message: "Network Error",
          failed: false,
        },
      }));
      console.log("first", error);
      setIsLoading(false);
    }
  };
  return (
    <div className={`transfer-info-container ${showModal ? "show" : ""}`}>
      <div className="transfer-info">
        <span
          className="close"
          onClick={() => {
            console.log("lkedmfekemf");

            onClose(false);
          }}
        >
          &times;
        </span>
        <h2 className="transfer-header">Transfer </h2>
        <div className="transfer-to">
          <span>To</span>
          <input
            onChange={(e) => {
              setToAddress(e.target.value);
            }}
            value={toAddress}
            type="text"
            placeholder="0x1221871c372A25163D6BF6c771ce322B66B43214"
          />
        </div>

        <div className="transfer-asset">
          <span>Asset</span>
          <div className="transfer-asset-amount">
            <p className="grey">Balance: </p>
            <p>{Math.floor(parseFloat(balance) * 10000000) / 10000000}</p>
            <img src={token.logoURI} alt="" />
            <p className="grey">{token.symbol}</p>
          </div>
        </div>
        <div className="transfer-asset">
          <span>
            Amount
            {/* <button>max</button> */}
          </span>
          <div className="transfer-asset-amount">
            <input
              onChange={(e) => {
                setAmount(parseFloat(e.target.value));
              }}
              value={amount}
              type="number"
              placeholder="0.0"
              min={0}
            />
            <GasFee tokenAddress={token.address} toAddress={address} />
          </div>
          <button
            className="transfer-btn"
            onClick={() => {
              transfer();
            }}
          >
            {isLoading ? <Spinner /> : "Transfer"}
          </button>
          {transactionStatus.isSuccess.success && (
            <div className="transfer-message success">
              <p>Transfer Succeed:</p>
              <a
                target="_blank"
                className=""
                href={`https://polygonscan.com/tx/${transactionStatus.isSuccess.tx}`}
              >
                see detail in polygonscan
              </a>
            </div>
          )}
          {transactionStatus.isFailed.failed && (
            <div className="transfer-message error">
              <p>Transfer failed</p>
              <p>{transactionStatus.isFailed.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transfer;
