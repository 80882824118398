import React, { useEffect, useState } from "react";
import warningIcon from "../images/warning-2.svg";
import editIcon from "../images/edit.svg";
import previewIcon from "../images/preview.svg";
import certIcon from "../images/cert.svg";
import ExportIcon from "../images/import.svg";
import deleteIcon from "../images/delete.svg";
import Status from "./Status";
import { useNavigate } from "react-router-dom";
import DeleteAction from "./DeleteAction";
import { closeIcon } from "../../../assets/icons";
import ToolTibHover from "../../../components/ToolTip/ToolTibHover";
import ToolTibHoverImg from "../../../components/ToolTip/ToolTibHoverForImg";
import { ApiEnum, EventType, routeEnum } from "../../../types/enum.types";
import ChallengeBAdge from "../images/ChallengeBadge.png";
import { useGetPdfIdQuery } from "../../../store/api/requestApi";
import { useSelector } from "react-redux";
const body = document.querySelector("body");
type Props = {
  setModalState;
  application;
  refetch;
  isAuditore;
};

const SingleApplicationModal = ({
  setModalState,
  application,
  refetch,
  isAuditore,
}: Props) => {
  console.log(
    "🚀 ~ file: SingleApplicationModal.tsx:24 ~ isAuditore:",
    application
  );
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
  });
  const { data, isError } = useGetPdfIdQuery(
    { accessToken, id: application?.id },
    { skip: !Boolean(accessToken) }
  );
  useEffect(() => {
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="application-modal-container">
      {deleteModal.visible ? (
        <DeleteAction
          setModalState={setModalState}
          refetch={refetch}
          requestId={application?.id}
          setDeleteModal={setDeleteModal}
        />
      ) : (
        <></>
      )}
      <div
        className="application-modal"
        style={{
          overflow: "hidden",
        }}
      >
        {application?.eventType === EventType.FUM_EVENT && (
          <img
            src={ChallengeBAdge}
            alt=""
            style={{
              position: "absolute",
              top: "-10px",
            }}
          />
        )}
        <button
          className="modal-close"
          onClick={() => {
            setModalState((state) => ({ ...state, show: false }));
          }}
        >
          <img
            style={{
              width: "15px",
            }}
            src={closeIcon}
            alt=""
          />
        </button>
        <h1
          className="application-header"
          style={{
            padding: "0",
          }}
        >
          Application Details
        </h1>
        <div style={{ padding: "5px 10px" }}>
          <div
            className="modal-item"
            style={{
              justifyContent: "center",
              color: "rgba(63, 66, 84, 1)",
            }}
          >
            <p>{application?.basicInformation?.startupName}</p>
          </div>
          <div className="modal-item">
            <p>ID</p>
            <p className="accept">{application?.id}</p>
          </div>
          <div className="modal-item">
            <p>Abstract</p>
            <p className="">
              {application?.basicInformation?.abstract?.length > 100 ? (
                <>
                  {application?.basicInformation?.abstract?.slice(0, 100) +
                    "..."}
                </>
              ) : (
                <>{application?.basicInformation?.abstract}</>
              )}
            </p>
          </div>
          <div className="modal-item">
            <p>Status</p>
            <Status status={application?.status} />
          </div>

          {application?.statusReason && (
            <div className="modal-item" style={{ borderBottom: "none" }}>
              <p
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {" "}
                <ToolTibHoverImg
                  tooltipText={
                    "Your application has been reviewed by our team. You can see the reviewer's message here."
                  }
                >
                  <img src={warningIcon} alt="" />
                </ToolTibHoverImg>
                Auditor's Message :
              </p>
            </div>
          )}

          {application?.statusReason && (
            <div
              className="auditor-message"
              style={
                application?.statusReason.length < 195
                  ? {
                      overflow: "hidden",
                    }
                  : {}
              }
            >
              <p className="message">{application?.statusReason}</p>
            </div>
          )}

          {isAuditore ? (
            <>
              <button
                onClick={() => {
                  navigate(`/review/${application.id}`);
                }}
                className="btn-preview"
              >
                {/* <img
                  onClick={() => {
                    navigate(`/review/${application.id}`);
                  }}
                  src={previewIcon}
                  alt=""
                /> */}
                View Application
              </button>
            </>
          ) : (
            <div className="action-container">
              <div className="actions">
                {/* <>
                  <div
                    className={
                      (application.status === "SFS_DRAFT" || isError) &&
                      "disable"
                    }
                  >
                    <a
                      target={
                        application.status === "SFS_DRAFT" ? "" : `_blank`
                      }
                      href={
                        application.status === "SFS_DRAFT" || isError
                          ? "#"
                          : `${ApiEnum.STORAGE_API}/files?id=${data?.fileId}
                          `
                      }
                    >
                      <img src={ExportIcon} alt="" />
                    </a>
                    <span className="tag">Export Pdf</span>
                  </div>
                </> */}
                <>
                  <div
                    className={application.status === "SFS_DRAFT" && "disable"}
                  >
                    <a
                      target={
                        application.status === "SFS_DRAFT" ? "" : `_blank`
                      }
                      href={
                        application.status === "SFS_DRAFT"
                          ? "#"
                          : `/certificate/${application.id}`
                      }
                    >
                      <img src={certIcon} alt="" />
                    </a>
                    <span className="tag">Certificate</span>
                  </div>
                </>

                <div
                  // className={application.status === "SFS_DRAFT" && "disable"}
                  onClick={() => {
                    // if (application.status === "SFS_DRAFT") return;
                    navigate(`/review/${application.id}`);
                  }}
                >
                  <img src={previewIcon} alt="" />
                  <span className="tag">Preview</span>
                </div>
                <div
                  className={
                    application.status !== "SFS_DRAFT" &&
                    application.status !== "SFS_UPDATE"
                      ? "disable"
                      : ""
                  }
                  onClick={() => {
                    if (
                      application.status !== "SFS_DRAFT" &&
                      application.status !== "SFS_UPDATE"
                    )
                      return;
                    // navigate(`/form/${application.id}`);
                    navigate(
                      `${routeEnum.formApplication}/${application.id}/${routeEnum.steps}`
                    );
                  }}
                >
                  <img src={editIcon} alt="" />
                  <span className="tag">Edit</span>
                </div>

                <div
                  className={` ${
                    application.status !== "SFS_ACCEPTED" &&
                    application.status !== "SFS_UNDER_REVIEW"
                      ? ""
                      : "disable"
                  }`}
                  onClick={() => {
                    if (
                      application.status !== "SFS_ACCEPTED" &&
                      application.status !== "SFS_UNDER_REVIEW"
                    ) {
                      setDeleteModal((state) => ({
                        ...state,
                        visible: true,
                      }));
                    }
                  }}
                >
                  <img src={deleteIcon} alt="" />
                  <span
                    style={{
                      color: "#F53838",
                    }}
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleApplicationModal;
