import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const Product = (props: Props) => {
  const { productInformation, setApplicationData, accessToken, id, appStatus } =
    useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(productInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          productInformation: { ...requestDataDebounce },
        },
      });
    if (hasValue(productInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Product / Service Information</h1>

      <div className="form-controller text">
        <label className="required">Describe your product / service: </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              productInformation: {
                ...productInformation,
                description: event.target.value,
              },
            }));
          }}
          value={productInformation?.description}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          What problems does your product / service solve?{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              productInformation: {
                ...productInformation,
                problems: event.target.value,
              },
            }));
          }}
          value={productInformation?.problems}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          Key Features and Unique Selling Points:{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              productInformation: {
                ...productInformation,
                keyFeatures: event.target.value,
              },
            }));
          }}
          value={productInformation?.keyFeatures}
        ></textarea>
      </div>
    </div>
  );
};

export default Product;
