import React, { useState } from "react";
import { StartUpFormStatus } from "../../../types/enum.types";
import { certIcon, eyeIcon } from "../../../assets/icons";
import CertIcon from "./certIcon.svg";
import AuditorActionModal from "../../request-detail/components/AuditorActionModal";
import { Link } from "react-router-dom";
type Props = {};

const Status = (props: any) => {
  let formStatus = Object.keys(StartUpFormStatus);
  console.log(
    "🚀 ~ file: Status.tsx:12 ~ Status ~ StartUpFormStatus:",
    StartUpFormStatus,
    props.status
  );

  let requestStatus = {
    classList: "",
    statusName: "",
  };
  switch (props.status) {
    case formStatus[0]:
      // requestStatus.statusName = StartUpFormStatus[formStatus[0]];
      requestStatus.statusName = "Pending";
      requestStatus.classList = "pending";
      break;
    case formStatus[1]:
      requestStatus.statusName = StartUpFormStatus[formStatus[1]];
      requestStatus.classList = "accepted";
      break;
    case formStatus[2]:
      requestStatus.statusName = StartUpFormStatus[formStatus[2]];
      requestStatus.classList = "rejected";
      break;
    case formStatus[3]:
      requestStatus.statusName = StartUpFormStatus[formStatus[3]];
      requestStatus.classList = "update";
      break;
    case formStatus[4]:
      requestStatus.statusName = StartUpFormStatus[formStatus[4]];
      requestStatus.classList = "any";
      break;
    case formStatus[5]:
      requestStatus.statusName = StartUpFormStatus[formStatus[5]];
      requestStatus.classList = "draft";
      break;
    case formStatus[6]:
      requestStatus.statusName = StartUpFormStatus[formStatus[5]];
      requestStatus.classList = "cancel";
      break;

    default:
      break;
  }

  return (
    <>
      <p
        className={`status ${requestStatus.classList}`}
        style={{
          position: "relative",
        }}
      >
        {requestStatus.statusName}
        {/* <img
          src={ChallengeBadge}
          style={{
            position: "absolute",
            right: "-5px",
            top: "0",
            width: "27px",
            height: "27px",
          }}
          alt=""
        /> */}
      </p>
    </>
  );
};

export default Status;
