import React, { useState } from "react";
import { Link } from "react-router-dom";
import iconFill from "../../../../assets/icons/check-load.png";
import Image from "../../../../components/Image/Image";

const Step4 = ({ requestData, setRequestData }) => {
  const [introducer, setIntroducer] = useState({
    name: "",
    position: "",
    qualification: "",
  });
  console.log("🚀 ~ file: Step4.tsx:10 ~ Step4 ~ introducer:", introducer);
  const addIntroducer = () => {
    if (introducer.name && introducer.position && introducer.qualification) {
      setRequestData((prevState) => ({
        ...prevState,
        teamInformation: {
          ...prevState.teamInformation,
          introduceTeam: [
            ...prevState.teamInformation.introduceTeam, // Spread the existing array
            introducer, // Add the new introducer
          ],
        },
      }));
      // Clear the introducer fields after adding
      setIntroducer({
        name: "",
        position: "",
        qualification: "",
      });
    }
  };
  const deleteIntroducer = (index) => {
    setRequestData((prevState) => {
      const updatedIntroduceTeam = [...prevState.teamInformation.introduceTeam];
      updatedIntroduceTeam.splice(index, 1); // Remove introducer at the specified index
      return {
        ...prevState,
        teamInformation: {
          ...prevState.teamInformation,
          introduceTeam: updatedIntroduceTeam,
        },
      };
    });
  };
  return (
    <div className="wrapper">
      <h1 className="title">Step 3: Your Team Information</h1>
      <ul className="loading-form">
        <li className="fill">
          <Image ImgSrc={iconFill} />
        </li>
        <li>
          <Image ImgSrc={iconFill} />
        </li>
        <li className="active">3</li>
      </ul>
      <div className="form">
        <div className="formControl">
          <label className=" required">Do you have a team?</label>
          <div className="req-box">
            <label>
              <input
                onChange={(event) => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    teamInformation: {
                      ...prevState.teamInformation,
                      haveTeam: true,
                    },
                  }));
                }}
                checked={requestData.teamInformation?.haveTeam}
                value={requestData.teamInformation?.haveTeam}
                name="team"
                type="radio"
                className="radio"
              />
              Yes
            </label>
            <label>
              <input
                onChange={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    teamInformation: {
                      ...prevState.teamInformation,
                      haveTeam: false,
                    },
                  }));
                }}
                name="team"
                type="radio"
                className="radio"
                checked={!requestData.teamInformation?.haveTeam}
                value={!requestData.teamInformation?.haveTeam + ""}
              />
              No
            </label>
          </div>
        </div>
        {requestData.teamInformation?.haveTeam ? (
          <>
            <div className="formControl wrap">
              <label className="required ">Introduce your team (Max 15)</label>
              <div className="intro-box">
                <input
                  value={introducer.name}
                  type="text"
                  className="txt txt2"
                  placeholder="First Name"
                  onChange={(event) => {
                    setIntroducer((prevState) => ({
                      ...prevState,
                      name: event.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  value={introducer.position}
                  className="txt txt2"
                  placeholder="Position / Role"
                  onChange={(event) => {
                    setIntroducer((prevState) => ({
                      ...prevState,
                      position: event.target.value,
                    }));
                  }}
                />
                <input
                  type="text"
                  value={introducer.qualification}
                  className="txt txt2"
                  placeholder="Qualification"
                  onChange={(event) => {
                    setIntroducer((prevState) => ({
                      ...prevState,
                      qualification: event.target.value,
                    }));
                  }}
                />
                <button
                  type="button"
                  onClick={() => addIntroducer()}
                  className="NewIntroduce-btn"
                >
                  +
                </button>
              </div>

              {requestData?.teamInformation?.introduceTeam?.map(
                (introducer, i) => (
                  <>
                    <div className="intro-box">
                      <p
                        style={{ display: "flex", alignItems: "center" }}
                        className="txt txt2"
                      >
                        {introducer.name}
                      </p>

                      <p
                        style={{ display: "flex", alignItems: "center" }}
                        className="txt txt2"
                      >
                        {introducer.position}
                      </p>

                      <p
                        style={{ display: "flex", alignItems: "center" }}
                        className="txt txt2"
                      >
                        {introducer.qualification}
                      </p>
                      <button
                        style={{ background: "red", paddingBottom: "20px" }}
                        className="NewIntroduce-btn"
                        onClick={() => deleteIntroducer(i)}
                      >
                        -
                      </button>
                    </div>
                  </>
                )
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="formControl">
          <label className="label2 required">
            How are you and your team uniquely qualified to succeed?
          </label>
          <textarea
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                teamInformation: {
                  ...prevState.teamInformation,
                  teamUniquelyQualified: event.target.value,
                },
              }));
            }}
            value={requestData.teamInformation?.teamUniquelyQualified}
            className="txt"
          />
        </div>
        <div className="formControl">
          <label className=" required">
            How many developers are in your team?
          </label>
          <input
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                teamInformation: {
                  ...prevState.teamInformation,
                  numOfDevelopers: event.target.value,
                },
              }));
            }}
            type="number"
            className="txt"
            required
            value={requestData.teamInformation?.numOfDevelopers}
          />
        </div>
      </div>
    </div>
  );
};

export default Step4;
