import React, { useEffect } from "react";
import { useStatisticQuery } from "../../../store/api/requestApi";
import { useLocation } from "react-router-dom";

type Props = {};

const Statistic = (props: Props) => {
  const { data, refetch } = useStatisticQuery({});
  const location = useLocation();
  useEffect(() => {
    refetch();
  }, [location.pathname]);

  return (
    <div className="application-statistic">
      <div>
        <p>Total Submitted Applications: </p>
        <p>{data?.total}</p>
      </div>
      <div>
        <p>Applications under review: </p>
        <p>{data?.underReview}</p>
      </div>
      <div>
        <p>Accepted Applications: </p>
        <p>{data?.accepted}</p>
      </div>
    </div>
  );
};

export default Statistic;
