import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Image from "../Image/Image";
import { logout } from "../../store/logic/authSlice";
import {
  CategoryIcon,
  folderAddIcon,
  folderOpenIcon,
  settingIcon,
} from "../../assets/icons";
import LogoSidebar from "./images/capLogo.svg";
import logOutIcon from "../../assets/icons/logout.png";
import LogoSidebarMobile from "./images/capLogoMobile.svg";
import iconAssets from "./images/wallet.png";
import userIcon from "./images/role.svg";
import refrralSidbar from "./images/ref.svg";
import minterSidbar from "./images/minter.svg";
import cafeSidbar from "./images/cafe.svg";
import challenge from "./images/ranking.svg";
import sensifaiSidbar from "./images/sensifia.svg";
import sensifaiSidbarMobile from "./images/sensifiaMobile.svg";
import fidaoSidbar from "./images/fidao.svg";
import fidaoSidbarMobile from "./images/fidaoMobile.svg";
import ClaimToken from "./images/claimToken.svg";

import jwt_decode from "jwt-decode";
import {
  useCreateDraftFormMutation,
  useCreateTempFormMutation,
} from "../../store/api/requestApi";
import { useContext, useEffect, useState } from "react";

import { ERC20_ABI } from "@biconomy/modules";
import { ethers } from "ethers";
import Button from "../LoginButton/Button";
import {
  setProvider,
  setWalletMatic,
  setWalletUsdt,
} from "../../store/logic/walletSlice";
import { biconomyContext } from "../../utils/Context";
import { Toaster, toast } from "sonner";
import { providerPolygan } from "../../config/providerConfig";
import Tooltip from "../ToolTip/ToolTip";
import { EventType, routeEnum } from "../../types/enum.types";
import Spinner from "../Spinner/Spinner";
import BCI from "../BIC2024/BlockchainChallenge";
import { useGetChallengeTokenInfoQuery } from "../../store/api/challengeApi";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { particle, transak } = useContext(biconomyContext);
  const address: any = useSelector<any>((state) => state.wallet.address);

  const dispacth = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const myParam = searchParams.get("type");

  const pathName = window.location.pathname;
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  var decoded: any = accessToken ? jwt_decode(`${accessToken}`) : null;

  // const [createDraft, { data, isSuccess, isLoading }] =
  //   useCreateDraftFormMutation();
  const [createTempForm, { data, isSuccess, isLoading }] =
    useCreateTempFormMutation();
  const {
    data: challengeTokenData,
    refetch,
    isError,
    error: challengeError,
  } = useGetChallengeTokenInfoQuery(accessToken);

  const [balance, setBalance] = useState("");
  const [balanceUsdt, setBalanceUsdt] = useState("");
  const getBalance = async () => {
    try {
      if (address) {
        const balance = await providerPolygan.getBalance(address);
        setBalance(ethers.utils.formatEther(balance));
        console.log("ethers", ethers.utils.formatEther(balance));

        const usdtContract = new ethers.Contract(
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          ERC20_ABI,
          providerPolygan
        );

        let usdtBalance = await usdtContract.balanceOf(address);
        // debugger;

        setBalanceUsdt(ethers.utils.formatUnits(usdtBalance, 6));
        dispacth(setWalletMatic(ethers.utils.formatEther(balance)));
        dispacth(setWalletUsdt(ethers.utils.formatUnits(usdtBalance, 6)));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data) {
      // navigate(`/form/${data.id}`);
      navigate(`/app/${data?.id}/${routeEnum.steps}`);
    }
  }, [isSuccess]);
  useEffect(() => {
    getBalance();
  }, [address, pathName]);

  const handleLogOut = async () => {
    particle.auth.logout().then(() => {
      console.log("logout from particle");
      dispacth(logout());

      return navigate("/");
    });
    // particle.auth.logout();
  };
  const [showBIC, setShowBIC] = useState(true);

  return (
    <>
      {/* {showBIC && (
        <BCI
          setShowBIC={setShowBIC}
          data={challengeTokenData}
          refetch={refetch}
        />
      )} */}
      <div
        style={{ zIndex: "20", background: " #f8f9fa" }}
        className="panel-profile "
      >
        <Link
          to={"https://ip-captain.com"}
          style={{
            marginTop: "20px",
          }}
          className={"logo"}
        >
          <Image ImgSrc={LogoSidebar} />
        </Link>
        <Link
          style={{
            marginTop: "20px",
          }}
          to={"https://ip-captain.com"}
          className={"imgLogo"}
        >
          <Image ImgSrc={LogoSidebarMobile} />
        </Link>

        <ul className="list-items">
          <h5 className="list-items-header">Dashboard</h5>
          {/* {true && ( */}
          {!decoded?.roles?.includes("ipcaptain.auditor") && (
            <li
              onClick={() => {
                if (pathName.startsWith("/form")) {
                  return;
                }
                createTempForm({ accessToken, EventType: EventType.GLOBAL });
              }}
              className={pathName.startsWith("/form") && "active"}
            >
              <Image ImgSrc={folderAddIcon} />
              <Link to={""}>
                {isLoading ? (
                  <Spinner color="green" small={true} />
                ) : (
                  "New Application"
                )}
              </Link>
            </li>
          )}
          {/* {!decoded?.roles?.includes("ipcaptain.auditor") && (
          <li
            onClick={() => {
              if (pathName.startsWith("/form")) {
                return;
              }
              createTempForm({ accessToken, EventType: EventType.FUM_EVENT });
            }}
            className={`innovation-challenge ${
              pathName.startsWith("/form") && "active"
            }`}
          >
            <Image ImgSrc={challenge} />
            <Link to={""}>
              {isLoading ? "is Loading..." : "Innovation Challenge"}
            </Link>
          </li>
        )} */}
          {/* <li
            onClick={() => {
              refetch();
              if (!challengeTokenData) return;
              if (challengeTokenData?.result == "YES") {
                toast("You already claimed the token");
                return;
              }

              setShowBIC(true);
            }}
          >
            <img src={ClaimToken} style={{ filter: "unset" }} />
            <a className="getfreetoken" href="#">
              Get Free Token
            </a>
          </li> */}
          {!decoded?.roles?.includes("ipcaptain.auditor") && (
            <li
              onClick={() => navigate("/requests")}
              className={pathName.startsWith("/requests") && "active"}
            >
              <Image ImgSrc={folderOpenIcon} />
              <Link to={"/requests"}>My Applications</Link>
            </li>
          )}

          {decoded?.roles?.includes("ipcaptain.auditor") && (
            <li
              onClick={() => navigate("/requests")}
              className={pathName.startsWith("/requests") && "active"}
            >
              <Image ImgSrc={CategoryIcon} />
              <Link to={"/requests"}>Manage Applications</Link>
            </li>
          )}

          <li
            onClick={() => navigate("/assets")}
            className={pathName.startsWith("/assets") && "active"}
          >
            <Image ImgSrc={iconAssets} />
            <Link to={"/assets"}>My Wallet </Link>
          </li>

          <li
            onClick={() => navigate("/referral")}
            className={pathName.startsWith("/referral") && "active"}
          >
            <Image ImgSrc={refrralSidbar} />
            <Link to={"/referral"}>Referral </Link>
          </li>
          <li className="user-role" style={{ cursor: "unset" }}>
            <Tooltip
              text={
                decoded?.roles?.includes("ipcaptain.auditor")
                  ? "Auditor"
                  : "User"
              }
            >
              <img src={userIcon} alt="" style={{}} />
            </Tooltip>
            <Link to={"#"}>
              <span className="userAdi">
                Role:{" "}
                {decoded?.roles?.includes("ipcaptain.auditor")
                  ? "Auditor"
                  : "User"}
              </span>
            </Link>
          </li>
        </ul>
        <ul className="list-items">
          <h5 className="list-items-header">IP Ecosystem</h5>

          <li>
            <a
              style={{
                display: "block",
              }}
              target="_blank"
              href={`https://ipminter.com/`}
            >
              <Image ImgSrc={minterSidbar} />
            </a>
            <a target="_blank" href={`https://ipminter.com/`}>
              Mint your IP{" "}
            </a>
          </li>
          <li>
            <a
              style={{
                display: "block",
              }}
              target="_blank"
              href={"https://ip-cafe.com/"}
            >
              <Image ImgSrc={cafeSidbar} />
            </a>
            <a target="_blank" href={"https://ip-cafe.com/"}>
              Sell/Buy IP{" "}
            </a>
          </li>
        </ul>
        <ul className="list-items">
          <h5 className="list-items-header">Links</h5>
          {/* {true && ( */}

          <li>
            <a
              style={{
                display: "block",
              }}
              target="_blank"
              href="https://sensifia.com/"
            >
              <img
                style={{
                  filter: "unset",
                }}
                src={sensifaiSidbarMobile}
              />
            </a>

            <a target="_blank" href="https://sensifia.com/">
              Sensifia
            </a>
          </li>
          <li>
            <a
              style={{
                display: "block",
              }}
              target="_blank"
              href="https://fidao.org/"
            >
              <img
                style={{
                  filter: "unset",
                  width: "22px",
                  display: "block",
                }}
                src={fidaoSidbarMobile}
              />
            </a>

            <a target="_blank" href="https://fidao.org/">
              FIDAO
            </a>
          </li>
        </ul>

        <div
          onClick={() => {
            handleLogOut();
          }}
          className="button btn-div-logout"
        >
          <Image ImgSrc={logOutIcon} />
          Log out
        </div>
      </div>
    </>
  );
};

export default Sidebar;
