import React, { useEffect, useState } from "react";
import DeleteWarning from "../images/delete.svg";
import { useDeleteFormUserMutation } from "../../../store/api/requestApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
type Props = {
  requestId: string;
  setDeleteModal: Function;
  //   formStatus:string;
  refetch: Function;
  setModalState: Function;
};

const DeleteAction = ({
  requestId,
  setDeleteModal,
  refetch,
  setModalState,
}: Props) => {
  const [deleteForm, { isLoading, isError, isSuccess }] =
    useDeleteFormUserMutation();
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setDeleteModal((state) => ({ ...state, visible: false }));
      setModalState((state) => ({ ...state, show: false }));
      refetch();
    }
  }, [isSuccess]);
  return (
    <div className=" delete-action-contianer">
      <div className="delete-action">
        <img src={DeleteWarning} alt="" />
        <h4>Are you sure you want to delete this request?</h4>
        {isError ? "somthing went wrong" : ""}
        <div className="delete-action-btns">
          {isLoading ? (
            "Loading..."
          ) : (
            <>
              <button
                onClick={() => {
                  deleteForm({ accessToken, requestId });
                }}
                className="btn-yes"
              >
                YES
              </button>
              <button
                onClick={() => {
                  setDeleteModal((state) => ({ ...state, visible: false }));
                }}
                className="btn-no"
              >
                NO
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAction;
