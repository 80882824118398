import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import "./requests.css";

import {
  editIcon as editAction,
  iconHead,
  trashAction,
  bookIcon as bookAction,
} from "../../assets/icons";
import jwt_decode from "jwt-decode";
import Sidebar from "../../components/Sidebar";
import Image from "../../components/Image/Image";
import { useSelector } from "react-redux";
import { useMyRequestQuery } from "../../store/api/requestApi";
import SingleRequest from "./components/SingleRequest";
import Status from "./components/Status";
import Toast from "../../components/Toast/Toast";
import Loading from "../../components/Loading/Loading.animation";
import ManageRequests from "../manage-requests";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import { counterTransaction } from "../../transactions/Counter";
import Wallet from "../../components/Wallet";

interface ss {
  wallet: {
    wallet: BiconomySmartAccountV2;
  };
}
const Requests = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/requests");
  }, []);

  return <></>;
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [searchParams, setSearchParams] = useSearchParams({
    pageNo: "1",
  });
  var decoded: any = jwt_decode(`${accessToken}`);
  const location = useLocation();
  console.log("🚀 ~ file: index.tsx:18 ~ Requests ~ accessToken:", accessToken);

  const { data, isError, isFetching, refetch } = useMyRequestQuery(
    { accessToken, pageNo: searchParams.get("pageNo") },
    {
      skip: Boolean(!accessToken),
    }
  );
  console.log("🚀 ~ file: index.tsx:24 ~ Requests ~ data:", data);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const closeToast = () => {
    setShowToast(false);
  };
  const showToastMessage = (message) => {
    setMessage(message);
    setShowToast(true);
  };

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (decoded?.roles?.includes("ipcaptain.auditor")) {
    return <ManageRequests />;
  }

  const handlePagination = (action) => {
    console.log(action);

    let currentPageNo = searchParams.get("pageNo");
    const { pageNo, pageSize, totalItemsCount } = data?.response;
    let totalPage = Math.ceil(totalItemsCount / pageSize) + "";
    switch (action) {
      case "next":
        if (currentPageNo == totalPage) return;
        setSearchParams({
          pageNo: String(1 + parseInt(currentPageNo)),
        });
        break;
      case "prev":
        if (currentPageNo == "1") return;
        setSearchParams({
          pageNo: String(parseInt(currentPageNo) - 1),
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="profile-page">
      <Sidebar />
      <Wallet />
      {showToast && <Toast message={message} type="warning" />}
      {isFetching ? <Loading /> : <></>}

      <div className="requset-container" style={{ marginTop: "80px" }}>
        <div className="head-container">
          <h2 className="head-name">
            <Image ImgSrc={iconHead} />
            My Applications
          </h2>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <div className="requsets-box user-req" style={{ display: "table" }}>
            <div className="head-req">
              <span>ID</span>
              <span>Startup or Idea Name</span>
              <span>Industry</span>
              <span>Status</span>
              <span>Actions</span>
            </div>
            {data?.data?.map((request) => {
              return <SingleRequest request={request} refetch={refetch} />;
            })}
          </div>
        </div>
        {data?.response?.totalItemsCount > 10 && (
          <div className="pagination-container">
            <button
              onClick={() => {
                // setSearchParams({ pageNo: "2" });
                handlePagination("prev");
              }}
            >
              &#60;
            </button>
            <p>{searchParams.get("pageNo")}</p>
            <button
              onClick={() => {
                // setSearchParams({ pageNo: "2" });
                handlePagination("next");
              }}
            >
              &#62;
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Requests;
