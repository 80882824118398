import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// import logo from "./images/ipminter.png";
import logo from "./images/logoCap.png";
import dotLeft from "./images/DotLeft.png";
import dotRight from "./images/DotRight.png";

import { useNavigate, useParams } from "react-router-dom";
import "./cert.style.css";
import QR from "./sub-component/Qrcode";

import { DateFormatCert } from "../../utils/time";
import { useSingleRequestQuery } from "../../store/api/requestApi";
import { useSelector } from "react-redux";

type Props = {};

const Certificate = (props: Props) => {
  const { id: requestid } = useParams();

  const [ipminterUrl, setIpminterUrl] = useState<any>();
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  const [polygonscan, setPolygonscan] = useState<any>();
  const { data } = useSingleRequestQuery(
    { accessToken, requestid },
    {
      skip: !Boolean(accessToken),
    }
  );
  console.log(
    "🚀 ~ file: Cetificate.tsx:29 ~ useSingleRequestQuery ~ data:",
    data
  );
  const navigate = useNavigate();

  const getPrimaryFounder = () => {
    return data?.basicInformation?.founders.map((f) => f.firstName).join(", ");

    // if (data?.basicInformation?.founders.length == 1) {
    //   return data?.basicInformation?.founders[0].firstName;
    // } else {
    //   let temp = data?.basicInformation?.founders?.find(
    //     (f) => f.isPrimaryContact
    //   );

    //   return temp?.firstName;
    // }
  };
  return (
    <>
      <button
        className=" remove-btn-pdf"
        style={{}}
        onClick={() => exportPDF()}
      >
        Download as PDF
      </button>

      <div className="page" id="certificate-page">
        <img src={dotLeft} alt="" className="dot-left" />
        <img src={dotRight} alt="" className="dot-right" />
        <div className="gradient-border">
          <img
            src={logo}
            //   style="width: 400px; height: 100px"
            // style={{
            //   width: "400px",
            //   height: "100px",
            // }}
            alt=""
          />
        </div>
        <h1 className="header-cert">Certificate</h1>
        <h1
          className="font-roboto"
          //    style="color: #f0f0f0; font-weight: 400"
          style={{
            color: "#fff",
            fontWeight: "300",
            textAlign: "center",
            lineHeight: "1.3",
            fontSize: "28px",
          }}
        >
          of Application Registration <br /> on Blockchain
        </h1>
        <div
          className="font-roboto"
          //   style="text-align: center"
          style={{
            textAlign: "center",
          }}
        >
          <h1
            style={{
              color: "#fff",
              fontSize: "22px",
              marginBottom: "25px",
            }}
            //  style="color: white"
          >
            This is to certify that the startup titled
          </h1>
          <p
            style={{
              color: "#fff",
              fontSize: "26px",
              fontWeight: "900",
            }}
            //  style="color: #fff; font-size: 26px; font-weight: 400"
          >
            {data?.basicInformation?.startupName}
          </p>
        </div>

        <div className="font-roboto" style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#fff",
              fontSize: "22px",
              marginBottom: "20px",
            }}
          >
            with below Founder / Owner
          </h1>
          <p
            style={{ color: "#fff", fontSize: "26px", fontWeight: "400" }}
            //  style="color: #fff; font-size: 26px; font-weight: 400">
          >
            {getPrimaryFounder()}
          </p>
          <p
            style={{
              color: "#fff",
              fontSize: "26px",
              fontWeight: "400",
              marginTop: "20px",
            }}
            //  style="color: #fff; font-size: 26px; font-weight: 400">
          >
            {data?.email}
          </p>
          <p style={{ color: "#fff", fontSize: "24px", marginTop: "20px" }}>
            is successfully recorded on blockchain on{" "}
            {DateFormatCert(data?.createdAt)} (UTC) by IP-Captain DApp. <br />{" "}
            Scan the QR code below for details of the application.
          </p>
        </div>

        <div
          className="qrcode"
          // style="display: flex; gap: 40px; margin-top: 100px"
          style={{
            display: "flex",
            gap: "70px",
            marginTop: "100px",
          }}
        >
          {/* <img style={{ width: "100px" }} src={qrcode} alt="" /> */}

          <QR
            title={"polygonscan"}
            url={`https://polygonscan.com/tx/${data?.chainData?.transactionHash}`}
          />
          {/* <img style={{ width: "100px" }} src={qrcode} alt="" /> */}
        </div>
      </div>
    </>
  );
};

const exportPDF = () => {
  const input = document.getElementById("certificate-page");
  const clonedInput: any = input.cloneNode(true); // Clone the element

  // Remove elements from the cloned version
  const buttons = clonedInput.getElementsByClassName("remove-btn-pdf");
  Array.from(buttons).forEach((button: any) => button.remove());

  // Temporarily append the cloned element to the DOM (off-screen)
  clonedInput.style.position = "fixed";
  clonedInput.style.top = "-10000px";
  document.body.appendChild(clonedInput);

  // Render the canvas and generate the PDF
  html2canvas(clonedInput, { logging: true, useCORS: true })
    .then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("portrait", "cm");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the ratio of the image dimensions to the PDF dimensions
      const ratio = Math.min(
        pdfWidth / imgProps.width,
        pdfHeight / imgProps.height
      );

      // Adjust ratio to make image a bit smaller, e.g., 80% of maximum size
      const adjustedRatio = ratio * 1;

      // Calculate the dimensions of the image after scaling
      const imgWidth = imgProps.width * adjustedRatio;
      const imgHeight = imgProps.height * adjustedRatio;
      //   const imgWidth = imgProps.width;
      //   const imgHeight = imgProps.height;

      // Calculate positions to center the image
      const x = (pdfWidth - imgWidth) / 2;
      const y = (pdfHeight - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
      console.log(
        "🚀 ~ file: Cetificate.tsx:245 ~ .then ~ imgWidth, imgHeight:",
        imgWidth,
        imgHeight
      );

      pdf.save("IPCaptainCertificate.pdf");
    })
    .finally(() => {
      // Remove the cloned element from the DOM
      document.body.removeChild(clonedInput);
    });
};

export default Certificate;
