export const checkIfBasicInfoIsComplete = (basicInfo) => {
  return (
    basicInfo.abstract &&
    basicInfo.startupName &&
    basicInfo.raisedCapital &&
    basicInfo.requestedInvestment &&
    basicInfo.trl &&
    basicInfo.founders?.length > 0
  );
};
export const checkIfProductInfoIsComplete = (productInfo) => {
  return (
    productInfo.keyFeatures && productInfo.problems && productInfo.description
  );
};
export const checkIfMarketInfoIsComplete = (marketInfo) => {
  return (
    marketInfo.competitiveAdvantages &&
    marketInfo.topCompetitors &&
    marketInfo.businessModel &&
    marketInfo.estimatedMarketSize &&
    marketInfo.targetMarket
  );
};
export const checkIfBusinessInfoIsComplete = (businessInfo) => {
  return (
    businessInfo.distributionChannels &&
    businessInfo.customerAcquisitionChannels &&
    businessInfo.pricingStrategy &&
    businessInfo.revenueModel
  );
};
export const checkIfFinanceInfoIsComplete = (financeInfo) => {
  return (
    financeInfo.nextYearProjectedRevenue &&
    financeInfo.currentYearProjectedRevenue &&
    financeInfo.totalRevenue &&
    financeInfo.averageAnnualTurnover &&
    financeInfo.recentAnnualTurnover
  );
};
export const checkIfTractionInfoIsComplete = (tractionInfo) => {
  return (
    tractionInfo.userFeedback &&
    tractionInfo.currentRevenueStream &&
    tractionInfo.currentUsers
    //  &&
    // tractionInfo.turnoverRecentFiscal
  );
};
export const checkIfRiskInfoIsComplete = (riskInfo) => {
  return riskInfo.mitigatePlan && riskInfo.mainChallenges;
};
export const checkIfOtherInfoIsComplete = (otherInfo) => {
  return (
    otherInfo.extra &&
    otherInfo.specificMentorship &&
    otherInfo.envisionAccelerator &&
    otherInfo.specificGoals
    // &&
    // otherInfo.businessPlanFileId
  );
};
// export const checkIfBasicInfoIsComplete = (basicInfo) => {
//   return (
//     basicInfo.abstract &&
//     basicInfo.startupName &&
//     basicInfo.raisedCapital &&
//     basicInfo.requestedInvestment &&
//     basicInfo.trl &&
//     basicInfo.founders?.length > 0
//   );
// };

export function hasValue(obj) {
  for (const key in obj) {
    if (obj[key] && obj[key].length != 0) {
      return true; // At least one field has a value
    }
  }
  return false; // No fields have a value
}

export function isFormHasValue(objs) {
  let temp;
  objs.forEach((element) => {
    temp = hasValue(element) || temp;
  });
  return temp; // No fields have a value
}
