import React, { useContext } from "react";
import imgNft from "../../../assets/images/imgNft.png";
import Nft from "./Nft";
import { biconomyContext } from "../../../utils/Context";
import { useGetAllTokenQuery } from "../../../store/api/tokenApi";
import Spinner from "../../../components/Spinner/Spinner";
type Props = {};

const NftsList = (props: Props) => {
  const { address } = useContext(biconomyContext);
  const { data, isLoading } = useGetAllTokenQuery({
    network: "POLYGON",
    address,
  });
  console.log("🚀 ~ file: NftsList.tsx:11 ~ NftsList ~ data:", data);
  return (
    <div className="wrapper">
      <div className="assets-box" style={{ display: "table", width: "100%" }}>
        <div className="head-info">
          <span>NFT’s</span>
          {/* <span>Total Qty</span>
          <span>Total Value</span> */}
        </div>
        {data?.items.map((token) => (
          <Nft token={token} />
        ))}
      </div>
    </div>
  );
};

export default NftsList;
