import doneImage from "../../../assets/images/done.png";
import axios from "axios";
import { useEffect, useState } from "react";

import { RouteEnum } from "../../../types/enum.types";
import { useNavigate, useSearchParams } from "react-router-dom";

const AuthenticationResult = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState(null);
  useEffect(() => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    console.log(email, code);

    axios
      .post(
        `https://ip-api.sensifia.vc/user/email/verification?email=${email}&code=${code}`
      )
      .then((res) => {
        console.log(res);
        const result = res as any;
        localStorage.setItem("token", result.data.accessToken);
        localStorage.setItem("refreshToken", result.data.refreshToken);
        localStorage.setItem("userId", result.data.userId);
        navigate(RouteEnum.explore);
      })
      .catch((err) => {
        console.log("this is err", err.response.data.message);
        setError(err?.response?.data?.message);
      });
  }, []);
  console.log("error", error);

  return (
    <div
      className=" "
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100vw",
      }}
    >
      <div
        className="pendingforactivation"
        style={{ width: "90%", maxWidth: 576 }}
      >
        <div className=" mb-2">
          <img src={doneImage} width={160} />
          <span className="f-25 fw-b">Thanks for the Register!</span>
        </div>
        <div className="flex justify-center items-center w-full">
          {error ? (
            <p style={{ color: "red" }}>{error}</p>
          ) : (
            <p className="gray f-14 mb-5 description">
              You will be redirected to the dashboard shortly
            </p>
          )}
          {/* <Button label='Go Dashboard' className='submit-btn mx-auto mb-4 w-max' onClick={()=>router.push(RouteEnum.dashboard)} /> */}
        </div>
      </div>
    </div>
  );
};

export default AuthenticationResult;
