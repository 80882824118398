import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ApiEnum } from "../../../../types/enum.types";
import { useSelector } from "react-redux";
import axios from "axios";
import PitchDeck from "./sub-components/PicthDeck";
import iconFill from "../../../../assets/icons/check-load.png";
import Image from "../../../../components/Image/Image";
import { toast } from "sonner";
import IntroVideo from "./sub-components/IntroVideo";

const Step3 = ({
  pitchDeck,
  setPitchDeck,
  pitchDeckURL,
  setPitchDeckURL,
  introduction,
  setIntroduction,
  introductionURL,
  setIntroductionURL,
  requestData,
  setRequestData,
}) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [progress, setProgress] = useState(0);
  const cancelToken = axios.CancelToken;
  const sourceCancel = cancelToken.source();

  const onUploadProfile = async (selectedFile: any, input: any, type: any) => {
    try {
      setProgress(0);
      const formdata: any = new FormData();
      formdata.append("file", selectedFile);
      var config = {
        method: "post",
        url: ApiEnum.STORAGE_API + `/files/upload`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-File-Access": 0,
        },
        data: formdata,
      };
      const response = await axios({
        ...config,
        cancelToken: sourceCancel.token,
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(uploadPercentage);
        },
      });
      // setRequestData(response);
      if (response?.status == 200) {
        if (input === "Introduction") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              videoFile: {
                ...prevState.businessInformation.videoFile,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
        if (input === "PitchDeck") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              pitchDeck: {
                ...prevState.businessInformation.pitchDeck,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
      }
      toast.success("file uploaded successfully");
      console.log("response onUploadProfile:", response);
    } catch (error) {
      console.error(error);
      toast.error("Error in Uploading file");
    }
  };
  return (
    <div className="wrapper">
      <h1 className="title">Step 2: Business Information</h1>
      <ul className="loading-form">
        <li className="fill">
          <Image ImgSrc={iconFill} />
        </li>
        <li className="active">2</li>
        <li>3</li>
      </ul>
      <div className="form">
        <div className="formControl">
          <label className="label2 required">
            Explain what your business does in 1 paragraph
          </label>
          <textarea
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  explainBusiness: event.target.value,
                },
              }));
            }}
            value={requestData?.businessInformation?.explainBusiness}
            className="txt"
            placeholder=""
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            Explain Technology of your idea
          </label>
          <textarea
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  explainTechnology: event.target.value,
                },
              }));
            }}
            value={requestData?.businessInformation?.explainTechnology}
            className="txt"
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            What's your target market(s)?
          </label>
          <textarea
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  targetMarket: event.target.value,
                },
              }));
            }}
            value={requestData?.businessInformation?.targetMarket}
            className="txt"
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            What's your estimated market size?
          </label>
          <div className="box">
            <input
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  businessInformation: {
                    ...prevState.businessInformation,
                    marketSize: event.target.value,
                  },
                }));
              }}
              value={requestData?.businessInformation?.marketSize}
              type="number"
              className="txt"
              placeholder="e.g., 23"
              required
            />
            <span>Please specify US Dollars</span>
          </div>
        </div>
        <div className="formControl">
          <label className="label2 required">Specify your market </label>
          <div className="req-box">
            <label>
              <input
                name="marketType"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      marketType: "BMT_B2B",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.marketType === "BMT_B2B"
                }
                type="radio"
                className="radio"
              />
              B2B
            </label>
            <label>
              <input
                name="marketType"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      marketType: "BMT_B2C",
                    },
                  }));
                }}
                type="radio"
                className="radio"
                checked={
                  requestData.businessInformation?.marketType === "BMT_B2C"
                }
              />
              B2C
            </label>
            <label>
              <input
                name="marketType"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      marketType: "BMT_B2B2C",
                    },
                  }));
                }}
                type="radio"
                className="radio"
                checked={
                  requestData.businessInformation?.marketType === "BMT_B2B2C"
                }
              />
              B2B2C
            </label>
            <label>
              <input
                name="marketType"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      marketType: "BMT_B2G",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.marketType === "BMT_B2G"
                }
                type="radio"
                className="radio"
              />
              B2G
            </label>
          </div>
        </div>
        <div className="formControl">
          <label className="label2 required">What's your business model?</label>
          <div className="box2">
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_RETAILER",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_RETAILER"
                }
                type="radio"
                className="radio"
              />
              Retailer model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_SUBSCRIPTION",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_SUBSCRIPTION"
                }
                type="radio"
                className="radio"
              />
              Subscription model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_PRODUCT_AS_SERVICE",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_PRODUCT_AS_SERVICE"
                }
                type="radio"
                className="radio"
              />
              Product-as-a-service model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_LEASING",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_LEASING"
                }
                type="radio"
                className="radio"
              />
              Leasing model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_FREEMIUM",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_FREEMIUM"
                }
                type="radio"
                className="radio"
              />
              Freemium model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_ADVERTISING",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel ===
                  "BM_ADVERTISING"
                }
                type="radio"
                className="radio"
              />
              Advertising model
            </label>
            <label className="businessModel-QA">
              <input
                name="businessModel"
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    businessInformation: {
                      ...prevState.businessInformation,
                      businessModel: "BM_OTHER",
                    },
                  }));
                }}
                checked={
                  requestData.businessInformation?.businessModel === "BM_OTHER"
                }
                type="radio"
                className="radio"
              />
              Other
            </label>
          </div>
        </div>
        {requestData?.businessInformation?.businessModel === "BM_OTHER" && (
          <div className="formControl">
            <label className="label2 required">Other</label>
            <textarea
              onChange={(event) => {
                setRequestData((prevState) => ({
                  ...prevState,
                  businessInformation: {
                    ...prevState.businessInformation,
                    otherModel: event.target.value,
                  },
                }));
              }}
              value={requestData.businessInformation?.otherModel}
              className="txt"
              placeholder=""
            />
          </div>
        )}
        <div className="formControl">
          <label className="label2 required">
            What's your revenue streams?
          </label>
          <textarea
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  revenueStreams: event.target.value,
                },
              }));
            }}
            value={requestData.businessInformation?.revenueStreams}
            className="txt"
            placeholder=""
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            How will blockchain help you address the market gap you identified?
          </label>
          <textarea
            className="txt"
            placeholder="If you're unsure,type 'unsure'"
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  blockchainMarketApp: event.target.value,
                },
              }));
            }}
            value={requestData.businessInformation?.blockchainMarketApp}
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            Who are your top competitors?
          </label>
          <textarea
            className="txt"
            placeholder="If you're unsure,type 'unsure'"
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  competitors: event.target.value,
                },
              }));
            }}
            value={requestData.businessInformation?.competitors}
          />
        </div>
        <div className="formControl">
          <label className="label2 required">
            What are your ambitions for where you'd like your startup to be in 3
            years time?
          </label>
          <textarea
            className="txt"
            placeholder="If you're unsure,type 'unsure'"
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                businessInformation: {
                  ...prevState.businessInformation,
                  ambitions: event.target.value,
                },
              }));
            }}
            value={requestData.businessInformation?.ambitions}
          />
        </div>
        <PitchDeck
          requestData={requestData}
          pitchDeck={pitchDeck}
          pitchDeckURL={pitchDeckURL}
          setPitchDeck={setPitchDeck}
          setPitchDeckURL={setPitchDeckURL}
          setRequestData={setRequestData}
        />
        <IntroVideo
          introduction={introduction}
          introductionURL={introductionURL}
          requestData={requestData}
          setIntroduction={setIntroduction}
          setIntroductionURL={setIntroductionURL}
          setRequestData={setRequestData}
        />
      </div>
    </div>
  );
};

export default Step3;
