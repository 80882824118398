import React, { useState } from "react";
import "./tooltip.css";
type Props = {};

const ToolTibHover = (props) => {
  const [state, setState] = useState({ showTooltip: false });
  const handleMouseOver = () => {
    setState({ showTooltip: true });
  };

  const handleMouseOut = () => {
    setState({ showTooltip: false });
  };

  return (
    <p
      className="tooltip-container"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <>{props.children}</>
      {state.showTooltip && (
        <span className="tooltip-text">{props.tooltipText}</span>
      )}
    </p>
  );
};

export default ToolTibHover;
