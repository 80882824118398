import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

interface ITokens {
  network: "POLYGON";
  address: string;
}
export const tokenApi = createApi({
  reducerPath: "tokens",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.rarible.org/v0.1/" }),
  endpoints: (builder) => ({
    getAllToken: builder.query({
      query: ({ network, address }: ITokens) => {
        return {
          url: `items/byOwner/?blockchains=${network}&owner=ETHEREUM:${address}&size=500`,
          headers: {
            "X-API-KEY": "a84bd16f-8e4f-4bad-a9ee-fa6a87053e81",
          },
        };
      },
    }),
  }),
});

export const { useGetAllTokenQuery } = tokenApi;
