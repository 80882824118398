import axios from "axios";
import { Contracts } from "../../types/enum.types";

export const getAllDiscountToken = async (
  network: "POLYGON",
  address: string
) => {
  let url = `https://api.rarible.org/v0.1/items/byOwner/?blockchains=${network}&owner=ETHEREUM:${address}&size=500`;
  const targetContract = `POLYGON:${Contracts.DISCOUNT.toLowerCase()}`;
  try {
    let data = await axios.get(url, {
      headers: {
        "X-API-KEY": "a84bd16f-8e4f-4bad-a9ee-fa6a87053e81",
      },
    });
    let tokens = data?.data?.items?.filter(
      (item) => item.contract === targetContract
    );

    return {
      error: null,
      tokens,
    };
  } catch (error) {
    console.log(error);
    return {
      error: error.message,
      data: null,
    };
  }
};
