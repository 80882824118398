import { useTransition } from 'react';

const TabButton = ({ children, isActive, onClick }) => {
    const [isPending, startTransition] = useTransition()
   
    if (isActive) {
      return <span className="button active">{children}</span>
    }
    // if(isPending){
    //   return <span className="button pending">{children}</span>;
    // }
    return (
      <button onClick={() => {
        startTransition(() => {
          onClick();
        });
      }} className="button">
        {children}
      </button>
    );
  }
  
  export default TabButton