import React, { useContext, useEffect } from "react";
import UploadOtherInfo from "./sub-components/UploadOtherInfo";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const OtherInfo = (props: Props) => {
  const { otherInformation, setApplicationData, accessToken, id, appStatus } =
    useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(otherInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          otherInformation: { ...requestDataDebounce },
        },
      });
    if (hasValue(otherInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);

  return (
    <div className="form-application">
      <h1 className="form-application-header">Other Information</h1>

      <UploadOtherInfo
        setApplicationData={setApplicationData}
        otherInformation={otherInformation}
      />

      <div className="form-controller text">
        <label className="required">
          What specific goals do you hope to achieve by participating in this
          accelerator program?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              otherInformation: {
                ...otherInformation,
                specificGoals: event.target.value,
              },
            }));
          }}
          value={otherInformation?.specificGoals}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          How do you envision the accelerator assisting in your startup’s
          growth?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              otherInformation: {
                ...otherInformation,
                envisionAccelerator: event.target.value,
              },
            }));
          }}
          value={otherInformation?.envisionAccelerator}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          Any specific mentorship or resources you are seeking?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              otherInformation: {
                ...otherInformation,
                specificMentorship: event.target.value,
              },
            }));
          }}
          value={otherInformation?.specificMentorship}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          Anything else you would like to share or highlight about your startup?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              otherInformation: {
                ...otherInformation,
                extra: event.target.value,
              },
            }));
          }}
          value={otherInformation?.extra}
        ></textarea>
      </div>
    </div>
  );
};

export default OtherInfo;
