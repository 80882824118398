import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

interface ITokens {
  network: "POLYGON";
  address: string;
}
export const walletApi = createApi({
  reducerPath: "activity",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.polygonscan.com" }),
  endpoints: (builder) => ({
    getAllWalletActivity: builder.query({
      query: (walletAddress) => {
        return {
          url: `api?module=account&action=txlist&address=${walletAddress}&apikey=CS5GVFM9GK2137JD6ZNYJVV6ANVNP8BEAZ`,
        };
      },
    }),
  }),
});

export const { useGetAllWalletActivityQuery } = walletApi;
