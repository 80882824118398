import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { requestApi } from "./api/requestApi";
import authReducer from "./logic/authSlice";
import walletReducer from "./logic/walletSlice";
import { ipApi } from "./api/ipApi";
import { accountApi } from "./api/acoountApi";
import { tokenApi } from "./api/tokenApi";
import { walletApi } from "./api/walletApi";
import { cryptoApi } from "./api/cryptoApi";
import { challengeApi } from "./api/challengeApi";

const store = configureStore({
  reducer: {
    auth: authReducer,
    wallet: walletReducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [ipApi.reducerPath]: ipApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [cryptoApi.reducerPath]: cryptoApi.reducer,
    [challengeApi.reducerPath]: challengeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      requestApi.middleware,
      ipApi.middleware,
      accountApi.middleware,
      tokenApi.middleware,
      walletApi.middleware,
      cryptoApi.middleware,
      challengeApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
