import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../store/logic/authSlice";
import { Outlet, Navigate } from "react-router-dom";
import { RouteEnum } from "../types/enum.types";
import { biconomyContext } from "./Context";
import { useContext } from "react";
import { ParticleAuthModule } from "@biconomy/particle-auth";
export interface GuardedRouteProps {
  isRouteAccessible?: boolean;
  redirectRoute?: string;
  isAuthPage?: boolean;
}

export const GuardedRoute = ({
  isRouteAccessible = true,
  redirectRoute = "/auth/login",
}: GuardedRouteProps): JSX.Element => {
  const { particle } = useContext<any>(biconomyContext);

  const dispacth = useDispatch();
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  if (!particle.auth.isLogin()) {
    dispacth(logout());
    return <Navigate to={redirectRoute} replace />;
  }

  if (!accessToken || accessToken == "undefined") {
    particle.auth.logout().then(() => {
      console.log("logout");
    });
    return <Navigate to={redirectRoute} replace />;
  }
  let decoded_token: any = jwt_decode(
    String(accessToken) ||
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
  );

  let currentTime = new Date(Date.now());
  let currentUtcTime = currentTime.toISOString();

  let expTime = new Date(decoded_token?.exp * 1000);
  let expUtcTime = expTime.toISOString();

  if (currentUtcTime >= expUtcTime) {
    dispacth(logout());
    return <Navigate to={redirectRoute} replace />;
  }

  return isRouteAccessible ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};

export const GuardedLogin = ({
  isRouteAccessible = true,
  redirectRoute = RouteEnum.request,
}: GuardedRouteProps): JSX.Element => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  console.log("🚀 ~ file: routeGuard.tsx:43 ~ accessToken:", accessToken);

  if (accessToken) {
    return <Navigate to={redirectRoute} replace />;
  }

  return isRouteAccessible ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};
