import { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleIconClick = (event) => {
    const iconPosition = event.target.getBoundingClientRect();
    const newPosition = {
      top: 0,
      left: iconPosition.left + window.scrollX + iconPosition.width / 2,
    };
    setTooltipPosition(newPosition);
    setShowTooltip(!showTooltip);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div onClick={handleIconClick}>{children}</div>
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",
            padding: "5px",
            borderRadius: "5px",
            zIndex: 999,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
