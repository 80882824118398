import React from "react";
import "./spinner.style.css";
type Props = {
  color?: string;
  small?: boolean;
};

const Spinner = ({ color, small = false }: Props) => {
  return (
    <div className={`lds-ring ${color} ${small && "lds-small"}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
