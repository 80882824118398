import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image/Image";
import picEnd from "../../images/picLetter.png";
import { endStepToCheckEmail } from "../../../../assets/images";


const EndStep = ({requestData, setRequestData}) => {
  return (
    <div className="wrapper">
      <div className="endStep-form">
        <Image ImgSrc={endStepToCheckEmail} />
        <h4>Thank you!</h4>
        <p>Your submission has been received.</p>
        <Link to={"/requests"} className="formCta-next"  >
          Back to List
        </Link>
      </div>
    </div>
  );
};

export default EndStep;
