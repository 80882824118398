import React, { useContext, useEffect, useState } from "react";
import { biconomyContext } from "../../../utils/Context";
import { providerPolygan } from "../../../config/providerConfig";
import { ethers } from "ethers";
import { ERC20_ABI } from "@biconomy/modules";
import Transfer from "./transferAsset/Transfer";
import { transfer } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { setWalletUsdt } from "../../../store/logic/walletSlice";

type Props = {
  chain: string;
  token: any;
};

const Crypto = ({ chain, token, changeUsdtBlance, setChangeUsdtBlance }) => {
  const { address } = useContext(biconomyContext);
  const [balance, setBalance] = useState("");
  const dispatch = useDispatch();
  const [showTransfer, setShowTransfer] = useState({
    show: false,
  });
  const getBalance = async () => {
    try {
      if (address) {
        if (token.name == "MATIC") {
          const balance = await providerPolygan.getBalance(address);
          setBalance(ethers.utils.formatEther(balance));
          return;
          //   console.log("ethers", ethers.utils.formatEther(balance));
        }

        const usdtContract = new ethers.Contract(
          token.address,
          ERC20_ABI,
          providerPolygan
        );

        let usdtBalance = await usdtContract.balanceOf(address);

        setBalance(ethers.utils.formatUnits(usdtBalance, token.decimals));
        if (token.address === "0xc2132d05d31c914a87c6611c10748aeb04b58e8f") {
          dispatch(
            setWalletUsdt(ethers.utils.formatUnits(usdtBalance, token.decimals))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    if (
      changeUsdtBlance &&
      token.address === "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
    ) {
      getBalance();
    }
  }, [changeUsdtBlance]);

  if (parseFloat(balance) > 0) {
    return (
      <div className="body-assets">
        {showTransfer.show && (
          <Transfer
            setChangeUsdtBlance={setChangeUsdtBlance}
            onClose={(val) => {
              setShowTransfer((state) => ({ ...state, show: val }));
            }}
            showModal={showTransfer.show}
            balance={balance}
            token={token}
            getBalance={getBalance}
          />
        )}
        <div
          className="items"
          style={{
            paddingLeft: "10px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img src={token.logoURI} alt="" />
          <span>{token.name}</span>
        </div>
        <div className="items">
          <span>
            {balance ? Math.floor(parseFloat(balance) * 100000) / 100000 : 0}
          </span>
        </div>
        <div className="items">
          <img
            onClick={() => {
              setShowTransfer((state) => ({ ...state, show: true }));
              setChangeUsdtBlance(false);
            }}
            style={{
              width: "25px",
              cursor: "pointer",
            }}
            src={transfer}
            alt=""
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Crypto;
