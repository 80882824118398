import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

export const requestApi = createApi({
  reducerPath: "request",
  baseQuery: fetchBaseQuery({ baseUrl: ApiEnum.CAPTAIN_API }),
  endpoints: (builder) => ({
    myRequest: builder.query({
      query: ({ accessToken, pageNo, isAuditore }) => {
        return {
          url: isAuditore
            ? `/auditor/captain/forms/list?paginated.pageNo=${pageNo}&paginated.pageSize=5`
            : `/captain/forms/me?pageNo=${pageNo}&pageSize=5`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    singleRequest: builder.query({
      query: ({ accessToken, requestid, isAuditor = false }) => {
        return {
          url: `${
            isAuditor
              ? `/auditor/captain/forms/${requestid}`
              : `/captain/forms/${requestid}`
          }`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    statistic: builder.query({
      query: () => {
        return {
          url: "/counts",
        };
      },
    }),

    auditorGetRequestList: builder.query({
      query: ({ accessToken, pageNo = 1 }) => {
        return {
          url: `/auditor/captain/forms/list?paginated.pageNo=${pageNo}&paginated.pageSize=10`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getPdfId: builder.query({
      query: ({ accessToken, id }) => {
        return {
          url: `/captain/forms/${id}/export:pdf`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),

    changeStatusToUnderReview: builder.mutation({
      query: ({ accessToken, requestid }) => {
        return {
          url: `/auditor/captain/forms/review/${requestid}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    changeStatusForm: builder.mutation({
      query: ({ accessToken, requestid, status, reason }) => {
        return {
          url: `/auditor/captain/forms/${requestid}`,
          method: "PUT",
          body: {
            status: status,
            statusReason: reason,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteFormUser: builder.mutation({
      query: ({ accessToken, requestId }) => {
        return {
          url: `/captain/forms/${requestId}/cancel`,
          method: "PUT",

          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateFormRequest: builder.mutation({
      query: ({ accessToken, requestId, requestData }) => {
        return {
          url: `/captain/forms/${requestId}`,
          method: "PUT",
          body: requestData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createDraftForm: builder.mutation({
      query: ({ accessToken }) => {
        return {
          url: `/captain/forms/draft`,
          method: "POST",

          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createTempForm: builder.mutation({
      query: ({ accessToken, EventType }) => {
        return {
          url: `/captain/forms/temp`,
          method: "POST",
          body: {
            eventType: EventType,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateDraftForm: builder.mutation({
      query: ({ accessToken, draftId, requestData }) => {
        return {
          url: `/captain/forms/draft/${draftId}`,
          method: "PUT",
          body: requestData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateTempForm: builder.mutation({
      query: ({ accessToken, draftId, requestData }) => {
        return {
          url: `/captain/forms/draft/${draftId}`,
          method: "POST",
          body: requestData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useAuditorGetRequestListQuery,
  useMyRequestQuery,
  useSingleRequestQuery,
  useDeleteFormUserMutation,
  useChangeStatusToUnderReviewMutation,
  useGetPdfIdQuery,
  useChangeStatusFormMutation,
  useUpdateFormRequestMutation,
  useCreateDraftFormMutation,
  useUpdateDraftFormMutation,
  useStatisticQuery,
  useCreateTempFormMutation,
  useUpdateTempFormMutation,
} = requestApi;
