import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";

import { handleCopyClick } from "../../utils/copy";
import {
  BoxRefrral,
  EmailRefrral,
  facebookRefrral,
  imgHeaderRerral,
  linkedinRefrral,
  smsRefrral,
  telegramRefrral,
  twitterRefrral,
} from "../../assets/images/refrral";

import { ApiEnum } from "../../types/enum.types";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Wallet from "../../components/Wallet";
import { toast } from "sonner";
import InfoModal from "./QrcodeModal";
import { formatUnits } from "ethers/lib/utils";

const Referral = () => {
  const [showModal, setShowModal] = useState(false);

  const nav = useNavigate();
  const accessToken = useSelector<any>((state) => state.auth.accessToken);

  const [ReferralData, setReferralData] = useState<any>();
  console.log(
    "🚀 ~ file: index.tsx:32 ~ Referral ~ ReferralData:",
    ReferralData
  );
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [copyMessage, setCopyMessage] = useState();
  const shareUrl = `${ApiEnum?.IPCAPTAIN_APP}/r/${ReferralData?.code}`;
  const title = `Hello,
You are invited to join IP-Ecosystem Apps using the below link. Enjoy the power of blockchain technology. 
Thank you,
IP-EcoSystem Support Team`;

  const getReferral = async () => {
    try {
      var config = {
        method: "get",
        url: ApiEnum.ACCOUNT_API + `/user/referral`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Application-Id": "1b85a246-77ac-11ee-86ea-bce92fb9ddda",
        },
      };
      const response = await axios(config);
      setReferralData(response?.data);
      console.log("response:", response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getReferral();
  }, []);
  const WithdrawBalance = async () => {
    try {
      var config = {
        method: "post",
        url: ApiEnum.ACCOUNT_API + `/user/referral/withdraw`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Application-Id": "1b85a246-77ac-11ee-86ea-bce92fb9ddda",
        },
      };
      const response = await axios(config);
      console.log("response:", response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl
      )}&text=${encodeURIComponent(title)}`,
      "_blank"
    );
  };

  const handleLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };
  const emailSubject = "IP-Ecosystem";
  const emailBody = `Hello, 

  You are invited to join IP-Ecosystem Apps using the below link. Enjoy the power of blockchain technology. 
  ${shareUrl}
  
  Thank you,
  IP-EcoSystem Support Team`;

  const handleEmailShare = () => {
    window.location.href = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
  };
  const smsMessage = `Hello, 

  You are invited to join IP-Ecosystem Apps using the below link. Enjoy the power of blockchain technology. 
  ${shareUrl}
  
  Thank you,
  IP-EcoSystem Support Team`;

  const handleSMSShare = () => {
    window.location.href = `sms:?body=${encodeURIComponent(smsMessage)}`;
  };
  const telegramMessage = `Hello, 
You are invited to join IP-Ecosystem Apps using the below link. Enjoy the power of blockchain technology. 
${shareUrl}
Thank you,
IP-EcoSystem Support Team`;

  const handleTelegramShare = () => {
    window.location.href = `https://t.me/share/url?url=${encodeURIComponent(
      telegramMessage
    )}`;
  };
  return (
    <div className="profile-page">
      <Sidebar />
      <Wallet />
      <div className="requset-container">
        {showModal && (
          <InfoModal
            showModal={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            address={shareUrl}
          />
        )}
        <div className="walletContainer-walletPage ">
          {/* <div className="walletHeader-walletPage ">
            <h2>Referral</h2>
          </div> */}

          <div className="banner-refrral">
            <img src={imgHeaderRerral} />
            <h2 className="banner-refrral-header">
              You have earned{" "}
              {ReferralData?.balance && formatUnits(ReferralData?.balance, 6)}{" "}
              USDT
            </h2>
            <h3 className="banner-refrral-header">
              You have invited {ReferralData?.count} of your friends to the
              IP-Ecosystem.
            </h3>
            <div className="searchContainer-reffral">
              <div>{shareUrl}</div>
              <button
                onClick={() => {
                  // handleCopyClick(shareUrl, setCopyMessage);
                  setShowModal(true);
                  // toast.success("Copied.");
                }}
              >
                QRcode
                <i
                  style={{ marginLeft: "4px" }}
                  className={copyMessage ? "pi pi-check-circle" : "pi pi-copy"}
                ></i>
              </button>
            </div>
          </div>

          <div className="socialShareContainer-refrral">
            <div className="SocialmediaLink">
              <h4>Share your referral link</h4>
              <h5>Social media</h5>
              <div>
                <button onClick={() => handleFacebookShare()}>
                  <img src={facebookRefrral} />
                </button>
                <button onClick={() => handleTwitterShare()}>
                  <img src={twitterRefrral} />
                </button>
                <button onClick={() => handleLinkedInShare()}>
                  <img src={linkedinRefrral} />
                </button>
                <button onClick={() => handleTelegramShare()}>
                  <img src={telegramRefrral} />
                </button>
                <button onClick={() => handleSMSShare()}>
                  <img src={EmailRefrral} />
                </button>
                <button onClick={() => handleEmailShare()}>
                  <img src={smsRefrral} />
                </button>
              </div>
            </div>
            <div className="Readeem-reffrral">
              <h4>Redeem your bonus</h4>
              <div>
                Your current bonus balance is:{" "}
                {ReferralData?.balance && formatUnits(ReferralData?.balance, 6)}{" "}
                USDT
              </div>
              <button onClick={() => WithdrawBalance()}>Redeem</button>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
