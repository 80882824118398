import { routeEnum } from "../../types/enum.types";

export const steps = [
  {
    title: "",
    pageLink: routeEnum.steps,
  },
  {
    title: "Basic Information",
    pageLink: routeEnum.basicInfo,
  },
  {
    title: "Product / Service",
    pageLink: routeEnum.product,
  },
  {
    title: "Market and Competition",
    pageLink: routeEnum.market,
  },
  {
    title: "Business Model",
    pageLink: routeEnum.businessModel,
  },
  {
    title: "Financials",
    pageLink: routeEnum.finance,
  },
  {
    title: "Traction",
    pageLink: routeEnum.traction,
  },
  {
    title: "Risk and Challenges",
    pageLink: routeEnum.risks,
  },
  {
    title: "Other Information",
    pageLink: routeEnum.other,
  },
];
