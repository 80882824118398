import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

export const ipApi = createApi({
  reducerPath: "ip",
  baseQuery: fetchBaseQuery({ baseUrl: ApiEnum.IP_API }),
  endpoints: (builder) => ({
    singleIp: builder.query({
      query: ({ ipId }) => {
        return {
          url: `ips/${ipId}`,
        };
      },
    }),
  }),
});

export const { useSingleIpQuery } = ipApi;
