import Login_img from "../../../assets/images/login-bg.png";
import axios from "axios";
import EmailIcon from "../../../assets/icons/email.svg";
import UserInputIcon from "../../../assets/icons/userInput.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiEnum, RouteEnum } from "../../../types/enum.types";
import Button from "../../../components/LoginButton/Button";
import { IPCaptainLogo } from "../../../assets/images";

type Props = {};

const Register = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [registerState, setRegister] = useState({
    // fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repassword: "",
  });

  const [registerError, setRegisterError] = useState({
    emailError: "",
    firstNameError: "",
    lastNameError: "",
    passwordError: "",
    submitError: "",
  });

  useEffect(() => {
    setRegisterError({
      emailError: "",
      submitError: "",
      passwordError: "",
      firstNameError: "",
      lastNameError: "",
    });
  }, [registerState]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setRegisterError({
      emailError: "",
      submitError: "",
      passwordError: "",
      firstNameError: "",
      lastNameError: "",
    });
    if (
      registerState.password.length < 8 ||
      registerState.repassword.length < 8
    ) {
      setRegisterError((state) => ({
        ...state,
        passwordError: "Passwords length must be at least 8 character",
      }));
      return;
    }

    if (registerState.password !== registerState.repassword) {
      setRegisterError((state) => ({
        ...state,
        passwordError: "Passwords Do Not Match",
      }));
      return;
    }

    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ApiEnum.CAPTAIN_API}/register`,
        {
          // name: registerState.fullName,
          firstName: registerState.firstName,
          lastName: registerState.lastName,
          email: registerState.email.toLocaleLowerCase(),
          password: registerState.password,
        },
        {
          headers: {
            "x-service-name": "ipcafe",
          },
        }
      );

      console.log(res);
      setIsLoading(false);
      navigate(RouteEnum.pendingForActivation);
    } catch (err: any) {
      if (err?.response?.data) {
        setRegisterError((state) => ({
          ...state,
          submitError: err?.response?.data?.message,
        }));
        setIsLoading(false);
      } else {
        console.log(err);
        setRegisterError((state) => ({
          ...state,
          submitError: err.message,
        }));
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="auth register">
      <div className="auth-header ">
        <img src={IPCaptainLogo} alt="" />
      </div>
      <div className="auth-form flex justify-center items-center">
        <form onSubmit={handleSubmit} autoComplete="new-password">
          <h1 className="heading-l ">Nice to see you</h1>
          <div className="input-controller-auth">
            <label htmlFor="">First name</label>
            <div className="input-icon">
              <input
                type="text"
                name=""
                id=""
                value={registerState.firstName}
                onChange={(e) =>
                  setRegister((state) => ({
                    ...state,
                    firstName: e.target.value,
                  }))
                }
                placeholder="Enter your first name"
                required
              />
              {/* <img src={UserInputIcon} alt="" /> */}
            </div>
          </div>
          <div className="input-controller-auth">
            <label htmlFor="">Last name</label>
            <div className="input-icon">
              <input
                type="text"
                name="lastname"
                id=""
                value={registerState.lastName}
                onChange={(e) =>
                  setRegister((state) => ({
                    ...state,
                    lastName: e.target.value,
                  }))
                }
                placeholder="Enter your last name"
                required
              />
              {/* <img src={UserInputIcon} alt="" /> */}
            </div>
          </div>
          <div className="input-controller-auth">
            <label htmlFor="">Email address</label>
            <div className="input-icon">
              <input
                type="email"
                name=""
                value={registerState.email}
                onChange={(e) =>
                  setRegister((state) => ({
                    ...state,
                    email: e.target.value,
                  }))
                }
                id=""
                placeholder="Enter your email"
                required
              />
              {/* <img src={EmailIcon} alt="" /> */}
            </div>
          </div>
          <div className="input-controller-auth">
            <label htmlFor="">Set password</label>
            <div className="input-icon">
              <input
                type="password"
                name="password"
                value={registerState.password}
                onChange={(e) =>
                  setRegister((state) => ({
                    ...state,
                    password: e.target.value,
                  }))
                }
                id=""
                placeholder="Enter password"
                required
              />
              {/* <img src={LockIcon} alt="" /> */}
            </div>
            <div className="input-icon">
              <input
                type="password"
                name="password2"
                value={registerState.repassword}
                onChange={(e) =>
                  setRegister((state) => ({
                    ...state,
                    repassword: e.target.value,
                  }))
                }
                id=""
                placeholder="Confirm password"
                required
              />
              {/* <img src={LockIcon} alt="" /> */}
            </div>
            {registerError.passwordError ? (
              <span className="error">{registerError.passwordError}</span>
            ) : (
              <></>
            )}
          </div>
          {registerError.submitError ? (
            <span className="error">{registerError.submitError}</span>
          ) : (
            <></>
          )}
          {/* <button className="btn btn-primary " type="submit">
            Sign Up
          </button> */}
          <Button content="Sign Up" lodaing={isLoading} />
          <div
            style={{ fontSize: "12px", width: "100%" }}
            className="flex justify-center w-full text-center "
          >
            Already have an IPCafe Account? •
            <Link
              style={{ color: "blue", paddingLeft: "", display: "inline" }}
              to={"/auth/login"}
            >
              {" "}
              Login
            </Link>
          </div>
        </form>
        {/* <div
          style={{
            position: "absolute",
            bottom: "0",
            fontSize: "12px",
          }}
        >
          <span
            onClick={() => navigate(RouteEnum.tos)}
            className="cursor-pointer"
          >
            Terms and conditions
          </span>
          {"  .  "}
          <span
            onClick={() => navigate(RouteEnum.privacy)}
            className="cursor-pointer"
          >
            Privacy policy
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Register;
