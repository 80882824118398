import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Toaster, toast } from "sonner";
import { ApiEnum } from "../../../../types/enum.types";
import upload from "../../images/receive-square.svg";
import { setFips } from "crypto";
import Spinner from "../../../../components/Spinner/Spinner";

type Props = {};

const UploadOtherInfo = ({ setApplicationData, otherInformation }) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setfile] = useState(null);
  const cancelToken = axios.CancelToken;
  const sourceCancel = cancelToken.source();
  const onUploadProfile = async (selectedFile: any, type: any) => {
    setIsLoading(true);
    try {
      setProgress(0);
      const formdata: any = new FormData();
      formdata.append("file", selectedFile);
      var config = {
        method: "post",
        url: ApiEnum.STORAGE_API + `/files/upload`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-File-Access": 0,
        },
        data: formdata,
      };
      const response = await axios({
        ...config,
        cancelToken: sourceCancel.token,
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(uploadPercentage);
        },
      });
      // setRequestData(response);
      if (response?.status == 200) {
        setApplicationData((state) => ({
          ...state,
          otherInformation: {
            ...otherInformation,
            businessPlanFileId: response?.data,
          },
        }));
        setIsLoading(false);
      }
      toast.success("file uploaded successfully");
      console.log("response onUploadProfile:", response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Error in Uploading file");
    }
  };

  return (
    <>
      <div className="form-controller upload ">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <label className="">Do you have a business plan?</label>

          {/* {file && (
            <span
              style={{
                color: "#858585",
                fontSize: "14px",
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {file?.name}
            </span>
          )} */}
          {otherInformation.businessPlanFileId && (
            <>
              <a
                className="upload_view"
                target="_blank"
                href={`${ApiEnum.STORAGE_API}/files?id=${otherInformation.businessPlanFileId}#view=fitH&toolbar=`}
              >
                view file
              </a>
            </>
          )}
        </div>
        {/* <div className="">skdfm </div> */}
        <div className="upload-label">
          {isLoading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              <img style={{ pointerEvents: "none" }} src={upload} alt="" />{" "}
              <p style={{ pointerEvents: "none" }}>Upload</p>
              <input
                id="uplaod"
                onChange={(event: any) => {
                  // setPitchDeck(event.target);

                  setfile(event?.target?.files[0]);
                  // setPitchDeckURL(
                  //   event?.target?.files[0]
                  //     ? URL.createObjectURL(event?.target?.files[0])
                  //     : null
                  // );
                  onUploadProfile(
                    event.target.files[0],
                    event.target.files[0].type
                  );
                }}
                type="file"
                accept=".pdf"
                style={{ zIndex: "10", display: "block" }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

{
  /* <div className="progress">
  <div
  className="progress-bar"
  role="progressbar"
  style={{ width: `${progress}%` }}
  ></div>
  </div> */
}
export default UploadOtherInfo;
