import React, { useContext } from "react";
import imgNft from "../../../assets/images/imgNft.png";
import { getAllDiscountToken } from "../../../utils/api/discountToken";
import { biconomyContext } from "../../../utils/Context";
import { useGetAllTokenQuery } from "../../../store/api/tokenApi";

type Props = {};

const Nft = ({ token }) => {
  return (
    <div className="body-assets">
      <div
        className="items"
        style={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        <img src={token?.meta?.content[0]?.url} alt="" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="name">{token?.meta?.name}</span>
          <span className="value">{token?.mintedAt}</span>
        </div>
      </div>
      {/* <div className="items">
        <span>{token?.supply}</span>
      </div>
      <div className="items">
        <span>245.2323</span>
      </div> */}
    </div>
  );
};

export default Nft;
