import React, { useContext, useState } from "react";
import { steps } from "../stepsInfo";
import editIcon from "../images/editeUnderline.svg";
import tickMarkIcon from "../images/tickMArk.svg";
import tickMarkDisableIcon from "../images/tickMarkDisable.svg";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routeEnum } from "../../../types/enum.types";
import MintModal from "./Mint/MintModal";
import { FormApplicationContext } from "../CreateApplication";
import {
  checkIfBasicInfoIsComplete,
  checkIfBusinessInfoIsComplete,
  checkIfFinanceInfoIsComplete,
  checkIfMarketInfoIsComplete,
  checkIfOtherInfoIsComplete,
  checkIfProductInfoIsComplete,
  checkIfRiskInfoIsComplete,
  checkIfTractionInfoIsComplete,
} from "../utils/CompletFormCheck";

type Props = {};

const Steps = (props: Props) => {
  const {
    basicInformation,
    productInformation,
    marketInformation,
    businessModelInformation,
    financialInformation,
    tractionInformation,
    riskInformation,
    otherInformation,
    appStatus,
    updateApplication,
  } = useContext(FormApplicationContext);

  const navigate = useNavigate();
  const { id } = useParams();
  const [mintModal, setMintModal] = useState({
    show: false,
  });

  const chooseIfFormPartIsComplete = (step) => {
    // debugger;
    switch (step) {
      case steps[1].title:
        if (checkIfBasicInfoIsComplete(basicInformation)) return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[2].title:
        if (checkIfProductInfoIsComplete(productInformation))
          return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[3].title:
        if (checkIfMarketInfoIsComplete(marketInformation)) return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[4].title:
        if (checkIfBusinessInfoIsComplete(businessModelInformation))
          return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[5].title:
        if (checkIfFinanceInfoIsComplete(financialInformation))
          return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[6].title:
        if (checkIfTractionInfoIsComplete(tractionInformation))
          return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[7].title:
        if (checkIfRiskInfoIsComplete(riskInformation)) return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;
      case steps[8].title:
        if (checkIfOtherInfoIsComplete(otherInformation)) return tickMarkIcon;
        else return tickMarkDisableIcon;
        break;

      default:
        return tickMarkDisableIcon;

        break;
    }
  };

  return (
    <div className="application-sections">
      {mintModal.show && <MintModal setMintModal={setMintModal} />}
      {steps.slice(1, steps.length).map((step) => (
        <>
          <div className="section-item">
            <div className="title">
              <img src={chooseIfFormPartIsComplete(step.title)} alt="" />
              <h4>
                {step.title}
                {step.pageLink == "basic-info" ? (
                  <span className="red">*</span>
                ) : (
                  ""
                )}
              </h4>
            </div>

            <button
              onClick={() => {
                navigate(`/app/${id}/${step.pageLink}`);
              }}
            >
              {" "}
              <img src={editIcon} alt="" />
              Edit
            </button>
          </div>
        </>
      ))}
      <div className="submit-application">
        {checkIfBasicInfoIsComplete(basicInformation) ? (
          <>
            {appStatus == "SFS_DRAFT" ? (
              <>
                {" "}
                <button
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                  onClick={() => {
                    setMintModal((state) => ({ ...state, show: true }));
                  }}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                {" "}
                {appStatus == "SFS_UPDATE" ? (
                  <>
                    <button
                      onClick={() => {
                        updateApplication();
                        navigate("/requests");
                      }}
                    >
                      Apply Changes
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      navigate("/certificate/" + id);
                    }}
                  >
                    Show Certificate
                  </button>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <button
              className="letsgo-btn"
              onClick={() => {
                navigate(
                  `${routeEnum.formApplication}/${id}/${routeEnum.basicInfo}`
                );
              }}
            >
              Let's Start
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Steps;
