import React, { useContext, useEffect, useRef, useState } from "react";
import "./mintModal.css";
import { getAllDiscountToken } from "../../../../utils/api/discountToken";
import ApllicationMint from "./ApllicationMint";
import { useSelector } from "react-redux";
import { biconomyContext } from "../../../../utils/Context";
import { closeIcon } from "../../../../assets/icons";

type Props = {
  setMintModal: Function;
  handelSubmit: Function;
};

const MintModal = ({ setMintModal, handelSubmit }: Props) => {
  const mintPrice = Number(process.env.REACT_APP_MINT_PRICE);
  const [TokenOff, setTokenOff] = useState<any>();
  const [discount, setDiscount] = useState<any>();
  const [scrollX, setScrollX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const containerRef = useRef(null);

  const { wallet }: any = useSelector<any>((state) => state.wallet);
  console.log("🚀 ~ file: MintModal.tsx:18 ~ MintModal ~ wallet:", wallet);

  const { address } = useContext(biconomyContext);

  const getToken = async () => {
    // debugger;
    let { tokens } = await getAllDiscountToken("POLYGON", address);
    console.log(tokens);

    // debugger;
    setTokenOff(tokens);
  };

  useEffect(() => {
    console.log("discount", discount);
  }, [discount]);
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleMouseDown = (e) => {
      setDragging(true);
      setStartX(e.clientX - container.scrollLeft);
      e.preventDefault();
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    const handleMouseMove = (e) => {
      if (!dragging) return;

      const deltaX = e.clientX - startX;
      container.scrollLeft -= deltaX;
    };

    container.addEventListener("mousedown", handleMouseDown);
    container.addEventListener("mouseup", handleMouseUp);
    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousedown", handleMouseDown);
      container.removeEventListener("mouseup", handleMouseUp);
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, [dragging, startX]);
  useEffect(() => {
    getToken();
  }, []);
  return (
    <>
      <div className="mintModlaContainer">
        <div className="mintModal">
          <h4>Attention</h4>
          <img
            className="close-btn"
            src={closeIcon}
            onClick={() => {
              setMintModal((state) => ({ ...state, show: false }));
            }}
          />

          <p>
            Disclaimer: By proceeding with the use of this platform, you affirm
            that you have the complete and legal ownership of intellectual
            property of the subject application.
          </p>

          <h5>
            IPCaptain Application Fee:{" "}
            <span
              style={{
                textDecoration: discount && "line-through",
                textDecorationColor: discount && "red",
              }}
            >
              {mintPrice} USDT
            </span>
          </h5>
          <h5 style={{ marginTop: "10px" }}>
            Discount:{" "}
            <span style={{ color: "green" }}>
              {discount && (
                <p className="DiscountAgreement">
                  -{(discount?.tokenId * 100) / 100}%
                </p>
              )}
            </span>
          </h5>
          <div
            ref={containerRef}
            onScroll={(e) => setScrollX(e.currentTarget.scrollLeft)}
            style={{ marginTop: "5px" }}
            className="FactorServiceFee overflowFactorServiceFee"
          >
            {TokenOff?.length > 0 ? (
              <>
                {TokenOff?.map((token, i) => (
                  <div
                    style={{ position: "relative", margin: "0 5px" }}
                    className="DiscountAgreementContainer"
                  >
                    {discount === token && (
                      <div
                        className="DiscountAgreement-remove"
                        onClick={() => setDiscount(null)}
                      >
                        -
                      </div>
                    )}
                    {discount !== token && (
                      <div className="DiscountAgreement-add">+</div>
                    )}
                    <button
                      style={{
                        background: discount === token && "rgb(0 130 255)",
                      }}
                      onClick={() => setDiscount(token)}
                      className="DiscountAgreement-btn"
                      key={i}
                    >
                      <img src={token?.meta?.content[0]?.url} />
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  fontSize: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "#7c7c7c",
                }}
              >
                you don't have any discount token!
              </div>
            )}
          </div>
          <p style={{ color: "red", fontSize: "12px" }}>
            {(discount ? (1 - discount / 100) * mintPrice : mintPrice) >
              parseFloat(wallet.usdt) && "Insufficient Balance"}
          </p>
          <h5>
            Total Fee:{" "}
            <span>
              {discount
                ? ((1 - parseInt(discount?.tokenId) / 100) * mintPrice).toFixed(
                    2
                  )
                : `${mintPrice}`}{" "}
              USDT
            </span>
          </h5>
          <ApllicationMint
            value={
              discount
                ? ((1 - parseInt(discount?.tokenId) / 100) * mintPrice).toFixed(
                    2
                  )
                : `${mintPrice}`
            }
            hasMoney={
              (discount ? (mintPrice * discount?.tokenId) / 100 : mintPrice) >
              parseFloat(wallet.usdt)
            }
            discountId={discount?.tokenId || 0}
            handelSubmit={handelSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default MintModal;
