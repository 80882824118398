import React, { createContext, useEffect, useState } from "react";
import HomePage from "./screen/home";

import Profile from "./screen/create-request";
// import Profile from "@/screen/Profile/Profile";

import Requests from "./screen/my-requests";
import Assets from "./screen/assets";
import "./css/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApiEnum, Contracts, RouteEnum, routeEnum } from "./types/enum.types";
import Login from "./screen/auth/Login";
import Register from "./screen/auth/Register";
import PendingForActivation from "./screen/auth/pendingForActivation";
import ForgotPassword from "./screen/auth/forgetPassword";
import AuthenticationResult from "./screen/auth/authenticationResult";
import ParticleLogin from "./screen/particle/Login";
import { useDispatch, useSelector } from "react-redux";

import { GuardedLogin, GuardedRoute } from "./utils/routeGuard";
import RequestsDetail from "./screen/request-detail";
import { ParticleAuthModule, ParticleProvider } from "@biconomy/particle-auth";

import { biconomyContext } from "./utils/Context";

import {
  DEFAULT_ECDSA_OWNERSHIP_MODULE,
  DEFAULT_ENTRYPOINT_ADDRESS,
  ECDSAOwnershipValidationModule,
  ERC20_ABI,
} from "@biconomy/modules";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import { ChainId } from "@biconomy/core-types";

import {
  BiconomyPaymaster,
  IHybridPaymaster,
  IPaymaster,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";

import { ethers } from "ethers";
import { Bundler, IBundler } from "@biconomy/bundler";
import { useParticleUserMutation } from "./store/api/acoountApi";
import { setWalletAddress } from "./store/logic/walletSlice";
import { Toaster, toast } from "sonner";
import { login, logout } from "./store/logic/authSlice";
import axios from "axios";
import Transak from "@biconomy/transak";
import Referral from "./screen/referral";
import ReferralLogin from "./screen/referral-id";
import Certificate from "./screen/Certficate/Cetificate";
import MyApplication from "./screen/my-application";
import ScrollToTop from "./utils/ScrollToTop";
import Test from "./screen/testGas";
import CreateApplication from "./screen/create-application/CreateApplication";
import Steps from "./screen/create-application/components/Steps";
import BasicInfo from "./screen/create-application/components/BasicInfo";
import Product from "./screen/create-application/components/Product";
import Market from "./screen/create-application/components/Market";
import Finance from "./screen/create-application/components/Finance";
import Traction from "./screen/create-application/components/Traction";
import Risk from "./screen/create-application/components/Risk";
import OtherInfo from "./screen/create-application/components/OtherInfo";
import BusinessModel from "./screen/create-application/components/BusinessModel";
import ApplicationReview from "./screen/request-detail/ApplicationReview";
// import BCI from "./components/BIC2024/BlockchainChallenge";
function App() {
  const dispatch = useDispatch();
  const referralCode = useSelector<any>((state) => state.auth.referralCode);
  const [smartAccount, setSmartAccount] = useState<any>(null);
  const [address, setAddress] = useState(null);
  const [bnbsmartAccount, setBnbSmartAccount] = useState<any>(null);
  const [bnbAddress, setBnbAddress] = useState(null);
  const [isLoginLoading, setIsloginLoading] = useState(false);

  const [email, setEmail] = useState<string | undefined>("");

  const submitUser = async (particleData) => {
    try {
      let data = await axios.post(
        `${ApiEnum.ACCOUNT_API}/login/provider:particle`,
        particleData,
        {
          headers: {
            "X-Application-Id": "1b85a246-77ac-11ee-86ea-bce92fb9ddda",
            "X-Referral-Code": referralCode + "",
          },
        }
      );
      // debugger;
      console.log(data?.data);

      dispatch(login(data?.data));
      toast.success("Logged in successfully");
      setIsloginLoading(false);
    } catch (error) {
      setIsloginLoading(false);
      toast.error("Something went wrong during login. Please try again.");

      await particle.auth.logout();
    }
  };

  const bundler: IBundler = new Bundler({
    // get from biconomy dashboard https://dashboard.biconomy.io/
    bundlerUrl:
      // "https://bundler.biconomy.io/api/v2/80001/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44",
      "https://bundler.biconomy.io/api/v2/137/hj789s.jk780Yh-iJkl-Ghjo-af80-68ggf74bbH67",
    chainId: ChainId.POLYGON_MAINNET, // or any supported chain of your choice
    entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
  });
  const Bnbbundler: IBundler = new Bundler({
    // get from biconomy dashboard https://dashboard.biconomy.io/
    bundlerUrl: `https://bundler.biconomy.io/api/v2/${ChainId.BSC_TESTNET}/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44`,
    // "https://bundler.biconomy.io/api/v2/137/hj789s.jk780Yh-iJkl-Ghjo-af80-68ggf74bbH67",
    chainId: ChainId.POLYGON_MAINNET, // or any supported chain of your choice
    entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
  });

  const paymaster: IPaymaster = new BiconomyPaymaster({
    // get from biconomy dashboard https://dashboard.biconomy.io/
    paymasterUrl:
      // "https://paymaster.biconomy.io/api/v1/80001/nqYmKVmWu.ae1e6f6b-37cf-4985-a0d1-ba697c307c8a",
      `https://paymaster.biconomy.io/api/v1/137/${process.env.REACT_APP_BICONOMY_PAYMASTER_API_KEY}`,
  });
  const bnbPaymaster: IPaymaster = new BiconomyPaymaster({
    // get from biconomy dashboard https://dashboard.biconomy.io/
    paymasterUrl:
      "https://paymaster.biconomy.io/api/v1/97/f7d1wXfoF.f375bfa0-749f-4a26-bc36-ae03eac024c4",
  });

  const particle = new ParticleAuthModule.ParticleNetwork({
    projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID,
    clientKey: process.env.REACT_APP_PARTICLE_CLIENT_KEY,
    appId: process.env.REACT_APP_PARTICLE_APP_ID,

    wallet: {
      displayWalletEntry: false,
      defaultWalletEntryPosition: ParticleAuthModule.WalletEntryPosition.BR,
    },
  });

  const particleProvider = new ParticleProvider(particle.auth);
  const web3Provider = new ethers.providers.Web3Provider(
    particleProvider,
    "any"
  );
  const createWallet = async () => {
    const particleProvider = new ParticleProvider(particle.auth);
    const web3Provider = new ethers.providers.Web3Provider(
      particleProvider,
      "any"
    );

    const module = await ECDSAOwnershipValidationModule.create({
      signer: web3Provider.getSigner(),
      moduleAddress: DEFAULT_ECDSA_OWNERSHIP_MODULE,
    });

    let biconomySmartAccount = await BiconomySmartAccountV2.create({
      chainId: ChainId.POLYGON_MAINNET,
      bundler: bundler,
      paymaster: paymaster,
      entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
      defaultValidationModule: module,
      activeValidationModule: module,
    });
    let bnbBiconomySmartAccount = await BiconomySmartAccountV2.create({
      chainId: ChainId.BSC_TESTNET,
      bundler: Bnbbundler,
      paymaster: bnbPaymaster,
      entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
      defaultValidationModule: module,
      activeValidationModule: module,
    });

    const address = await biconomySmartAccount.getAccountAddress();
    const bnbaddress = await bnbBiconomySmartAccount.getAccountAddress();
    setAddress(address);
    setBnbAddress(bnbaddress);
    dispatch(setWalletAddress(address));
    setSmartAccount(biconomySmartAccount);
    setBnbSmartAccount(bnbBiconomySmartAccount);
  };
  const connect = async () => {
    setIsloginLoading(true);
    // debugger;
    try {
      let isLoggedIn = particle.auth.isLogin();
      if (isLoggedIn) {
        setIsloginLoading(false);
        createWallet();
        return;
      }
      const userInfo = await particle.auth.login({
        // supportAuthTypes: "twitter,facebook,linkedin,microsoft,phone",
        supportAuthTypes: "email",
      });
      console.log("Logged in user:", userInfo);
      setEmail(userInfo.email);

      const particleProvider = new ParticleProvider(particle.auth);
      const web3Provider = new ethers.providers.Web3Provider(
        particleProvider,
        "any"
      );

      const module = await ECDSAOwnershipValidationModule.create({
        signer: web3Provider.getSigner(),
        moduleAddress: DEFAULT_ECDSA_OWNERSHIP_MODULE,
      });

      let biconomySmartAccount = await BiconomySmartAccountV2.create({
        chainId: ChainId.POLYGON_MAINNET,
        bundler: bundler,
        paymaster: paymaster,
        entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
        defaultValidationModule: module,
        activeValidationModule: module,
      });
      let bnbBiconomySmartAccount = await BiconomySmartAccountV2.create({
        chainId: ChainId.BSC_TESTNET,
        bundler: Bnbbundler,
        paymaster: bnbPaymaster,
        entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
        defaultValidationModule: module,
        activeValidationModule: module,
      });
      const address = await biconomySmartAccount.getAccountAddress();
      setAddress(address);
      const bnbaddress = await bnbBiconomySmartAccount.getAccountAddress();
      setBnbAddress(bnbaddress);

      setBnbSmartAccount(bnbBiconomySmartAccount);

      setSmartAccount(biconomySmartAccount);
      submitUser({
        uuid: userInfo.uuid,
        token: userInfo.token,
        publicKey: address.toLowerCase(),
      });
    } catch (error) {
      console.error(error);
      setIsloginLoading(false);
      toast.error("Something went wrong during login. Please try again.");
      // particle.auth.logout();
      dispatch(logout());
    }
  };
  // debugger
  useEffect(() => {
    connect();
  }, []);

  return (
    <div className="App">
      <Toaster richColors position="top-right" closeButton />
      <BrowserRouter>
        <ScrollToTop>
          <biconomyContext.Provider
            value={{
              particle,
              smartAccount,
              address,
              toast,
              bnbAddress,
              bnbsmartAccount,
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <GuardedLogin
                  // isRouteAccessible={true}
                  // redirectRoute={RouteEnum.login}
                  />
                }
              >
                <Route
                  path={RouteEnum.login}
                  element={
                    <ParticleLogin
                      isLoginLoading={isLoginLoading}
                      connect={connect}
                    />
                  }
                />
                {/* <Route path={RouteEnum.login} element={<Login />} /> */}
                <Route path={RouteEnum.register} element={<Register />} />
                <Route
                  path={RouteEnum.pendingForActivation}
                  element={<PendingForActivation />}
                />
                <Route path={"/r/:referralId"} element={<ReferralLogin />} />
                <Route
                  path={RouteEnum.forgotPassword}
                  element={<ForgotPassword />}
                />
                <Route
                  path="/AuthenticationResult"
                  element={<AuthenticationResult />}
                />
              </Route>
              <Route
                path="/"
                element={
                  <GuardedRoute
                    isRouteAccessible={true}
                    redirectRoute={RouteEnum.login}
                  />
                }
              >
                {/* Add other routes here */}
                {/* <Route path="/form" element={<Profile />} /> */}
                <Route path="/form/:id" element={<Profile />} />
                <Route path="/app/:id" element={<CreateApplication />}>
                  <Route path={routeEnum.steps} element={<Steps />} />
                  <Route path={routeEnum.basicInfo} element={<BasicInfo />} />
                  <Route path={routeEnum.product} element={<Product />} />
                  <Route path={routeEnum.market} element={<Market />} />
                  <Route
                    path={routeEnum.businessModel}
                    element={<BusinessModel />}
                  />
                  <Route path={routeEnum.finance} element={<Finance />} />
                  <Route path={routeEnum.traction} element={<Traction />} />
                  <Route path={routeEnum.risks} element={<Risk />} />
                  <Route path={routeEnum.other} element={<OtherInfo />} />
                </Route>
                <Route index element={<Requests />} />
                <Route path={"/referral"} element={<Referral />} />
                <Route path="/requests" element={<MyApplication />} />
                {/* <Route path="/requests" element={<Requests />} /> */}
                {/* <Route path="/review/:requestid" element={<RequestsDetail />} /> */}
                <Route
                  path="/review/:requestid"
                  element={<ApplicationReview />}
                />
                <Route path="/certificate/:id" element={<Certificate />} />
                <Route path="/assets" element={<Assets />} />
                {/* <Route path="/test" element={<Test />} /> */}
              </Route>
            </Routes>
          </biconomyContext.Provider>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
