import React, { useEffect, useMemo, useState } from "react";

import "../../auth.css";
// import EmailIcon from "../../../../assets/icons/email.svg";
// import LockIcon from "../../../../assets/icons/lock.svg";
// import EyeIcon from "../../assets/icons/eye.svg";
// import EyeOffIcon from "../../assets/icons/eye-off.svg";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react18-input-otp";
import { ApiEnum, RouteEnum } from "../../../../types/enum.types";
import Button from "../../../../components/LoginButton/Button";

type Props = {
  email: string;
};

const ResetPassword = ({ email }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [timerOn, setTimerOn] = useState(true);
  const [timerCount, setTimerCount] = useState(180000);
  const [resetPassword, setResetPassword] = useState({
    email: email,
    code: "",
    password1: "",
    password2: "",
  });

  const [loginError, setLoginError] = useState({
    emailError: "",
    passwordError: "",
    submitError: "",
  });

  const TimerController = () => {
    return (
      <span className={`text-gray-500 flex flex-row`}>
        {timerOn ? (
          <>
            <span className=" mr-3">after</span>{" "}
            <Countdown
              date={Date.now() + timerCount}
              onComplete={() => {
                setTimerOn(false);
              }}
              autoStart
              renderer={(data: any) => (
                <span style={{ right: 5 }} className="relative  block">
                  {data.minutes > 0 ? `${data.minutes}:` : ""}
                  {data.seconds}
                </span>
              )}
            />
          </>
        ) : undefined}
      </span>
    );
  };
  const reGetCode = () => {
    setIsLoading(true);
    setLoginError((state) => ({
      ...state,
      emailError: "",
      passwordError: "",
      submitError: "",
    }));
    // const { email } = formik.values;/
    // const recaptchaValue = captchaRef.current?.getValue();
    // console.log(recaptchaValue);

    // const customHeader = { "X-Captcha-Key": recaptchaValue as string };
    axios
      .post(
        `${ApiEnum.CAPTAIN_API}/forgot`,
        { email: email.toLowerCase() },
        {
          headers: {
            // "Content-Type":
          },
        }
      )
      .then((res) => {
        // toast.success("Check Your Email");
        console.log(res);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginError((state) => ({
      ...state,
      emailError: "",
      passwordError: "",
      submitError: "",
    }));

    console.log(resetPassword);

    if (resetPassword.code == "" || resetPassword.code == "undefined") {
      setLoginError((state) => ({ ...state, emailError: "code is required" }));
      return;
    }

    if (resetPassword.password1.length < 8) {
      setLoginError((state) => ({
        ...state,
        emailError: "Password: value length must be at least 8 charcter",
      }));
      return;
    }
    if (resetPassword.password1 !== resetPassword.password2) {
      setLoginError((state) => ({
        ...state,
        emailError: "Passwords Do Not Match",
      }));
      return;
    }

    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ApiEnum.CAPTAIN_API}/user/password`,
        {
          email: resetPassword.email,
          code: resetPassword.code,
          password: resetPassword.password1,
        },
        {
          headers: {
            "x-service-name": "ipcafe",
          },
        }
      );

      console.log(res.data);
      navigate(RouteEnum.login);
      setIsLoading(false);
    } catch (err: any) {
      console.log("err", err.response?.data?.message);
      setLoginError((state) => ({
        ...state,
        submitError: err?.response?.data?.message,
      }));
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   setLoginError((state) => ({ ...state, emailError: null }));
  // }, [email]);
  // useEffect(() => {
  //   setLoginError((state) => ({ ...state, passwordError: null }));
  // }, [password]);

  return (
    <form onSubmit={handleSubmit} autoComplete="false">
      <h1 className="heading-l ">We just emailed you. </h1>
      <p style={{ fontWeight: "600" }}>
        {" "}
        Please enter the 6 digit code we emailed you.
      </p>
      <div className="input-controller-auth ">
        <div className="confirm-otp-controller">
          {/* <label htmlFor="">
           
          </label> */}
          <OtpInput
            isInputNum={true}
            value={resetPassword.code}
            onChange={(val: string) =>
              setResetPassword((state) => ({ ...state, code: val }))
            }
            numInputs={6}
            separator={<span></span>}
          />
        </div>
        {/* <div className="confirm-otp-controller">
          <input
            // isInputNum={true}
            value={resetPassword.code}
            onChange={(e) =>
              setResetPassword((state) => ({ ...state, code: e.target.value }))
            }
            type="number"
            placeholder="Please enter the code we emailed you."
            // numInputs={6}
            // separator={<span></span>}
          />
        </div> */}
        <p style={{ marginTop: "10px" }}> Please enter your new password.</p>
        <div className="input-icon">
          <input
            type="password"
            name=""
            value={resetPassword.password1}
            onChange={(e) =>
              setResetPassword((state) => ({
                ...state,
                password1: e.target.value,
              }))
            }
            id=""
            placeholder="Enter password"
          />
          {/* <img src={LockIcon} alt="" /> */}
        </div>
        <div className="input-icon">
          <input
            type="password"
            autoComplete="new-password"
            name=""
            value={resetPassword.password2}
            onChange={(e) =>
              setResetPassword((state) => ({
                ...state,
                password2: e.target.value,
              }))
            }
            id=""
            placeholder="Confirm password"
          />
          {/* <img src={LockIcon} alt="" /> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
          }}
          className="flex justify-end items-center gap-10"
        >
          <>
            <span
              className={`bas text-sm  ${!timerOn ? "cursor-pointer" : ""}`}
              onClick={() => {
                if (!timerOn) {
                  setTimerOn(true);
                  setTimerCount(180000);
                  reGetCode();
                  setResetPassword((state) => ({ ...state, code: "" }));
                  // formik.setFieldValue("code", "");
                }
              }}
            >
              Resend Code
            </span>
          </>

          {useMemo(
            () => (
              <TimerController />
            ),
            [timerOn]
          )}
        </div>
        {/* {loginError.emailError ? (
          <span className="error">{loginError.emailError}</span>
        ) : (
          <></>
        )} */}
      </div>

      {loginError.emailError ? (
        <span className="error">{loginError.emailError}</span>
      ) : (
        <></>
      )}
      {loginError.submitError ? (
        <span className="error">{loginError.submitError}</span>
      ) : (
        <></>
      )}

      <Button content="Reset password" lodaing={isLoading} />

      <div className="flex justify-end w-full text-center ">
        <Link
          style={{ color: "", textAlign: "right" }}
          className="width-100"
          to={"/auth/login"}
        >
          {" "}
          Back to sign in
        </Link>
      </div>
    </form>
  );
};

export default ResetPassword;
