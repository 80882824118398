import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const BusinessModel = (props: Props) => {
  const {
    businessModelInformation,
    setApplicationData,
    accessToken,
    id,
    appStatus,
  } = useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(businessModelInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          businessModelInformation: { ...requestDataDebounce },
        },
      });

    if (hasValue(businessModelInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Business Model</h1>

      <div className="form-controller text">
        <label className="required">Explain your revenue model :</label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              businessModelInformation: {
                ...businessModelInformation,
                revenueModel: event.target.value,
              },
            }));
          }}
          value={businessModelInformation?.revenueModel}
        ></textarea>
      </div>

      <div className="form-controller text">
        <label className="required">What is your Pricing Strategy? </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              businessModelInformation: {
                ...businessModelInformation,
                pricingStrategy: event.target.value,
              },
            }));
          }}
          value={businessModelInformation?.pricingStrategy}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          What are your preferred channels for customer acquisition?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              businessModelInformation: {
                ...businessModelInformation,
                customerAcquisitionChannels: event.target.value,
              },
            }));
          }}
          value={businessModelInformation?.customerAcquisitionChannels}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          What are your preferred distribution channels?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              businessModelInformation: {
                ...businessModelInformation,
                distributionChannels: event.target.value,
              },
            }));
          }}
          value={businessModelInformation?.distributionChannels}
        ></textarea>
      </div>
    </div>
  );
};

export default BusinessModel;
