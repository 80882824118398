import React, { useContext, useEffect, useState } from "react";
import trash from "../../images/trash.png";
import { biconomyContext } from "../../../../utils/Context";
import { validateEmail } from "../../../../utils/regex";
import { FormApplicationContext } from "../../CreateApplication";
const FounderForm = () => {
  const { setApplicationData, basicInformation: basicInfo } = useContext(
    FormApplicationContext
  );
  const { founders } = basicInfo;

  const { toast } = useContext(biconomyContext);
  const [tableState, setTableState] = useState({
    firstName: "",
    email: "",
    isPrimaryContact: founders?.length == 0,
  });

  const addNewFounder = () => {
    if (tableState.email && tableState.firstName) {
      if (!validateEmail(tableState.email)) {
        toast.error("Please add a valid email");
        return;
      }
      setApplicationData((prevState) => ({
        ...prevState,
        basicInformation: {
          ...prevState.basicInformation,
          founders: [
            ...prevState.basicInformation.founders, // Spread the existing array
            tableState, // Add the new introducer
          ],
        },
      }));
      // Clear the introducer fields after adding
      setTableState({
        firstName: "",
        email: "",
        isPrimaryContact: false,
      });
    }
  };
  const deleteFounder = (index) => {
    let updatedFounder = founders.map((founder) => ({ ...founder }));

    updatedFounder.splice(index, 1); // Remove introducer at the specified index
    if (founders[index].isPrimaryContact && updatedFounder.length !== 0) {
      updatedFounder[0] = { ...updatedFounder[0], isPrimaryContact: true };
    }
    if (updatedFounder.length == 0) {
      setTableState((state) => ({ ...state, isPrimaryContact: true }));
    }
    setApplicationData((prevState) => ({
      ...prevState,
      basicInformation: {
        ...prevState.basicInformation,
        founders: [...updatedFounder],
      },
    }));
  };

  const changeThePrimaryContact = (index) => {
    const updatedFounder = founders.map((f) => {
      let tempFounder = { ...f };
      tempFounder.isPrimaryContact = false;
      return tempFounder;
    });
    updatedFounder[index].isPrimaryContact = true;
    setApplicationData((prevState) => ({
      ...prevState,
      basicInformation: {
        ...prevState.basicInformation,
        founders: [...updatedFounder],
      },
    }));
  };

  return (
    <div className="form-table">
      <div className="form-table-header">
        <h4>
          Founder(s) / Owner(s)<span className="red">*</span>{" "}
        </h4>
        <button
          onClick={() => {
            addNewFounder();
          }}
          className="letsgo-btn"
        >
          Add
        </button>
      </div>
      <div className="form-table-items">
        <div className="table-item">Name</div>
        <div className="table-item">Email</div>
        <div className="table-item">Primary Point of Contact</div>
        <div className="table-item"></div>
      </div>
      <div className="form-table-items">
        <div className="table-item">
          <p className="mobile-table-item">Name: </p>

          <input
            onChange={(e) => {
              setTableState((state) => ({
                ...state,
                firstName: e.target.value,
              }));
            }}
            value={tableState.firstName}
            type="text"
            placeholder="enter name"
          />
        </div>
        <div className="table-item">
          <p className="mobile-table-item">email: </p>

          <input
            onChange={(e) => {
              setTableState((state) => ({
                ...state,
                email: e.target.value,
              }));
            }}
            onKeyDown={(e) => {
              console.log(e.code);

              if (e.code == "Enter") {
                addNewFounder();
              }
            }}
            value={tableState.email}
            placeholder="enter email"
            type="text"
          />
        </div>
        <div className="table-item"></div>
      </div>
      {founders?.map((f, index) => (
        <div className="form-table-items">
          <div className="table-item">
            <p className="mobile-table-item">Name: </p>
            <p>{f.firstName}</p>
          </div>
          <div className="table-item">
            <p className="mobile-table-item">Email:</p>
            <p>{f.email}</p>
          </div>
          <div className="table-item">
            <p className="mobile-table-item">Primary:</p>{" "}
            <input
              onChange={() => {
                changeThePrimaryContact(index);
              }}
              type="radio"
              name="isPrimary"
              value={f.isPrimaryContact}
              checked={f.isPrimaryContact}
            />{" "}
          </div>
          <div className="table-item">
            <p className="mobile-table-item">Remove:</p>{" "}
            <img
              onClick={() => {
                deleteFounder(index);
              }}
              src={trash}
              alt=""
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FounderForm;
