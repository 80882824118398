import React, { useContext, useEffect, useState } from "react";
import "./create-request.css";

import Sidebar from "../../components/Sidebar";
import Step1 from "./components/steps/Step1";
import Step2 from "./components/steps/Step2";
import Step3 from "./components/steps/Step3";
import Step4 from "./components/steps/Step4";
import Step5 from "./components/steps/Step5";
import EndStep from "./components/steps/EndStep";
import Review from "./components/steps/Review";
import { Toaster, toast } from "sonner";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { ApiEnum } from "../../types/enum.types";
import { useSelector } from "react-redux";
import ProgressBar from "../../components/progressbar";
import { useDebounce } from "usehooks-ts";
import {
  useSingleRequestQuery,
  useUpdateDraftFormMutation,
  useUpdateFormRequestMutation,
} from "../../store/api/requestApi";
import Loading from "../../components/Loading/Loading.animation";
import { validateEmail } from "../../utils/regex";
import { ethers } from "ethers";
import { CounterAbi } from "../../utils/ABI";
import { web3Provider } from "../../config/biconomyConig";
import { providerMumbai, providerPolygan } from "../../config/providerConfig";
import { biconomyContext } from "../../utils/Context";
import MintModal from "./components/Mint/MintModal";
import Wallet from "../../components/Wallet";

const CreateRequest = () => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const { address } = useContext(biconomyContext);
  const location = useLocation();
  const [mintModal, setMintModal] = useState({
    show: false,
  });

  const { id } = useParams();
  const { data, isFetching, isSuccess, refetch } = useSingleRequestQuery(
    {
      accessToken,
      requestid: id,
    },
    { skip: Boolean(!accessToken) }
  );

  const getCount = async () => {
    try {
      const contract = new ethers.Contract(
        "0x78f65d7ac2f5a21b793077734fa213bfd202e921",
        CounterAbi,
        providerMumbai
      );

      const currentCount = await contract.count();
      toast.message("the current count is " + currentCount);
    } catch (error) {
      console.log(error);
    }
  };

  const [
    updateForm,
    {
      isSuccess: isUpdateFormSuccess,
      isError,
      isLoading: isLoadingForUpdateForm,
    },
  ] = useUpdateFormRequestMutation();
  const [updateDraftForm, { isLoading: isLoadingForUpdateDraft }] =
    useUpdateDraftFormMutation();
  console.log("🚀 ~ file: index.tsx:30 ~ CreateRequest ~ data:", data);
  const [NFTData, setNFTData] = useState<any>();
  const [step, setStep] = useState<any>({
    form: 1,
    review: "false",
    end: "false",
  });

  console.log("🚀 ~ file: index.tsx:20 ~ CreateRequest ~ step:", step);
  const navigate = useNavigate();
  const [pitchDeck, setPitchDeck] = useState<any>();
  const [pitchDeckURL, setPitchDeckURL] = useState<any>();
  const [introduction, setIntroduction] = useState<any>();
  const [introductionURL, setIntroductionURL] = useState<any>();
  const [progressForm, setProgressForm] = useState();
  const [submitError, setSubmitError] = useState("");
  const [errorMesseg, setErrorMesseg] = useState({
    startupName: "",
    industry: "",
    founderName: "",
    email: "",
    foundingInformation: "",
    businessInformation: "",
    teamInformation: "",
  });
  const [requestData, setRequestData] = useState<any>({
    startupName: data?.startupName || "",
    industry: data?.industry || "0",
    founderName: {
      firstName: data?.founderName?.firstName || "",
      lastName: data?.founderName?.lastName || "",
    },
    ipId: data?.ipId || "",
    email: data?.email || "",
    mobile: data?.mobile || "",
    formAddress: {
      country: data?.formAddress?.country || "",
      city: data?.formAddress?.city || "",
    },
    formSocial: {
      instagram: data?.formSocial?.instagram || "",
      twitter: data?.formSocial?.twitter || "",
      linkedin: data?.formSocial?.linkedin || "",
      facebook: data?.formSocial?.facebook || "",
      website: data?.formSocial?.website || "",
    },
    appliedBefore: data?.appliedBefore,
    foundingInformation: {
      launchCompany: data?.foundingInformation?.launchCompany || false,
      decentralizedCompany:
        data?.foundingInformation?.decentralizedCompany || false,
      investIdea: data?.foundingInformation?.investIdea || false,
      howMuchInvested: data?.foundingInformation?.howMuchInvested || 0,
    },
    businessInformation: {
      explainBusiness: data?.businessInformation?.explainBusiness || "",
      explainTechnology: data?.businessInformation?.explainTechnology || "",
      targetMarket: data?.businessInformation?.targetMarket || "",
      marketSize: data?.businessInformation?.marketSize || 0,
      marketType: data?.businessInformation?.marketType || "BMT_NULL",
      businessModel: data?.businessInformation?.businessModel || "BM_NULL",
      otherModel: data?.businessInformation?.otherModel || "",
      revenueStreams: data?.businessInformation?.revenueStreams || "",
      blockchainMarketApp: data?.businessInformation?.blockchainMarketApp || "",
      competitors: data?.businessInformation?.competitors || "",
      ambitions: data?.businessInformation?.ambitions || "",
      pitchDeck: {
        id: data?.businessInformation?.pitchDeck?.id || "",
        contentType: data?.businessInformation?.pitchDeck?.contentType || "",
      },
      videoFile: {
        id: data?.businessInformation?.videoFile?.id || "",
        contentType: data?.businessInformation?.videoFile?.contentType || "",
      },
    },
    teamInformation: {
      haveTeam: data?.teamInformation?.haveTeam || false,
      introduceTeam: data?.teamInformation?.haveTeam || [],
      teamUniquelyQualified: data?.teamInformation?.teamUniquelyQualified || "",
      numOfDevelopers: data?.teamInformation?.numOfDevelopers || 0,
    },
    // {
    //   "name": "string",
    //   "position": "string",
    //   "qualification": "string"
    // }
  });

  const requestDataDebounce = useDebounce(requestData, 5000);
  const [shouldUpdateDraftForm, setShouldUpdateDraftForm] = useState(false);
  useEffect(() => {
    if (true) {
      console.log(
        "🚀 ~ file: index.tsx:130 ~ useEffect ~ shouldUpdateDraftForm:",
        shouldUpdateDraftForm
      );

      setRequestData({
        startupName: data?.startupName || "",
        industry: data?.industry || "sector",
        founderName: {
          firstName: data?.founderName?.firstName || "",
          lastName: data?.founderName?.lastName || "",
        },
        ipId: data?.ipId || "",
        email: data?.email || "",
        mobile: data?.mobile || "",
        formAddress: {
          country: data?.formAddress?.country || "",
          city: data?.formAddress?.city || "",
        },
        formSocial: {
          instagram: data?.formSocial?.instagram || "",
          twitter: data?.formSocial?.twitter || "",
          linkedin: data?.formSocial?.linkedin || "",
          facebook: data?.formSocial?.facebook || "",
          website: data?.formSocial?.website || "",
        },

        appliedBefore: data?.appliedBefore,
        foundingInformation: {
          launchCompany: data?.foundingInformation?.launchCompany || false,
          decentralizedCompany:
            data?.foundingInformation?.decentralizedCompany || false,
          investIdea: data?.foundingInformation?.investIdea || false,
          howMuchInvested: data?.foundingInformation?.howMuchInvested || 0,
        },
        businessInformation: {
          explainBusiness: data?.businessInformation?.explainBusiness || "",
          explainTechnology: data?.businessInformation?.explainTechnology || "",
          targetMarket: data?.businessInformation?.targetMarket || "",
          marketSize: data?.businessInformation?.marketSize || 0,
          marketType: data?.businessInformation?.marketType || "BMT_NULL",
          businessModel: data?.businessInformation?.businessModel || "BM_NULL",
          otherModel: data?.businessInformation?.otherModel || "",
          revenueStreams: data?.businessInformation?.revenueStreams || "",
          blockchainMarketApp:
            data?.businessInformation?.blockchainMarketApp || "",
          competitors: data?.businessInformation?.competitors || "",
          ambitions: data?.businessInformation?.ambitions || "",
          pitchDeck: {
            id: data?.businessInformation?.pitchDeck?.id || "",
            contentType:
              data?.businessInformation?.pitchDeck?.contentType || "",
          },
          videoFile: {
            id: data?.businessInformation?.videoFile?.id || "",
            contentType:
              data?.businessInformation?.videoFile?.contentType || "",
          },
        },
        teamInformation: {
          haveTeam: data?.teamInformation?.haveTeam || false,
          introduceTeam: data?.teamInformation?.introduceTeam || [],
          teamUniquelyQualified:
            data?.teamInformation?.teamUniquelyQualified || "",
          numOfDevelopers: data?.teamInformation?.numOfDevelopers || 0,
        },
      });
      if (data?.businessInformation?.pitchDeck?.id) {
        setPitchDeckURL(
          `${ApiEnum.STORAGE_API}/files?id=${data?.businessInformation?.pitchDeck?.id}`
        );
      }
      if (data?.businessInformation?.videoFile?.id) {
        setIntroductionURL(
          `${ApiEnum.STORAGE_API}/files?id=${data?.businessInformation?.videoFile?.id}`
        );
      }
      setShouldUpdateDraftForm(true);
    }
  }, [data]);

  useEffect(() => {
    if (shouldUpdateDraftForm && data?.status === "SFS_DRAFT") {
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: requestDataDebounce,
      });
    }
  }, [requestDataDebounce]);

  useEffect(() => {
    if (isUpdateFormSuccess) {
      navigate("/requests");
    }
  }, [isUpdateFormSuccess]);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const createForm = async (isDraft) => {
    if (isDraft) {
      requestData.draftId = id;
    }
    try {
      var config = {
        method: "POST",
        url: ApiEnum.CAPTAIN_API + `/captain/forms`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: requestData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted: any = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressForm(percentCompleted);
          setStep((prevState) => ({
            ...prevState,
            review: "isLoading",
          }));
        },
      };
      const response = await axios(config);
      console.log("response createForm:", response);
      setStep((prevState) => ({
        ...prevState,
        review: "success",
      }));
      toast.success("Submitted successfully");

      navigate("/requests");
    } catch (error) {
      console.error("Error createForm:", error);
      toast.error(error.message);
      setTimeout(() => {
        navigate("/requests");
      }, 1000);
    }
  };
  const handleFormValidation = (step) => {
    let isValid = true;
    if (step == 1) {
      if (requestData.startupName.trim().length == 0) {
        toast.error("Please Enter your StartUp Name");
        isValid = false;
      }
      if (
        requestData.founderName.firstName.trim().length == 0 ||
        requestData.founderName.lastName.trim().length == 0
      ) {
        toast.error("Please Enter Your Full Name");
        isValid = false;
      }

      if (requestData.email.trim().length == 0) {
        toast.error("Please Enter your Email");
        isValid = false;
      }
      if (requestData.email && !validateEmail(requestData.email.trim())) {
        toast.error("Please Enter a Valid Email");
        isValid = false;
      }

      if (
        isNaN(requestData.foundingInformation.howMuchInvested) ||
        String(requestData.foundingInformation.howMuchInvested).trim().length ==
          0
      ) {
        toast.error("How much capital has been raised so far?");
        isValid = false;
      }
    }
    if (step == 2) {
      if (requestData.businessInformation.explainBusiness.trim().length == 0) {
        toast.error("Explain what your business does in 1 sentence");
        isValid = false;
      }
      if (!requestData.businessInformation.explainTechnology) {
        toast.error("Explain what Technology description");
        isValid = false;
      }
      if (requestData.businessInformation.marketType == "BMT_NULL") {
        toast.error("Specify your market.");
        isValid = false;
      }
      if (requestData.businessInformation.businessModel == "BM_NULL") {
        toast.error("What's your business model? ");
        isValid = false;
      }
      if (
        requestData.businessInformation.businessModel == "BM_OTHER" &&
        requestData.businessInformation.otherModel.trim().length == 0
      ) {
        toast.error("What's your business model? ");
        isValid = false;
      }
      if (requestData.businessInformation.revenueStreams.trim().length == 0) {
        toast.error("What's your revenue streams?");
        isValid = false;
      }
      if (
        isNaN(requestData.businessInformation.marketSize) ||
        String(requestData.businessInformation.marketSize).trim().length == 0
      ) {
        toast.error("What's your estimated market size?");
        isValid = false;
      }
      if (
        requestData.businessInformation.blockchainMarketApp.trim().length == 0
      ) {
        toast.error(
          "How will blockchain help you address the market gap you identified?"
        );
        isValid = false;
      }
      if (requestData.businessInformation.competitors.trim().length == 0) {
        toast.error("Who are your top competitors?");
        isValid = false;
      }
      if (requestData.businessInformation.ambitions?.trim().length == 0) {
        toast.error(
          "What are your ambitions for where you'd like your startup to be in 3 years time?"
        );
        isValid = false;
      }
      if (requestData.businessInformation.targetMarket?.trim().length == 0) {
        toast.error("What's your target market(s)?");
        isValid = false;
      }
      if (!requestData.businessInformation.pitchDeck?.id) {
        toast.error("Please attach Pitch-deck file");
        isValid = false;
      }
      // if (!requestData.businessInformation.videoFile.id) {
      //   toast.error("Please attach a introduction video ");
      //   isValid = false;
      // }
    }

    if (step == 3) {
      if (
        requestData.teamInformation?.haveTeam &&
        requestData.teamInformation?.introduceTeam.length == 0
      ) {
        toast.error("Introduce your team.");
        isValid = false;
      }
      if (
        requestData.teamInformation?.teamUniquelyQualified?.trim().length == 0
      ) {
        toast.error("How are you and your team uniquely qualified to succeed?");
        isValid = false;
      }
      if (
        isNaN(requestData.teamInformation?.numOfDevelopers) ||
        String(requestData.teamInformation?.numOfDevelopers).trim().length == 0
      ) {
        toast.error("How many developers are in your team?");
        isValid = false;
      }
    }

    return isValid;
  };
  const handelNextStep = () => {
    if (!handleFormValidation(step.form)) {
      return;
    }
    if (step.form < 5) {
      setStep((prevState) => ({
        ...prevState,
        form: prevState.form + 1,
      }));
    }
    if (step.form === 3) {
      setStep((prevState) => ({
        ...prevState,
        review: "active",
      }));
    }
  };
  const handelSubmit = () => {
    if (data?.status === "SFS_UPDATE") {
      console.log(requestData);
      updateForm({ accessToken, requestId: data?.id, requestData });
      return;
    }
    // if (data?.status === "SFS_DRAFT") {

    //   return;
    // }
    createForm(data?.status === "SFS_DRAFT");
  };

  const handelBackStep = () => {
    if (step.form < 5 && step.form > 1) {
      setStep((prevState) => ({
        ...prevState,
        form: prevState.form - 1,
      }));
    }
    if (step.form === 1) {
      navigate(-1);
    }
    if (step.review === "active") {
      setStep((prevState) => ({
        ...prevState,
        review: "false",
      }));
    }
  };

  return (
    <>
      <Toaster closeButton richColors position="top-right" />
      <div className="profile-page">
        <Sidebar />
        <Wallet />
        {isLoadingForUpdateDraft ? <Loading /> : <></>}
        {isLoadingForUpdateForm ? <Loading /> : <></>}
        {mintModal.show && (
          <MintModal setMintModal={setMintModal} handelSubmit={handelSubmit} />
        )}
        <div className="insidePage">
          <form onSubmit={(e) => e.preventDefault()}>
            {step.form === 1 && (
              <>
                <Step1
                  requestData={requestData}
                  setRequestData={setRequestData}
                  setNFTData={setNFTData}
                  NFTData={NFTData}
                />
                <Step2
                  requestData={requestData}
                  setRequestData={setRequestData}
                />
              </>
            )}
            {step.form === 2 && (
              <Step3
                pitchDeck={pitchDeck}
                setPitchDeck={setPitchDeck}
                pitchDeckURL={pitchDeckURL}
                setPitchDeckURL={setPitchDeckURL}
                introduction={introduction}
                setIntroduction={setIntroduction}
                introductionURL={introductionURL}
                setIntroductionURL={setIntroductionURL}
                requestData={requestData}
                setRequestData={setRequestData}
              />
            )}
            {step.form === 3 && (
              <Step4
                requestData={requestData}
                setRequestData={setRequestData}
              />
            )}
            {step.review === "active" && (
              <Review
                pitchDeck={pitchDeck}
                pitchDeckURL={pitchDeckURL}
                introductionURL={introductionURL}
                introduction={introduction}
                requestData={requestData}
                NFTData={NFTData}
              />
            )}
            {step.review === "isLoading" && (
              <div className="insidePage">
                <div className="wrapper">
                  <ProgressBar
                    bgcolor={"linear-gradient(90deg, #4EC8FC 0%, #1577AE 100%)"}
                    completed={progressForm}
                  />
                </div>
              </div>
            )}
            {step.review === "success" && (
              <EndStep
                requestData={requestData}
                setRequestData={setRequestData}
              />
            )}
          </form>
        </div>
        {step.review !== "success" && (
          <div className="formCta-container">
            {/* <h1>;sfn;snf</h1> */}
            <div>
              <button className="formCta-back" onClick={() => handelBackStep()}>
                Back
              </button>

              {step.review === "false" && (
                <button
                  type="submit"
                  onClick={() => handelNextStep()}
                  className="formCta-next"
                >
                  Next
                </button>
              )}
              {step.review === "active" && (
                // <button onClick={() => handelSubmit()} className="formCta-next">
                //   Submit
                // </button>

                <>
                  {data?.status === "SFS_DRAFT" ? (
                    <button
                      onClick={() =>
                        setMintModal((state) => ({ ...state, show: true }))
                      }
                      className="formCta-next"
                    >
                      Mint & Submit
                    </button>
                  ) : (
                    <button
                      onClick={() => handelSubmit()}
                      className="formCta-next"
                    >
                      Submit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateRequest;
