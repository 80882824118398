import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { useDispatch } from "react-redux";
import { getReferralCode } from "../../store/logic/authSlice";

// import useAuthStore from "@/@core/global/store/auth-store";

const ReferralLogin = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  // const { getReferralCode, referralCode, logout, accessToken } = useAuthStore();
  const { referralId } = useParams();
  console.log(
    "🚀 ~ file: index.tsx:30 ~ ReferralLogin ~ referralId:",
    referralId
  );

  useEffect(() => {
    dispatch(getReferralCode(referralId));
    nav("/auth/login");
  }, []);

  return <></>;
};

export default ReferralLogin;
