import "./style.css";

import { useContext, useState } from "react";
import { biconomyContext } from "../../utils/Context";
import { toast } from "sonner";
import InfoModal from "./InfoModal";
import { walletIcon } from "../../assets/icons";
const Wallet = () => {
  const { address } = useContext(biconomyContext);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (state) => {
    setShowModal(state);
  };

  return (
    <>
      {/* <h1>{Date.now().toLocaleString()}</h1> */}
      <div
        className="wallet"
        style={{
          cursor: "pointer",
          zIndex: "1",
        }}
        onClick={() => toggleModal(true)}
      >
        <img className="wallet-icon" src={walletIcon} alt="" />
        <span>
          {address
            ? address.slice(0, 4) + "..." + address.slice(-4)
            : "creating wallet"}
        </span>
      </div>
      <InfoModal
        showModal={showModal}
        onClose={toggleModal}
        address={address}
      />
    </>
  );
};

export default Wallet;
