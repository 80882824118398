import { useState } from "react";
import QRCode from "react-qr-code";

function QR({ title, url }) {
  console.log("🚀 ~ file: Qrcode.tsx:5 ~ QR ~ url:", url);
  //   const [value, setValue] = useState(url);
  //   setValue(url);

  const [back, setBack] = useState<any>("#001500");
  const [fore, setFore] = useState<any>("#fff");
  const [size, setSize] = useState<any>(120);

  return (
    <div
      className="qrcode-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "25px",
      }}
    >
      {url && (
        <QRCode
          // title="GeeksForGeeks"
          value={url}
          bgColor={back}
          fgColor={fore}
          size={size === "" ? 0 : size}
        />
      )}
      <h5 style={{ color: "#FFF" }}> {title}</h5>
    </div>
  );
}

export default QR;
