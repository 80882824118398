import React, { useState } from "react";
import BTC from "../../../assets/images/BTC.png";
import Crypto from "./Crypto";
import { ChainEnum } from "../../../types/enum.types";
import { ChainNames } from "@biconomy/core-types";
import { useGetAllCryptoTokenDataQuery } from "../../../store/api/cryptoApi";

type Props = {};

const CryptoList = (props: Props) => {
  const { data } = useGetAllCryptoTokenDataQuery({});
  const [changeUsdtBlance, setChangeUsdtBlance] = useState(false);
  console.log("🚀 ~ file: CryptoList.tsx:12 ~ CryptoList ~ data:", data);

  return (
    <div className="wrapper">
      <div className="assets-box" style={{ display: "table", width: "100%" }}>
        <div className="head-info">
          <span>Asset Type</span>
          <span>Amount</span>
          <span>Transfer</span>
        </div>

        {data?.tokens?.map((token) => {
          if (token.chainId != "137") return <></>;

          return (
            <Crypto
              chain={"Polygon"}
              token={token}
              setChangeUsdtBlance={setChangeUsdtBlance}
              changeUsdtBlance={changeUsdtBlance}
            />
          );
        })}

        {/* <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"}
          contract="0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0"
          decimals={6}
          name="MATIC"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"}
          contract="0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
          decimals={6}
          name="USDT"
          chain={"Polygon"}
        />
        <Crypto
          image={
            "https://s2.coinmarketcap.com/static/img/coins/64x64/12845.png"
          }
          contract="0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683"
          decimals={18}
          name="SAND"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"}
          contract="0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39"
          decimals={18}
          name="LINK"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"}
          contract="0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6"
          decimals={8}
          name="WBTC"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"}
          contract="0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063"
          decimals={18}
          name="DAI"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"}
          contract="0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"
          decimals={6}
          name="USDC"
          chain={"Polygon"}
        />
        <Crypto
          image={"https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"}
          contract="0xD6DF932A45C0f255f85145f286eA0b292B21C90B"
          decimals={18}
          name="AAVE"
          chain={"Polygon"}
        /> */}
      </div>
    </div>
  );
};

export default CryptoList;
