import { DoneImage } from "../../../assets/images";

const PendingForActivation = () => {
  return (
    <div
      className=" "
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100vw",
      }}
    >
      <div
        className=" pendingforactivation "
        style={{ width: "90%", maxWidth: 576 }}
      >
        <div className="child both-center mb-2">
          <img src={DoneImage} width={160} />
          <span className="f-25 fw-b">Register successfully!</span>
        </div>
        <div className="child">
          <p className="gray f-14 mb-5 description">
            Please Check Your Email .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PendingForActivation;
