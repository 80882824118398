import React, { useState } from "react";
import { ApiEnum } from "../../../../../types/enum.types";
import { Toaster, toast } from "sonner";
import { useSelector } from "react-redux";
import axios from "axios";

type Props = {};

const IntroVideo = ({
  requestData,
  setRequestData,
  introduction,
  setIntroduction,
  introductionURL,
  setIntroductionURL,
}) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [progress, setProgress] = useState(0);
  const cancelToken = axios.CancelToken;
  const sourceCancel = cancelToken.source();

  const onUploadProfile = async (selectedFile: any, input: any, type: any) => {
    try {
      setProgress(0);
      const formdata: any = new FormData();
      formdata.append("file", selectedFile);
      var config = {
        method: "post",
        url: ApiEnum.STORAGE_API + `/files/upload`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-File-Access": 0,
        },
        data: formdata,
      };
      const response = await axios({
        ...config,
        cancelToken: sourceCancel.token,
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(uploadPercentage);
        },
      });
      // setRequestData(response);
      if (response?.status == 200) {
        if (input === "Introduction") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              videoFile: {
                ...prevState.businessInformation.videoFile,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
        if (input === "PitchDeck") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              pitchDeck: {
                ...prevState.businessInformation.pitchDeck,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
      }
      toast.success("file uploaded successfully");
      console.log("response onUploadProfile:", response);
    } catch (error) {
      console.error(error);
      toast.error("Error in Uploading file");
    }
  };
  return (
    <>
      <Toaster richColors position="top-right" />

      <div className="formControl">
        <label className="label2 ">
          Please attach a video introduction with the founders or any other
          supporting documents
        </label>
        <div className="box" style={{ position: "relative" }}>
          <div
            className="tfile"
            style={{
              overflow: "hidden",
              background: introduction?.files[0] && "none",
            }}
          >
            <input
              onChange={(event) => {
                setIntroduction(event.target);
                setIntroductionURL(
                  event?.target?.files[0]
                    ? URL.createObjectURL(event?.target?.files[0])
                    : null
                );
                onUploadProfile(
                  event.target.files[0],
                  "Introduction",
                  event.target.files[0].type
                );
              }}
              type="file"
              accept="video/*"
            />

            {!introductionURL &&
              requestData?.businessInformation?.videoFile?.id && (
                <video
                  style={{
                    maxHeight: " 100%",
                    position: "absolute",
                    left: "35%",
                  }}
                  src={`${ApiEnum.STORAGE_API}/files?id=${requestData?.businessInformation?.videoFile?.id}`}
                />
              )}
            {introduction?.files[0]?.type.startsWith("video") && (
              <video
                style={{
                  maxHeight: " 100%",
                  position: "absolute",
                  left: "35%",
                }}
                src={introductionURL}
              />
            )}
          </div>
          {/* <span>Not mandatory.</span> */}
          {true && (
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IntroVideo;
