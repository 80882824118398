import React, { useEffect, useState } from "react";

import "../auth.css";
// import EmailIcon from "../../../assets/icons/email.svg";
// import LockIcon from "../../../assets/icons/lock.svg";
// import EyeIcon from "../../assets/icons/eye.svg";
// import EyeOffIcon from "../../assets/icons/eye-off.svg";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ApiEnum, RouteEnum } from "../../../types/enum.types";
// import { AfterLogin } from "../../../utils/auth";
// import Button from "../../../components/Button/Button";
import SendEmail from "./components/SendEmail";
import ResetPassword from "./components/ResetPassword";
import { IPCaptainLogo } from "../../../assets/images";

interface ForgotStep {
  fotgotStep: "send" | "reset";
}

const ForgotPassword = () => {
  const [step, setStep] = useState<ForgotStep>({ fotgotStep: "send" });
  const [email, setEmail] = useState("");
  const stepController = () => {
    switch (step.fotgotStep) {
      case "send":
        return <SendEmail setResetEmail={setEmail} setStep={setStep} />;

      case "reset":
        return <ResetPassword email={email} />;

      default:
        return <></>;
        break;
    }
  };
  return (
    <div className="auth">
      <div className="auth-header ">
        <img src={IPCaptainLogo} alt="" />
        {/* <h1 className="heading-l">Forgot Password?</h1> */}
      </div>
      <div className="auth-form flex justify-center items-center">
        {stepController()}
      </div>
    </div>
  );
};

export default ForgotPassword;
