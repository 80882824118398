import React, { useState } from "react";
import Status from "./Status";
import SingleApplicationModal from "./SingleApplicationModal";
import ToolTibHover from "../../../components/ToolTip/ToolTibHover";
import { EventType } from "../../../types/enum.types";

type Props = {};

const SingleApplication = ({ application, refetch, isAuditore }) => {
  const [modalState, setModalState] = useState({
    show: false,
  });
  const returnTruncate = (string, count) => {
    return string?.length > count ? string?.slice(0, count) + "..." : string;
  };
  return (
    <>
      {modalState.show && (
        <SingleApplicationModal
          application={application}
          setModalState={setModalState}
          refetch={refetch}
          isAuditore={isAuditore}
        />
      )}
      <div className={`application-item ${application.status}`}>
        <ToolTibHover
          tooltipText={application?.basicInformation?.startupName}
          showTooltip={application?.basicInformation?.startupName > 20}
        >
          <>{returnTruncate(application?.basicInformation?.startupName, 20)}</>
        </ToolTibHover>
        <ToolTibHover
          tooltipText={application?.marketInformation?.businessModel}
          showTooltip={application?.marketInformation?.businessModel > 20}
        >
          <>
            {returnTruncate(application?.marketInformation?.businessModel, 20)}
          </>
        </ToolTibHover>
        <Status
          id={application?.id}
          status={application?.status}
          statusReason={application?.statusReason}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            position: "relative",
          }}
        >
          {/* { && (
            <>
              <button
                style={{
                  fontSize: "60%",
                }}
                className={application?.eventType}
              >
                challenge
              </button>
            </>
          )} */}
          <button
            onClick={() => {
              setModalState((state) => ({ ...state, show: true }));
              console.log(modalState.show);
            }}
            className={application?.eventType}
          >
            Detail{" "}
            {application?.eventType === EventType.FUM_EVENT && (
              <span style={{}}>(BIC-2024)</span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SingleApplication;
