import React, { useEffect } from "react";
import Spinner from "../Spinner/Spinner";

type Props = {
  lodaing: boolean;
  content: string;
};

const Button = ({ lodaing, content }: Props) => {
  return (
    <>
      <button
        className={`login-btn flex items-center justify-center relative ${
          lodaing ? "opacity-70" : ""
        } `}
        type="submit"
      >
        {lodaing ? <Spinner /> : content}
      </button>
    </>
  );
};

export default Button;
