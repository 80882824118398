import React, { useState } from "react";

const Review = ({
  requestData,
  pitchDeckURL,
  introductionURL,
  pitchDeck,
  introduction,
  NFTData,
}) => {
  const [stepReviewCollapse, setStepReviewCollapse] = useState({
    step1: true,
    step2: true,
    step3: true,
  });
  return (
    <div className="wrapper">
      <h1 className="title">Review</h1>

      <div className="review-container">
        <ul>
          <li>
            <input
              type="checkbox"
              checked={stepReviewCollapse.step1}
              onChange={() => {
                console.log("sldfjngjlasnfgjlnsjlfng");
                setStepReviewCollapse((state) => ({
                  ...state,
                  step1: !stepReviewCollapse.step1,
                }));
              }}
            />
            <i></i>
            <span className="title">Step 1:General Information</span>
            <div className="info-review ">
              <div>
                <span className="required caption">Startup or Idea Name</span>
                <div className="value">{requestData?.startupName}</div>
              </div>
              <div>
                <span className="required caption">Sector / Industry</span>
                <div className="value">{requestData?.industry}</div>
              </div>
              <div>
                <span className="required caption">
                  Founder or Owner Full Name
                </span>
                <div className="value">
                  {requestData?.founderName.firstName}-
                  {requestData?.founderName.lastName}
                </div>
              </div>
              <div>
                <span className=" caption">Your IP</span>
                <div className="value">{NFTData?.name}</div>
              </div>
              <div>
                <span className="required caption">Email</span>
                <div className="value"> {requestData?.email}</div>
              </div>
              <div>
                <span className=" caption">Phone Number</span>
                <div className="value"> {requestData?.mobile}</div>
              </div>
              <div>
                <span className="caption">Address</span>
                <div className="value">
                  {requestData?.formAddress.country}-
                  {requestData?.formAddress.city}
                </div>
              </div>
              <div>
                <span className=" caption">Website</span>
                <div className="value">{requestData?.formSocial.website}</div>
              </div>
              <div>
                <span className=" caption">Have you applied before?</span>
                <div className="value">
                  {" "}
                  {requestData?.appliedBefore ? "yes" : "no"}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Do you want to launch company?
                </span>
                <div className="value">
                  {requestData?.foundingInformation.launchCompany
                    ? "yes"
                    : "no"}{" "}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Do you want to decentralize the company?
                </span>
                <div className="value">
                  {" "}
                  {requestData?.foundingInformation.decentralizedCompany
                    ? "yes"
                    : "no"}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Do you decide to invest in your idea?
                </span>
                <div className="value">
                  {" "}
                  {requestData?.foundingInformation.investIdea ? "yes" : "no"}
                </div>
              </div>
              <div>
                <span className=" caption">
                  How much capital has been invested in your startup so far?
                </span>
                <div className="value">
                  {requestData?.foundingInformation.howMuchInvested} USD
                </div>
              </div>
            </div>
          </li>
          <li>
            <input
              type="checkbox"
              checked={stepReviewCollapse.step2}
              onChange={() => {
                console.log("sldfjngjlasnfgjlnsjlfng");
                setStepReviewCollapse((state) => ({
                  ...state,
                  step2: !stepReviewCollapse.step2,
                }));
              }}
            />
            <i></i>
            <span className="title">Step 2: Business Information</span>
            <div className="info-review ">
              <div>
                <span className="required caption">
                  Explain your business/idea in 1 paragraph?
                </span>
                <div className="value">
                  {" "}
                  {requestData.businessInformation.explainBusiness}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Explain required technologies
                </span>
                <div className="value">
                  {requestData.businessInformation.explainTechnology}
                </div>
              </div>
              <div>
                <span className="required caption">
                  What's your target market(s)?{" "}
                </span>
                <div className="value">
                  {requestData.businessInformation.targetMarket}
                </div>
              </div>
              <div>
                <span className="required caption">
                  What's your estimated market size?
                </span>
                <div className="value">
                  {" "}
                  {requestData.businessInformation.marketSize} USD
                </div>
              </div>
              <div>
                <span className="required caption">Specify your market </span>
                <div className="value">
                  {requestData.businessInformation.marketType}{" "}
                </div>
              </div>
              <div>
                <span className="required caption">
                  What's your business model?{" "}
                </span>
                <div className="value">
                  {requestData.businessInformation.businessModel}
                </div>
              </div>
              <div>
                <span className="required caption">
                  What's your revenue streams?{" "}
                </span>
                <div className="value">
                  {" "}
                  {requestData.businessInformation.revenueStreams}
                </div>
              </div>
              <div>
                <span className="required caption">
                  How will blockchain help you address the market gap you
                  identified?
                </span>
                <div className="value">
                  {requestData.businessInformation.blockchainMarketApp}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Who are your top competitors?
                </span>
                <div className="value">
                  {requestData.businessInformation.competitors}
                </div>
              </div>
              <div>
                <span className="required caption">
                  What are your ambitions for where you'd like your startup to
                  be in 3 years time?
                </span>
                <div className="value">
                  {requestData.businessInformation.ambitions}
                </div>
              </div>
              <div>
                <span className="required caption">
                  Pitch-deck (High Importance!)
                </span>
                <div
                  className="files"
                  style={{
                    overflow: "hidden",
                    height: "85px",
                    position: "relative",
                  }}
                >
                  {pitchDeck?.files[0]?.type == "application/pdf" && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          zIndex: "1",
                        }}
                      ></div>
                      <iframe
                        src={`${pitchDeckURL}#view=fitH&toolbar=0`}
                        frameBorder="0"
                        height="110%"
                        width="120%"
                        style={{ position: "absolute", left: "-5px" }}
                      ></iframe>
                    </>
                  )}

                  {pitchDeck?.files[0]?.type.startsWith("image") && (
                    <img
                      style={{
                        maxHeight: " 100%",
                        position: "absolute",
                        left: "40%",
                      }}
                      src={pitchDeckURL}
                    />
                  )}
                </div>
              </div>
              <div>
                <span className=" caption">
                  Please attach a video introduction with the founders or any
                  other supporting documents
                </span>
                <div
                  className="files"
                  style={{
                    overflow: "hidden",
                    height: "85px",
                    position: "relative",
                  }}
                >
                  {introduction?.files[0]?.type.startsWith("video") && (
                    <video
                      style={{
                        maxHeight: " 100%",
                        position: "absolute",
                        left: "35%",
                      }}
                      src={introductionURL}
                    />
                  )}
                </div>
              </div>
            </div>
          </li>
          <li>
            <input
              type="checkbox"
              checked={stepReviewCollapse.step3}
              onChange={() => {
                console.log("sldfjngjlasnfgjlnsjlfng");
                setStepReviewCollapse((state) => ({
                  ...state,
                  step3: !stepReviewCollapse.step3,
                }));
              }}
            />
            <i></i>
            <span className="title">Step 3: Team Information</span>
            <div className="info-review ">
              <div>
                <span className="required caption">Do you have a team?</span>
                <div className="value">
                  {" "}
                  {requestData.teamInformation.haveTeam ? "yes" : "no"}
                </div>
              </div>
              <div>
                <span className="required caption w-100">
                  Introduce your team (Max 15)
                </span>
                <ul className="teams">
                  {requestData.teamInformation.introduceTeam?.map(
                    (introducer, i) => (
                      <li>
                        <span>{introducer.name}</span>
                        <span>{introducer.position}</span>
                        <span>{introducer.qualification}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div>
                <span className="required caption">
                  How are you and your team uniquely qualified to succeed?
                </span>
                <div className="value">
                  {" "}
                  {requestData.teamInformation.teamUniquelyQualified}
                </div>
              </div>
              <div>
                <span className="required caption">
                  How many developers are in your team?
                </span>
                <div className="value">
                  {" "}
                  {requestData.teamInformation.numOfDevelopers}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Review;
