import React, { useEffect, useState } from "react";
import { ApiEnum } from "../../types/enum.types";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useChangeStatusFormMutation,
  useChangeStatusToUnderReviewMutation,
  useSingleRequestQuery,
} from "../../store/api/requestApi";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner/Spinner";
import Loading from "../../components/Loading/Loading.animation";
import Toast from "../../components/Toast/Toast";
import AuditorActionModal from "./components/AuditorActionModal";
import { useSingleIpQuery } from "../../store/api/ipApi";

interface modalSet {
  showModal: Boolean;
  action: null | Function;
  type:
    | "SFS_PENDING"
    | "SFS_ACCEPTED"
    | "SFS_REJECTED"
    | "SFS_UPDATE"
    | "SFS_UNDER_REVIEW"
    | "SFS_DRAFT";
  message: string | null;
}

const ApplicationReview = () => {
  const trlOptions = [
    "0",
    "TRL1 - Basic principles observed",
    "TRL2 - Technology concept formulated",
    "TRL3 - Experimental proof of concept",
    "TRL4 - Technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)",
    "TRL5 - Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)",
    "TRL6 - System prototype demonstration in operational environment",
    "TRL7 - System prototype demonstration in operational environment",
    "TRL8 - System complete and qualified",
    "TRL9 - Actual system proven in operational environment (competitive manufacturing in the case of key enabling technologies; or in space)",
  ];
  const [stepReviewCollapse, setStepReviewCollapse] = useState({
    step1: true,
    step2: true,
    step3: true,
    step4: true,
    step5: true,
    step6: true,
    step7: true,
    step8: true,
  });
  const location = useLocation();
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  var decoded: any = jwt_decode(`${accessToken}`);
  const [modalState, setModalState] = useState<modalSet>({
    showModal: false,
    action: null,
    type: "SFS_ACCEPTED",
    message: null,
  });

  const navigate = useNavigate();
  const { requestid } = useParams();

  const {
    data: requestData,
    isFetching,
    refetch,
  } = useSingleRequestQuery(
    {
      accessToken,
      requestid,
      isAuditor: !!decoded?.roles?.includes("ipcaptain.auditor"),
    },
    { skip: Boolean(!accessToken) }
  );

  const [changeStatusToUnderReview, { isError, isLoading }] =
    useChangeStatusToUnderReviewMutation();

  const [
    changeStaus,
    { isLoading: isStatusLoading, isSuccess: isStatusChanged },
  ] = useChangeStatusFormMutation();
  const handleStatusChange = async (status, reason) => {
    changeStaus({ accessToken, requestid, status, reason });
  };

  useEffect(() => {
    if (
      requestData?.status == "SFS_PENDING" &&
      decoded?.roles?.includes("ipcaptain.auditor")
    ) {
      changeStatusToUnderReview({ accessToken, requestid });
      refetch();
    }
  }, [requestData?.status]);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  return (
    <div className="profile-page">
      <Sidebar />
      {/* {isError && <Toast message="Somthing went wrong" type="warning" />} */}
      {modalState.showModal && (
        <AuditorActionModal
          type={modalState.type}
          action={modalState.action}
          setModalState={setModalState}
          message={modalState.message}
          isStatusChanged={isStatusChanged}
        />
      )}
      <div className="insidePage">
        {isFetching ? <Loading /> : <></>}
        {isStatusLoading ? <Loading /> : <></>}
        <div className="wrapper">
          <h1 className="title">Preview</h1>
          <div className="review-container">
            <ul>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step1}
                  onChange={() => {
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step1: !stepReviewCollapse.step1,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Basic Information</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">Startup or Idea Name</span>
                    <div className="value">
                      {requestData?.basicInformation?.startupName}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">Abstract</span>
                    <div className="value">
                      {requestData?.basicInformation?.abstract}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">Founder(s) / Owner(s) </span>
                    {requestData?.basicInformation?.founders && (
                      <ul className="teams">
                        {requestData?.basicInformation?.founders?.map(
                          (val, i) => (
                            <li>
                              <span>
                                {val?.firstName}{" "}
                                {val.isPrimaryContact ? "(Primary POC)" : ""}
                              </span>
                              <span>{val?.email}</span>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                  <div>
                    <span className=" caption">
                      How much capital has been raised until now?
                    </span>
                    <div className="value">
                      {requestData?.basicInformation?.raisedCapital}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      How much investment you are requesting?
                    </span>
                    <div className="value">
                      {requestData?.basicInformation?.requestedInvestment}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Specify the TRL of your business / idea
                    </span>
                    <div className="value">
                      {requestData?.basicInformation?.trl &&
                        trlOptions[requestData?.basicInformation?.trl]}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step2}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step2: !stepReviewCollapse.step2,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Product / Service Information</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      Describe your product / service:{" "}
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.productInformation?.description}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What problems does your product / service solve?{" "}
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.productInformation?.problems}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Key Features and Unique Selling Points:{" "}
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.productInformation?.keyFeatures}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step3}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step3: !stepReviewCollapse.step3,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Market and Competition</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      What is your target market: (Industry, Demographics,
                      Geographical Focus)
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.marketInformation?.targetMarket}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What is your estimated market size
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.marketInformation?.estimatedMarketSize}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Specify your business model
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.marketInformation?.businessModel}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Who are your top competitors?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.marketInformation?.topCompetitors}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What are your top competitive advantages?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.marketInformation?.competitiveAdvantages}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step4}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step4: !stepReviewCollapse.step4,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Business Model</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      Explain your revenue model:
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.businessModelInformation?.revenueModel}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What is your Pricing Strategy?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.businessModelInformation?.pricingStrategy}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What are your preferred channels for customer acquisition?
                    </span>
                    <div className="value">
                      {" "}
                      {
                        requestData?.businessModelInformation
                          ?.customerAcquisitionChannels
                      }
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What your preferred distribution channels?
                    </span>
                    <div className="value">
                      {" "}
                      {
                        requestData?.businessModelInformation
                          ?.distributionChannels
                      }
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step5}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step5: !stepReviewCollapse.step5,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Financial Information</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      What is your most recent annual turnover?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.financialInformation?.recentAnnualTurnover}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What is your average annual turnover in last 5 years?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.financialInformation?.averageAnnualTurnover}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What is your total revenue, year to date?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.financialInformation?.totalRevenue}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What was your projected revenue for current year?
                    </span>
                    <div className="value">
                      {" "}
                      {
                        requestData?.financialInformation
                          ?.currentYearProjectedRevenue
                      }
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What is your projected revenue for next year?
                    </span>
                    <div className="value">
                      {" "}
                      {
                        requestData?.financialInformation
                          ?.nextYearProjectedRevenue
                      }
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step6}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step6: !stepReviewCollapse.step6,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Traction</span>
                <div className="info-review ">
                  {/* <div>
                    <span className=" caption">
                      Your turnover in most recent fiscal year?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.tractionInformation?.turnoverRecentFiscal}
                    </div>
                  </div> */}
                  <div>
                    <span className=" caption">
                      Identify your current users/ customers
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.tractionInformation?.currentUsers}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Explain your current revenue stream
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.tractionInformation?.currentRevenueStream}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Explain if you have any user feedback or testimonials?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.tractionInformation?.userFeedback}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step8}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step8: !stepReviewCollapse.step8,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Risks and Challenges</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      What are the main challenges your startup currently faces?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.riskInformation?.mainChallenges}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      How do you plan to mitigate these challenges?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.riskInformation?.mitigatePlan}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={stepReviewCollapse.step7}
                  onChange={() => {
                    console.log("sldfjngjlasnfgjlnsjlfng");
                    setStepReviewCollapse((state) => ({
                      ...state,
                      step7: !stepReviewCollapse.step7,
                    }));
                  }}
                />
                <i></i>
                <span className="title">Other Information</span>
                <div className="info-review ">
                  <div>
                    <span className=" caption">
                      Do you have a business plan? if yes, you can upload it
                      here for our review.
                    </span>
                    <div className="value">
                      {" "}
                      {}
                      {requestData?.otherInformation?.businessPlanFileId && (
                        <a
                          className="upload_view"
                          target="_blank"
                          href={`${ApiEnum.STORAGE_API}/files?id=${requestData?.otherInformation?.businessPlanFileId}#view=fitH&toolbar=`}
                        >
                          View File
                        </a>
                      )}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      What specific goals do you hope to achieve by
                      participating in this accelerator program?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.otherInformation?.specificGoals}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      How do you envision the accelerator assisting in your
                      startup’s growth?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.otherInformation?.envisionAccelerator}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Any specific mentorship or resources you are seeking?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.otherInformation?.specificMentorship}
                    </div>
                  </div>
                  <div>
                    <span className=" caption">
                      Anything else you would like to share or highlight about
                      your startup?
                    </span>
                    <div className="value">
                      {" "}
                      {requestData?.otherInformation?.extra}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {decoded?.roles?.includes("ipcaptain.auditor") &&
          (requestData?.status === "SFS_UNDER_REVIEW" ||
            requestData?.status === "SFS_PENDING") ? (
            <>
              <div className="action-btns">
                <button
                  onClick={() => {
                    setModalState((state) => ({
                      ...state,
                      action: handleStatusChange,
                      showModal: true,
                      type: "SFS_UPDATE",
                      message: null,
                    }));
                  }}
                  className="update-btn "
                >
                  RFI / Need for Revise
                </button>
                <button
                  onClick={() => {
                    setModalState((state) => ({
                      ...state,
                      action: handleStatusChange,
                      showModal: true,
                      type: "SFS_REJECTED",
                      message:
                        "Thank you for sending your request. We have reviewed your application and unfortunately it did not meet our expectation. You can submit a new request if you want.",
                    }));
                  }}
                  className="reject-btn"
                >
                  Reject Application
                </button>
                <button
                  onClick={() => {
                    setModalState((state) => ({
                      ...state,
                      action: handleStatusChange,
                      showModal: true,
                      type: "SFS_ACCEPTED",
                      message:
                        "Thank you for sending your request in IP-Captain. We have reviewed your application and accepted your request. Please keep this message and our team will contact you to proceed with next steps.",
                    }));
                  }}
                  className=" accept-btn "
                >
                  Accept Application
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
          {!decoded?.roles?.includes("ipcaptain.auditor") && (
            <div
              className="action-btns"
              style={{
                justifyContent: "center",
              }}
            >
              <>
                {" "}
                <button
                  onClick={() => {
                    navigate("/requests");
                  }}
                  className=" back-btn "
                >
                  My Applications{" "}
                </button>
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationReview;
