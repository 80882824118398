import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const Traction = (props: Props) => {
  const {
    tractionInformation,
    setApplicationData,
    accessToken,
    id,
    appStatus,
  } = useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(tractionInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          tractionInformation: { ...requestDataDebounce },
        },
      });
    if (hasValue(tractionInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Traction</h1>
      {/* <div className="form-controller shortinput usd">
        <label className="required">
          Your turnover in most recent fiscal year?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              tractionInformation: {
                ...tractionInformation,
                turnoverRecentFiscal: parseInt(event.target.value),
              },
            }));
          }}
          value={tractionInformation?.turnoverRecentFiscal}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div> */}
      <div className="form-controller text">
        <label className="required">
          Identify your current users/ customers:{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              tractionInformation: {
                ...tractionInformation,
                currentUsers: event.target.value,
              },
            }));
          }}
          value={tractionInformation?.currentUsers}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          Explain your current revenue stream:{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              tractionInformation: {
                ...tractionInformation,
                currentRevenueStream: event.target.value,
              },
            }));
          }}
          value={tractionInformation?.currentRevenueStream}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          Explain if you have any user feedback or testimonials?{" "}
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              tractionInformation: {
                ...tractionInformation,
                userFeedback: event.target.value,
              },
            }));
          }}
          value={tractionInformation?.userFeedback}
        ></textarea>
      </div>
    </div>
  );
};

export default Traction;
