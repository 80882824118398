import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthEnum } from "../../types/enum.types";
import { BiconomySmartAccountV2 } from "@biconomy/account";

interface ISmartAccount {
  wallet;
}

const initialState = {
  wallet: {
    matic: 0,
    usdt: 0,
  },
  address: null,
};

const walletSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setWalletMatic(state, actions) {
      state.wallet.matic = actions.payload;
    },
    setWalletUsdt(state, actions) {
      state.wallet.usdt = actions.payload;
    },
    setWalletAddress(state, actions) {
      state.address = actions.payload;
    },
    setProvider(state, actions) {
      state.wallet = actions.payload;
    },
  },
});

export const { setWalletMatic, setWalletUsdt, setProvider, setWalletAddress } =
  walletSlice.actions;
export default walletSlice.reducer;
