import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const Finance = (props: Props) => {
  const {
    financialInformation,
    setApplicationData,
    accessToken,
    id,
    appStatus,
  } = useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(financialInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    console.log(financialInformation);
  }, [financialInformation]);
  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          financialInformation: { ...requestDataDebounce },
        },
      });
    if (hasValue(financialInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Financial Information</h1>

      <div className="form-controller shortinput usd">
        <label className="required">
          What is your most recent annual turnover?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              financialInformation: {
                ...financialInformation,
                recentAnnualTurnover: event.target.value + "",
              },
            }));
          }}
          value={financialInformation?.recentAnnualTurnover}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">
          What is your average annual turnover in last 5 years?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              financialInformation: {
                ...financialInformation,
                averageAnnualTurnover: event.target.value + "",
              },
            }));
          }}
          value={financialInformation?.averageAnnualTurnover}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">
          What is your total revenue, year to date?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              financialInformation: {
                ...financialInformation,
                totalRevenue: event.target.value + "",
              },
            }));
          }}
          value={financialInformation?.totalRevenue}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">
          What was your projected revenue for current year?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              financialInformation: {
                ...financialInformation,
                currentYearProjectedRevenue: event.target.value + "",
              },
            }));
          }}
          value={financialInformation?.currentYearProjectedRevenue}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">
          What is your projected revenue for next year?{" "}
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              financialInformation: {
                ...financialInformation,
                nextYearProjectedRevenue: event.target.value + "",
              },
            }));
          }}
          value={financialInformation?.nextYearProjectedRevenue}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
    </div>
  );
};

export default Finance;
