import { Contract, ethers } from "ethers";
import React, { useContext, useState } from "react";
import { ApiEnum, Contracts } from "../../../../types/enum.types";
import { ERC20_ABI } from "@biconomy/modules";
import { providerPolygan } from "../../../../config/providerConfig";
import { DiscountAbi, SafeMintAbi, usdtAbi } from "../../../../utils/ABI";
import minterAbi from "./IPMinterABI.json";
import { biconomyContext } from "../../../../utils/Context";
import {
  IHybridPaymaster,
  PaymasterFeeQuote,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";

import { parseEther } from "ethers/lib/utils";
import Spinner from "../../../../components/Spinner/Spinner";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { async } from "q";

type Props = {
  value: any;
  hasMoney: boolean;
  discountId: string;
};

const ApllicationMint = ({ value, hasMoney, discountId }: Props) => {
  console.log(
    "🚀 ~ file: ApllicationMint.tsx:29 ~ ApllicationMint ~ hasMoney:",
    hasMoney
  );
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const { id } = useParams();
  const { smartAccount, address, particle } = useContext(biconomyContext);

  const mint = async () => {
    setIsloading(true);

    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-bor.publicnode.com",
        "any"
      );
      const usdtContract = new ethers.Contract(
        Contracts.USDT,
        ERC20_ABI,
        provider
      );
      toast.message("creating transaction");
      const balance = await usdtContract.balanceOf(address);

      console.log(id, balance, value, discountId);
      console.log(ethers.utils.formatUnits(balance, 6));

      if (
        parseFloat(ethers.utils.formatUnits(balance, 6)) < parseFloat(value)
      ) {
        toast.error("Insufficient Balance");
        setIsloading(false);
        return;
      }

      const discountContract = new ethers.Contract(
        Contracts.DISCOUNT,
        DiscountAbi,
        provider
      );
      const mintContract = new ethers.Contract(
        Contracts.SAFE_MINT,
        SafeMintAbi,
        provider
      );
      const usdtData = await usdtContract.populateTransaction.approve(
        Contracts.SAFE_MINT,
        ethers.utils.parseEther(value)
      );
      const discountData =
        await discountContract.populateTransaction.setApprovalForAll(
          Contracts.SAFE_MINT,
          true
        );

      const mintData = await mintContract.populateTransaction.safeMint(
        discountId,
        `${ApiEnum.CAPTAIN_API}/metadata/${id}`,
        `0x${id}`
      );

      const usdt = {
        to: Contracts.USDT,
        data: usdtData.data,
      };
      const tx4 = {
        to: Contracts.DISCOUNT,
        data: discountData.data,
      };
      const tx1 = {
        to: Contracts.SAFE_MINT,
        data: mintData.data,
      };

      let partialUserOp2 = await smartAccount.buildUserOp([usdt, tx4, tx1]);

      toast.message("bundling transactions");
      const biconomyPaymaster =
        smartAccount.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      // const feeQuotesResponse =
      //   await biconomyPaymaster.getPaymasterFeeQuotesOrData(partialUserOp2, {
      //     mode: PaymasterMode.ERC20,
      //     tokenList: ["0xc2132d05d31c914a87c6611c10748aeb04b58e8f"],
      //   });

      // const feeQuotes = feeQuotesResponse.feeQuotes as PaymasterFeeQuote[];
      // const spender = feeQuotesResponse.tokenPaymasterAddress || "";
      // const usdtFeeQuotes = feeQuotes[0];
      // const gasFee = usdtFeeQuotes?.maxGasFeeUSD;
      // console.log(
      //   "🚀 ~ file: ApllicationMint.tsx:121 ~ mint ~ gasFee:",
      //   gasFee
      // );

      let paymasterServiceData: SponsorUserOperationDto = {
        mode: PaymasterMode.SPONSORED,
        smartAccountInfo: {
          name: "BICONOMY",
          version: "2.0.0",
        },
        // optional params...
        calculateGasLimits: true,
      };

      try {
        const paymasterAndDataResponse =
          await biconomyPaymaster.getPaymasterAndData(
            partialUserOp2,
            paymasterServiceData
          );
        partialUserOp2.paymasterAndData =
          paymasterAndDataResponse.paymasterAndData;

        if (
          paymasterAndDataResponse.callGasLimit &&
          paymasterAndDataResponse.verificationGasLimit &&
          paymasterAndDataResponse.preVerificationGas
        ) {
          // Returned gas limits must be replaced in your op as you update paymasterAndData.
          // Because these are the limits paymaster service signed on to generate paymasterAndData
          // If you receive AA34 error check here..

          partialUserOp2.callGasLimit = paymasterAndDataResponse.callGasLimit;
          partialUserOp2.verificationGasLimit =
            paymasterAndDataResponse.verificationGasLimit;
          partialUserOp2.preVerificationGas =
            paymasterAndDataResponse.preVerificationGas;
        }
      } catch (e) {
        console.log("error received ", e);
      }

      try {
        // const paymasterAndDataResponse =
        //   await biconomyPaymaster.getPaymasterAndData(
        //     partialUserOp2,
        //     paymasterServiceData
        //   );
        // partialUserOp2.paymasterAndData =
        //   paymasterAndDataResponse.paymasterAndData;

        toast.message("sending transactions on blockchain");
        const userOpResponse = await smartAccount.sendUserOp(partialUserOp2);

        const transactionDetails = await userOpResponse.wait();
        if (transactionDetails?.success == "true") {
          toast.success("minted successfuly");
          setIsloading(false);
        } else {
          console.log("failed");
          toast.error("Transaction Failed");
          setIsloading(false);
        }

        console.log("Transaction Details:", transactionDetails);
        console.log("Transaction Hash:", userOpResponse.userOpHash);
        // setIsloading(true);
        navigate("/requests");
      } catch (e) {
        setIsloading(false);

        toast.error("Error executing transaction:", e);
        console.error("Error executing transaction:", e);
      }
    } catch (error) {
      console.log(error);
      toast.error("Network Error");
      setIsloading(false);
    }
  };

  return (
    <>
      <button
        className={`apllication-mint-btn ${hasMoney ? "btn-disable" : ""}`}
        onClick={async () => {
          if (hasMoney) return;
          if (isLoading) return;
          if (!particle?.auth?.isLogin()) {
            navigate("/auth/login");
            return;
          } else {
            mint();
          }
        }}
      >
        {isLoading ? <Spinner /> : "Start Mint"}
      </button>
    </>
  );
};

export default ApllicationMint;
