import { ethers } from "ethers";

export const providerMumbai = new ethers.providers.JsonRpcProvider(
  "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78",
  "any"
);
export const providerPolygan = new ethers.providers.JsonRpcProvider(
  "https://endpoints.omniatech.io/v1/matic/mainnet/public",
  // "wss://polygon-bor.publicnode.com",
  "any"
);
export const providerBnbTestNet = new ethers.providers.JsonRpcProvider(
  "https://bsc-testnet.blockpi.network/v1/rpc/public",
  // "wss://polygon-bor.publicnode.com",
  "any"
);
