export const DateFormatCert = (isoString: string) => {
  const date = new Date(isoString);
  console.log(isoString);

  let a = date.getUTCDate;
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    timeZone: "GMT",
  });

  console.log(
    "🚀 ~ file: global.service.ts:52 ~ .padStart ~ formattedUTCDate:",
    a
  );
  return formattedDate;
};
