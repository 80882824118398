import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const Risk = (props: Props) => {
  const { riskInformation, setApplicationData, accessToken, id, appStatus } =
    useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(riskInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          riskInformation: { ...requestDataDebounce },
        },
      });
    if (hasValue(riskInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);
  return (
    <div className="form-application">
      <h1 className="form-application-header">Risks and Challenges</h1>

      <div className="form-controller text">
        <label className="required">
          What are the main challenges your startup currently faces?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              riskInformation: {
                ...riskInformation,
                mainChallenges: event.target.value,
              },
            }));
          }}
          value={riskInformation?.mainChallenges}
        ></textarea>
      </div>
      <div className="form-controller text">
        <label className="required">
          How do you plan to mitigate these challenges?
        </label>
        <textarea
          placeholder="(300 words max)"
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              riskInformation: {
                ...riskInformation,
                mitigatePlan: event.target.value,
              },
            }));
          }}
          value={riskInformation?.mitigatePlan}
        ></textarea>
      </div>
    </div>
  );
};

export default Risk;
