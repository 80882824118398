import React, { createContext, useEffect, useState } from "react";
import "./createApplication.css";

import Sidebar from "../../components/Sidebar";
import Wallet from "../../components/Wallet";
import { steps } from "./stepsInfo";
import { applicationIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import {
  Outlet,
  Route,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import Steps from "./components/Steps";
import {
  useSingleRequestQuery,
  useUpdateDraftFormMutation,
  useUpdateFormRequestMutation,
  useUpdateTempFormMutation,
} from "../../store/api/requestApi";
import StepControler from "./components/StepControler";
import { useDebounce } from "usehooks-ts";
import { hasValue, isFormHasValue } from "./utils/CompletFormCheck";
import { EventType } from "../../types/enum.types";

type Props = {};

export const FormApplicationContext = createContext(null);

const CreateApplication = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    pageNo: "1",
  });
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  var decoded: any = jwt_decode(`${accessToken}`);
  const [updateDraftForm, { isLoading }] = useUpdateDraftFormMutation();

  const location = useLocation();
  const [mintModal, setMintModal] = useState({
    show: false,
  });

  const { id } = useParams();
  const { data, isFetching, isSuccess, refetch } = useSingleRequestQuery(
    {
      accessToken,
      requestid: id,
    },
    { skip: Boolean(!accessToken) }
  );

  const [
    updateForm,
    {
      isSuccess: isUpdateFormSuccess,
      isError,
      isLoading: isLoadingForUpdateForm,
    },
  ] = useUpdateFormRequestMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  const [applicationData, setApplicationData] = useState({
    id: "",
    userId: "",
    eventType: "",
    basicInformation: {
      startupName: "",
      abstract: "",
      founders: [],
      raisedCapital: 0,
      requestedInvestment: 0,
      trl: 0,
    },
    productInformation: {
      description: "",
      problems: "",
      keyFeatures: "",
    },
    marketInformation: {
      targetMarket: "",
      estimatedMarketSize: 0,
      businessModel: "",
      topCompetitors: "",
      competitiveAdvantages: "",
    },
    businessModelInformation: {
      revenueModel: "",
      pricingStrategy: "",
      customerAcquisitionChannels: "",
      distributionChannels: "",
    },
    financialInformation: {
      recentAnnualTurnover: 0,
      averageAnnualTurnover: 0,
      totalRevenue: 0,
      currentYearProjectedRevenue: 0,
      nextYearProjectedRevenue: 0,
    },
    tractionInformation: {
      turnoverRecentFiscal: 0,
      currentUsers: "",
      currentRevenueStream: "",
      userFeedback: "",
    },
    riskInformation: {
      mainChallenges: "",
      mitigatePlan: "",
    },
    otherInformation: {
      businessPlanFileId: "",
      specificGoals: "",
      envisionAccelerator: "",
      specificMentorship: "",
      extra: "",
    },
    status: "SFS_DRAFT",
    statusReason: "",
    createdAt: "2023-12-12T05:58:11.413Z",
    creator: "BICONOMY",
    updatedAt: "2023-12-12T12:28:28.282Z",
    reviewedBy: "",
    chainData: {
      smartContract: "",
      tokenId: "0",
      transactionHash: "",
    },
  });
  const requestDataDebounce = useDebounce(applicationData, 5000);

  const update = () => {
    if (
      applicationData.status === "SFS_TEMP" &&
      isFormHasValue([
        applicationData?.basicInformation,
        applicationData?.productInformation,
        applicationData?.marketInformation,
        applicationData?.businessModelInformation,
        applicationData?.financialInformation,
        applicationData?.tractionInformation,
        applicationData?.riskInformation,
        applicationData?.otherInformation,
      ])
    ) {
      console.log("lsjfngljsfngl'jNS");
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: applicationData,
      });
      refetch();
      applicationData.status = "SFS_DRAFT";
      setApplicationData((state) => ({ ...state, status: "SFS_DRAFT" }));
      return;
    }
    if (applicationData.status !== "SFS_DRAFT") return;

    console.log(applicationData);

    updateDraftForm({
      accessToken,
      draftId: id,
      requestData: applicationData,
    });

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const updateApplication = () => {
    updateForm({
      accessToken,
      requestId: data?.id,
      requestData: applicationData,
    });
  };

  // const refetchData = async () => {
  //   try {
  //     refetch();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   if (data && pathname.includes("steps")) {
  //     refetchData();
  //   }
  // }, [pathname]);

  useEffect(() => {
    if (data) setApplicationData(data);
  }, [data]);
  // console.log("🚀 ~ file: CreateApplication.tsx:198 ~ useEffect ~ data:", data);
  return (
    <>
      <div className="profile-page">
        <Sidebar />
        <Wallet />
        {/* {isFetching ? <Loading /> : <></>} */}
        <div
          className="application-container requset-container"
          style={{
            paddingBottom: "70px",
          }}
        >
          <h2>
            <img src={applicationIcon} alt="" />
            Create Application
          </h2>

          <FormApplicationContext.Provider
            value={{
              accessToken: accessToken,
              id: id,
              basicInformation: applicationData?.basicInformation,
              productInformation: applicationData?.productInformation,
              marketInformation: applicationData?.marketInformation,
              businessModelInformation:
                applicationData?.businessModelInformation,
              financialInformation: applicationData?.financialInformation,
              tractionInformation: applicationData?.tractionInformation,
              riskInformation: applicationData?.riskInformation,
              otherInformation: applicationData?.otherInformation,
              appStatus: applicationData.status,
              setApplicationData: setApplicationData,
              updateApplication: updateApplication,
            }}
          >
            <h1 className="application-header">
              {applicationData?.basicInformation?.startupName}
              {applicationData?.eventType === EventType.FUM_EVENT ? (
                <>
                  <span
                    style={{
                      color: "#F7931A",
                      fontSize: "10px",
                      display: "block",
                    }}
                  >
                    (Blockchain Innovation Challenge)
                  </span>
                </>
              ) : (
                ""
              )}
            </h1>
            <Outlet />
          </FormApplicationContext.Provider>
          <StepControler steps={steps} id={id} update={update} />
        </div>
      </div>
    </>
  );
};

export default CreateApplication;
