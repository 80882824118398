import React, { useEffect, useState } from "react";
import "./application.css";
import Sidebar from "../../components/Sidebar";
import Wallet from "../../components/Wallet";
import { applicationBg } from "../../assets/images";
import { applicationIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useMyRequestQuery } from "../../store/api/requestApi";
import SingleApplication from "./components/SingleApplication";
import Loading from "../../components/Loading/Loading.animation";
import preIcon from "./images/pre.svg";
import nextIcon from "./images/next.svg";
import Statistic from "./components/Statistic";
import SubmitForChallenge from "./components/SubmitForChallenge";
import BCI from "../../components/BIC2024/BlockchainChallenge";
type Props = {};

const MyApplication = (props: Props) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [searchParams, setSearchParams] = useSearchParams({
    pageNo: "1",
  });
  var decoded: any = jwt_decode(`${accessToken}`);

  let isAuditore = decoded?.roles?.includes("ipcaptain.auditor");
  console.log(
    "🚀 ~ file: index.tsx:26 ~ MyApplication ~ isAuditore:",
    isAuditore
  );
  const location = useLocation();
  console.log("🚀 ~ file: index.tsx:18 ~ Requests ~ accessToken:", accessToken);

  const { data, isError, isFetching, refetch, isSuccess } = useMyRequestQuery(
    { accessToken, pageNo: searchParams.get("pageNo"), isAuditore },
    {
      skip: Boolean(!accessToken),
    }
  );

  const getTotoalPage = () => {
    const { pageNo, pageSize, totalItemsCount } = data?.response;
    let totalPage = Math.ceil(totalItemsCount / pageSize) + "";
    return totalPage;
  };
  const handlePagination = (action) => {
    console.log(action);

    let currentPageNo = searchParams.get("pageNo");
    const { pageNo, pageSize, totalItemsCount } = data?.response;
    let totalPage = Math.ceil(totalItemsCount / pageSize) + "";
    switch (action) {
      case "next":
        if (currentPageNo == totalPage) return;
        setSearchParams({
          pageNo: String(1 + parseInt(currentPageNo)),
        });
        break;
      case "prev":
        if (currentPageNo == "1") return;
        setSearchParams({
          pageNo: String(parseInt(currentPageNo) - 1),
        });
        break;

      default:
        break;
    }
  };
  const refetchData = async () => {
    try {
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data && isSuccess) {
      refetchData();
    }
  }, [location.pathname]);

  console.log("🚀 ~ file: index.tsx:24 ~ Requests ~ data:", data);
  return (
    <>
      <div className="profile-page">
        <Sidebar />
        <Wallet />
        {/* {showBIC && <BCI setShowBIC={setShowBIC} />} */}

        {isFetching ? <Loading /> : <></>}
        <div className="application-container requset-container">
          <h2>
            <img src={applicationIcon} alt="" />
            My Applications
          </h2>
          <div
            style={{
              backgroundImage: `url(${applicationBg})`,
            }}
            className="application-banner"
          >
            <h1
              style={{
                textAlign: "center",
              }}
            >
              Fueling Your Vision, Accelerating Success
            </h1>
          </div>
          <div className="application-content">
            <div className="application-list">
              <div className="application-item">
                <p>Startup or Idea Name</p>
                <p>Business Model</p>
                <p>Status</p>
                {/* <button></button> */}
              </div>

              {data?.data?.map((item) => (
                <SingleApplication
                  application={item}
                  refetch={refetch}
                  isAuditore={isAuditore}
                />
              ))}

              {data?.response?.totalItemsCount > 1 && (
                <div className="application-pagination">
                  <button
                    onClick={() => {
                      // setSearchParams({ pageNo: "2" });
                      handlePagination("prev");
                    }}
                  >
                    <img src={preIcon} alt="" />
                  </button>
                  <p>
                    Page{" "}
                    <span
                      style={{
                        color: "#66c16d",
                      }}
                    >
                      {searchParams.get("pageNo")}
                    </span>{" "}
                    of {getTotoalPage()}{" "}
                  </p>
                  <button
                    onClick={() => {
                      // setSearchParams({ pageNo: "2" });
                      handlePagination("next");
                    }}
                  >
                    <img src={nextIcon} alt="" />
                  </button>
                </div>
              )}
            </div>

            {/* <>
              <SubmitForChallenge
                isAuditore={decoded?.roles?.includes("ipcaptain.auditor")}
              />
              <div></div>
            </> */}

            <Statistic />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyApplication;
