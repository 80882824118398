import { useState, Suspense, useContext } from "react";
import TabButton from "./tabButton";

import NftsList from "./components/NftsList";
import CryptoList from "./components/CryptoList";
import ActivityList from "./components/ActivityList";
import { ethers } from "ethers";
import { Contracts } from "../../types/enum.types";
import { ERC20_ABI } from "@biconomy/modules";
import { biconomyContext } from "../../utils/Context";
import { maticAbi } from "../../utils/ABI";
import {
  IHybridPaymaster,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";

const TabContainer = () => {
  const { address, smartAccount } = useContext(biconomyContext);
  const [tab, setTab] = useState("CRYPTO");

  const transferMatic = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-bor.publicnode.com",
        "any"
      );

      const matic = new ethers.Contract(
        "0x0000000000000000000000000000000000001010",
        maticAbi,
        provider
      );
      debugger;
      const maticData = await matic.populateTransaction.transfer(
        "0x6f97185B8C4717Adb3Bb7094eD4F627D0106d736",
        ethers.utils.parseEther("1.5")
      );

      const maticTx = {
        to: "0x0000000000000000000000000000000000001010",
        data: maticData.data,
        value: ethers.utils.parseEther("1.5"),
      };

      let partialUserOp2 = await smartAccount.buildUserOp([maticTx]);

      const biconomyPaymaster =
        smartAccount.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      let paymasterServiceData: SponsorUserOperationDto = {
        mode: PaymasterMode.SPONSORED,
        smartAccountInfo: {
          name: "BICONOMY",
          version: "2.0.0",
        },
      };
      try {
        const paymasterAndDataResponse =
          await biconomyPaymaster.getPaymasterAndData(
            partialUserOp2,
            paymasterServiceData
          );
        partialUserOp2.paymasterAndData =
          paymasterAndDataResponse.paymasterAndData;
        // debugger;

        const userOpResponse = await smartAccount.sendUserOp(partialUserOp2);
        // debugger;

        const transactionDetails = await userOpResponse.wait();
        // debugger;

        console.log("Transaction Details:", transactionDetails);
        console.log("Transaction Hash:", userOpResponse.userOpHash);

        // handelSubmit();
      } catch (e) {
        console.error("Error executing transaction:", e);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Suspense fallback={<h1> Loading...</h1>}>
      <div className="tab--button">
        <TabButton isActive={tab === "CRYPTO"} onClick={() => setTab("CRYPTO")}>
          Crypto
        </TabButton>
        <TabButton isActive={tab === "NFTS"} onClick={() => setTab("NFTS")}>
          Tokens
        </TabButton>
        <TabButton
          isActive={tab === "TRANSACTIONS"}
          onClick={() => setTab("TRANSACTIONS")}
        >
          Transactions
        </TabButton>
        {/* <TabButton
          isActive={false}
          onClick={() => {
            transferMatic();
          }}
        >
          Receive
        </TabButton> */}
      </div>
      <div className="content--tab">
        {tab === "CRYPTO" && <CryptoList />}
        {tab === "NFTS" && <NftsList />}
        {tab === "TRANSACTIONS" && <ActivityList />}
      </div>
    </Suspense>
  );
};

export default TabContainer;
