import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Toaster, toast } from "sonner";
import { ApiEnum } from "../../../../../types/enum.types";

type Props = {};

const PitchDeck = ({
  setRequestData,
  pitchDeck,
  setPitchDeck,
  pitchDeckURL,
  setPitchDeckURL,
  requestData,
}) => {
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const [progress, setProgress] = useState(0);
  const cancelToken = axios.CancelToken;
  const sourceCancel = cancelToken.source();
  const onUploadProfile = async (selectedFile: any, input: any, type: any) => {
    try {
      setProgress(0);
      const formdata: any = new FormData();
      formdata.append("file", selectedFile);
      var config = {
        method: "post",
        url: ApiEnum.STORAGE_API + `/files/upload`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-File-Access": 0,
        },
        data: formdata,
      };
      const response = await axios({
        ...config,
        cancelToken: sourceCancel.token,
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(uploadPercentage);
        },
      });
      // setRequestData(response);
      if (response?.status == 200) {
        if (input === "Introduction") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              videoFile: {
                ...prevState.businessInformation.videoFile,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
        if (input === "PitchDeck") {
          setRequestData((prevState) => ({
            ...prevState,
            businessInformation: {
              ...prevState.businessInformation,
              pitchDeck: {
                ...prevState.businessInformation.pitchDeck,
                id: response.data,
                contentType: type,
              },
            },
          }));
        }
      }
      toast.success("file uploaded successfully");
      console.log("response onUploadProfile:", response);
    } catch (error) {
      console.error(error);
      toast.error("Error in Uploading file");
    }
  };
  return (
    <>
      <Toaster richColors position="top-right" />
      <div className="formControl">
        <label className="label2 required">
          Upload your Pitch-deck (High Importance!)
        </label>
        <div className="box" style={{ position: "relative" }}>
          <div
            style={{
              overflow: "hidden",
              background: pitchDeck?.files[0] && "none",
            }}
            className="tfile"
          >
            <input
              onChange={(event: any) => {
                setPitchDeck(event.target);
                setPitchDeckURL(
                  event?.target?.files[0]
                    ? URL.createObjectURL(event?.target?.files[0])
                    : null
                );
                onUploadProfile(
                  event.target.files[0],
                  "PitchDeck",
                  event.target.files[0].type
                );
              }}
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              style={{ zIndex: "10" }}
            />

            {!pitchDeck?.files[0]?.type &&
              requestData?.businessInformation?.pitchDeck?.id &&
              (requestData?.businessInformation?.pitchDeck?.contentType.startsWith(
                "image"
              ) ? (
                <>
                  <img
                    style={{
                      maxHeight: " 100%",
                      position: "absolute",
                      left: "40%",
                    }}
                    src={`${ApiEnum.STORAGE_API}/files?id=${requestData?.businessInformation?.pitchDeck?.id}`}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      zIndex: "1",
                    }}
                  ></div>
                  <iframe
                    src={`https://storage-api-dev.sensifia.vc/files?id=${requestData?.businessInformation?.pitchDeck?.id}#view=fitH&toolbar=`}
                    frameBorder="0"
                    height="110%"
                    width="120%"
                    style={{ position: "absolute", left: "-5px" }}
                  ></iframe>
                </>
              ))}

            {pitchDeck?.files[0]?.type == "application/pdf" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                  }}
                ></div>
                <iframe
                  src={`${pitchDeckURL}#view=fitH&toolbar=0`}
                  frameBorder="0"
                  height="110%"
                  width="120%"
                  style={{ position: "absolute", left: "-5px" }}
                ></iframe>
              </>
            )}

            {pitchDeck?.files[0]?.type.startsWith("image") && (
              <img
                style={{
                  maxHeight: " 100%",
                  position: "absolute",
                  left: "40%",
                }}
                src={pitchDeckURL}
              />
            )}
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
          <span>
            Please attach a PDF deck, slides or presentation of your product /
            start-up.
          </span>
        </div>
      </div>
    </>
  );
};

export default PitchDeck;
