import React, { useContext, useEffect } from "react";
import "./Particle.css";
import { IPCaptainLogo } from "../../assets/images";
import { ParticleAuthModule, ParticleProvider } from "@biconomy/particle-auth";
import { Toaster, toast } from "sonner";
import { useParticleUserMutation } from "../../store/api/acoountApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, logout } from "../../store/logic/authSlice";
import { biconomyContext } from "../../utils/Context";
import Spinner from "../../components/Spinner/Spinner";

type Props = {
  connect: Function;
  isLoginLoading: Boolean;
  // setIslogin: Function;
  // isSuccess: boolean;
  // isError: boolean;
  // userData: any;
};

const Login = ({ connect, isLoginLoading }: Props) => {
  const { particle } = useContext(biconomyContext);
  useEffect(() => {
    connect();
  }, [particle?.auth?.isLogin]);

  return (
    <div className="particle-container">
      <Toaster position="top-right" richColors />
      <div className="particle">
        <img src={IPCaptainLogo} alt="" />
        <button
          onClick={() => {
            connect();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoginLoading ? <Spinner /> : "Login"}
        </button>
      </div>
    </div>
  );
};

export default Login;
