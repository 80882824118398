import React, { useContext, useEffect } from "react";
import { FormApplicationContext } from "../CreateApplication";
import FounderForm from "./sub-components/FounderForm";
import warningIcon from "../images/warning.svg";
import ToolTibHover from "../../../components/ToolTip/ToolTibHover";
import { useDebounce } from "usehooks-ts";
import {
  useUpdateDraftFormMutation,
  useUpdateTempFormMutation,
} from "../../../store/api/requestApi";
import { hasValue } from "../utils/CompletFormCheck";

type Props = {};

const BasicInfo = (props: Props) => {
  const { basicInformation, setApplicationData, accessToken, id, appStatus } =
    useContext(FormApplicationContext);

  const requestDataDebounce = useDebounce(basicInformation, 5000);
  const [updateDraftForm, { data, isLoading }] = useUpdateDraftFormMutation();
  const [
    updateTempForm,
    {
      isSuccess: isUpdateTempFormSuccess,
      isError: isErrorTempForm,
      isLoading: isLoadingForUpdateTempForm,
    },
  ] = useUpdateTempFormMutation();

  useEffect(() => {
    if (appStatus === "SFS_DRAFT")
      updateDraftForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });

    if (hasValue(basicInformation) && appStatus === "SFS_TEMP") {
      updateTempForm({
        accessToken,
        draftId: id,
        requestData: {
          basicInformation: { ...requestDataDebounce },
        },
      });
    }
  }, [requestDataDebounce]);

  return (
    <div className="form-application">
      <h1 className="form-application-header">Basic Information</h1>

      <div className="form-controller shortinput">
        <label className="required">
          Business / Idea Title <span className="red">*</span>
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              basicInformation: {
                ...basicInformation,
                startupName: event.target.value,
              },
            }));
          }}
          value={basicInformation?.startupName}
          type="text"
          className="txt "
          required
        />
      </div>
      <div className="form-controller text">
        <label className="required">
          Abstract<span className="red">*</span>{" "}
        </label>
        <textarea
          placeholder="(Brief Description of your idea) - 100 words max."
          name=""
          id=""
          cols={30}
          rows={10}
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              basicInformation: {
                ...basicInformation,
                abstract: event.target.value,
              },
            }));
          }}
          value={basicInformation?.abstract}
        ></textarea>
      </div>

      <FounderForm />

      <div className="form-controller shortinput usd">
        <label className="required">
          How much capital has been raised until now?
          <span className="red">*</span>{" "}
        </label>
        <input
          onChange={(event) => {
            // if (parseInt(event.target.value) < 0) event.target.value = "0";
            setApplicationData((state) => ({
              ...state,
              basicInformation: {
                ...basicInformation,
                raisedCapital: event.target.value,
              },
            }));
          }}
          value={basicInformation?.raisedCapital}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput usd">
        <label className="required">
          How much investment you are requesting? <span className="red">*</span>
        </label>
        <input
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              basicInformation: {
                ...basicInformation,
                requestedInvestment: event.target.value,
              },
            }));
          }}
          value={basicInformation?.requestedInvestment}
          className="txt "
          type="number"
          min={0}
          required
        />
      </div>
      <div className="form-controller shortinput">
        <label className="required">
          Specify the TRL of your business / idea<span className="red">*</span>
        </label>
        {/* <div className="range">
          <input
            onChange={(event) => {
              setApplicationData((state) => ({
                ...state,
                basicInformation: {
                  ...basicInformation,
                  trl: parseInt(event.target.value),
                },
              }));
            }}
            value={basicInformation?.trl}
            type="range"
            name=""
            max={100}
            min={1}
            id=""
          />
          <span>
            <ToolTibHover tooltipText="Launched and commercially available">
              TRL1-3 <img src={warningIcon} alt="" />
            </ToolTibHover>
          </span>
          <span>
            {" "}
            <ToolTibHover tooltipText="Launched and commercially available">
              TRL4-6 <img src={warningIcon} alt="" />
            </ToolTibHover>
          </span>
          <span>
            <ToolTibHover tooltipText="Launched and commercially available">
              TRL7-9 <img src={warningIcon} alt="" />
            </ToolTibHover>
          </span>
        </div> */}
        <select
          onChange={(event) => {
            setApplicationData((state) => ({
              ...state,
              basicInformation: {
                ...basicInformation,
                trl: event.target.value,
              },
            }));
          }}
          value={basicInformation?.trl}
          className="trl-select"
          name=""
          id=""
        >
          <option disabled value="">
            {" "}
            Specify the TRL{" "}
          </option>
          <option value="1">TRL1 - Basic principles observed</option>
          <option value="2">TRL2 - Technology concept formulated</option>
          <option value="3">TRL3- Experimental proof of concept</option>
          <option value="4">
            TRL4 - Technology validated in relevant environment (industrially
            relevant environment in the case of key enabling technologies)
          </option>
          <option value="5">
            TRL5 - Technology demonstrated in relevant environment (industrially
            relevant environment in the case of key enabling technologies)
          </option>
          <option value="6">
            TRL6 - System prototype demonstration in operational environment
          </option>
          <option value="7">
            TRL7 - System prototype demonstration in operational environment
          </option>
          <option value="8">TRL8 - System complete and qualified</option>
          <option value="9">
            TRL9 - Actual system proven in operational environment (competitive
            manufacturing in the case of key enabling technologies; or in space)
          </option>
        </select>
      </div>
    </div>
  );
};

export default BasicInfo;
