import React from "react";
import { Link } from "react-router-dom";

const Step2 = ({ requestData, setRequestData }) => {
  return (
    <div className="wrapper">
      <div className="form">
        <div className="formControl">
          <label className=" required">Have you applied before? </label>
          <div className="req-box">
            <label htmlFor="applied">
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    appliedBefore: true,
                  }));
                }}
                type="radio"
                name="applied"
                id="applied"
                className="radio"
                checked={requestData?.appliedBefore}
              />
              Yes
            </label>
            <label htmlFor="appliedNo">
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    appliedBefore: false,
                  }));
                }}
                type="radio"
                name="appliedNo"
                id="appliedNo"
                className="radio"
                checked={!requestData?.appliedBefore}
              />
              No
            </label>
          </div>
        </div>
        <div className="formControl">
          <label className="required">Do you want to launch company? </label>
          <div className="req-box">
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      launchCompany: true,
                    },
                  }));
                }}
                name="company"
                type="radio"
                className="radio"
                checked={requestData?.foundingInformation?.launchCompany}
              />
              Yes
            </label>
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      launchCompany: false,
                    },
                  }));
                }}
                name="company"
                type="radio"
                className="radio"
                checked={!requestData?.foundingInformation?.launchCompany}
              />
              No
            </label>
          </div>
        </div>
        <div className="formControl">
          <label className="required">
            Do you want to initiate a decentralize company?
          </label>
          <div className="req-box">
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      decentralizedCompany: true,
                    },
                  }));
                }}
                type="radio"
                name="decentralize"
                className="radio"
                checked={requestData?.foundingInformation?.decentralizedCompany}
              />
              Yes
            </label>
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      decentralizedCompany: false,
                    },
                  }));
                }}
                type="radio"
                name="decentralize"
                checked={
                  !requestData?.foundingInformation?.decentralizedCompany
                }
                className="radio"
              />
              No
            </label>
          </div>
        </div>
        <div className="formControl">
          <label className="label3 required">
            Do you intent to invest in your idea?{" "}
          </label>
          <div className="req-box">
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      investIdea: true,
                    },
                  }));
                }}
                name="invest"
                type="radio"
                className="radio"
                checked={requestData?.foundingInformation?.investIdea}
              />
              Yes
            </label>
            <label>
              <input
                onClick={() => {
                  setRequestData((prevState) => ({
                    ...prevState,
                    foundingInformation: {
                      ...prevState.foundingInformation,
                      investIdea: false,
                    },
                  }));
                }}
                name="invest"
                type="radio"
                checked={!requestData?.foundingInformation?.investIdea}
                className="radio"
              />
              No
            </label>
          </div>
        </div>
        <div className="formControl flexCenter">
          <label className=" required">
            How much capital has been raised so far?
          </label>
          <input
            onChange={(event) => {
              setRequestData((prevState) => ({
                ...prevState,
                foundingInformation: {
                  ...prevState.foundingInformation,
                  howMuchInvested: event.target.value,
                },
              }));
            }}
            type="number"
            className="txt txt2"
            placeholder="e.g., 23"
            value={requestData?.foundingInformation?.howMuchInvested}
          />
          <span>USD</span>
        </div>
      </div>
    </div>
  );
};
export default Step2;
