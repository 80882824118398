import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

interface ITokens {
  network: "POLYGON";
  address: string;
}
export const cryptoApi = createApi({
  reducerPath: "crypto",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api-polygon-tokens.polygon.technology/",
  }),
  endpoints: (builder) => ({
    getAllCryptoTokenData: builder.query({
      query: () => {
        return {
          url: `tokenlists/polygonPopular.tokenlist.json`,
        };
      },
    }),
  }),
});

export const { useGetAllCryptoTokenDataQuery } = cryptoApi;
