import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiEnum, EventType } from "../../types/enum.types";

// import store from "../index";
// console.log("🚀 ~ file: profile.api.ts:5 ~ store :", store);

// console.log(store);

export const challengeApi = createApi({
  reducerPath: "challenge",
  baseQuery: fetchBaseQuery({ baseUrl: ApiEnum.CAPTAIN_API }),
  endpoints: (builder) => ({
    getChallengeTokenInfo: builder.query({
      query: (accessToken) => {
        return {
          url: `/discount/usage/IPCAPTAIN/${
            EventType.FUM_EVENT
          }?time=${Math.random()}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    setChallengeTokenInfo: builder.mutation({
      query: (accessToken) => {
        return {
          url: `/discount/usage`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: {
            application: "IPCAPTAIN",
            uniqueId: EventType.FUM_EVENT,
          },
        };
      },
    }),
  }),
});

export const {
  useGetChallengeTokenInfoQuery,
  useSetChallengeTokenInfoMutation,
} = challengeApi;
