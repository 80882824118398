const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    const containerStyles:any  = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      margin: 50
    }
  
    const fillerStyles:any = {
      height: '100%',
      width: `${completed}%`,
      background: 'linear-gradient(90deg, #4EC8FC 0%, #1577AE 100%)',
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
    }
  
    const labelStyles:any  = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold',

    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;