import React, { useEffect, useState } from "react";

import "../../auth.css";
// import EmailIcon from "../../../../assets/icons/email.svg";
// import LockIcon from "../../../assets/icons/lock.svg";
// import EyeIcon from "../../assets/icons/eye.svg";
// import EyeOffIcon from "../../assets/icons/eye-off.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEnum, RouteEnum } from "../../../../types/enum.types";
import Button from "../../../../components/LoginButton/Button";

// import Button from "../../../../components/Button/Button";

type Props = {
  setResetEmail: Function;
  setStep: Function;
};

const SendEmail = ({ setResetEmail, setStep }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginError, setLoginError] = useState({
    emailError: "",
    passwordError: "",
    submitError: "",
  });
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginError({
      emailError: "",
      passwordError: "",
      submitError: "",
    });
    if (!email) {
      setLoginError((state) => ({ ...state, emailError: "email is required" }));
    }

    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ApiEnum.CAPTAIN_API}/forgot`,
        {
          email: email?.toLocaleLowerCase(),
        },
        {
          headers: {
            "x-service-name": "ipcafe",
          },
        }
      );

      console.log(res.data);
      setResetEmail(email);
      setStep({ fotgotStep: "reset" });
      setIsLoading(false);
    } catch (err: any) {
      console.log("err", err.response.data.message);
      setLoginError((state) => ({
        ...state,
        submitError: err?.response?.data?.message,
      }));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLoginError((state) => ({ ...state, emailError: "" }));
  }, [email]);
  useEffect(() => {
    setLoginError((state) => ({ ...state, passwordError: "" }));
  }, [password]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-controller-auth">
        <label htmlFor="">Please enter the email you use to sign in.</label>
        <div className="input-icon">
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id=""
            placeholder="Enter your email"
          />
          {/* <img src={EmailIcon} alt="" /> */}
        </div>
        {loginError.emailError ? (
          <span className="error">{loginError.emailError}</span>
        ) : (
          <></>
        )}
      </div>

      {loginError.submitError ? (
        <span className="error">{loginError.submitError}</span>
      ) : (
        <></>
      )}

      <Button content="Send" lodaing={isLoading} />

      <div
        className="flex justify-center w-full "
        style={{
          fontSize: "80%",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        Don’t have an IPMinter Account?
        <Link
          style={{ color: "blue", paddingLeft: "2px" }}
          className="width-100"
          to={"/auth/register"}
        >
          {" "}
          • New Account
        </Link>
        <Link
          style={{ color: "blue", paddingLeft: "2px" }}
          className="width-100"
          to={"/auth/login"}
        >
          {" "}
          • login
        </Link>
      </div>
    </form>
  );
};

export default SendEmail;
