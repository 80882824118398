import React, { useContext, useEffect, useState } from "react";
import { biconomyContext } from "../../../../utils/Context";
import { ethers } from "ethers";
import { ERC20_ABI } from "@biconomy/modules";
import { providerPolygan } from "../../../../config/providerConfig";
import {
  IHybridPaymaster,
  PaymasterFeeQuote,
  PaymasterMode,
  SponsorUserOperationDto,
} from "@biconomy/paymaster";

type Props = {};

const GasFee = ({ toAddress, tokenAddress }) => {
  const { address, smartAccount, toast } = useContext(biconomyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [gasfee, setGasfee] = useState("");
  const calculateGasFee = async () => {
    setIsLoading(true);
    const contract = new ethers.Contract(
      tokenAddress,
      ERC20_ABI,
      providerPolygan
    );

    const countData = await contract.populateTransaction.transfer(
      toAddress,
      ethers.utils.parseUnits("1")
    );

    const transferTx = {
      to: tokenAddress,
      data: countData.data,
    };

    try {
      let userOp = await smartAccount.buildUserOp([transferTx]);

      const biconomyPaymaster =
        smartAccount.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      const feeQuotesResponse =
        await biconomyPaymaster.getPaymasterFeeQuotesOrData(userOp, {
          mode: PaymasterMode.ERC20,
          tokenList: ["0xc2132d05d31c914a87c6611c10748aeb04b58e8f"],
        });

      const feeQuotes = feeQuotesResponse.feeQuotes as PaymasterFeeQuote[];
      const spender = feeQuotesResponse.tokenPaymasterAddress || "";
      const usdcFeeQuotes = feeQuotes[0];
      console.log(usdcFeeQuotes?.maxGasFeeUSD);

      setGasfee(usdcFeeQuotes?.maxGasFeeUSD + "");
    } catch (error) {
      console.log("first", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calculateGasFee();
  }, []);
  return (
    <p className="gasfee">
      Estimated Gas Fee : <em>{gasfee} usdt</em>
    </p>
  );
};

export default GasFee;
